import React, {useCallback, useEffect, useState} from "react";
import {ButtonType, DatePicker, DayOfWeek, IconButton, IDatePickerStrings, Stack} from '@fluentui/react';
import {Text} from "@fluentui/react/lib/Text";
import {IRcFormFieldProps, RcFormBaseField} from "./RcFormBaseField";
import moment from "moment";

export interface IRcFormDateFieldProps extends IRcFormFieldProps {
  formatDate?: string,
  strings?: IDatePickerStrings
}

export const RcFormDateField = (props: IRcFormDateFieldProps): JSX.Element => {

  const [value, setValue] = useState<Date | undefined>();

  useEffect(() => {
    if(props.initialValue !== undefined) {
      setValue(props.initialValue);
    }
  }, [])

  const onFormatDate = useCallback((date?: Date): string => {
    return getFormattedDate(date);
  }, [value]);

  const getFormattedDate = (date?: Date): string => {
    return !date ? '' : moment(date).format(props.formatDate ? props.formatDate : "DD/MM/YYYY");
  }

  const onChangeValue = useCallback((date: Date | null | undefined) => {
    if(date === null || date === undefined){
      setValue(undefined);
    } else {
      setValue(date);
    }
  }, []);

  const parseDateFromString = (dateStr: string) => {
    return moment(dateStr, props.formatDate ? props.formatDate : 'DD/MM/YYYY').toDate();
  }

  const EditComponent = (
    <Stack horizontal tokens={{childrenGap: 4}}>
      <Stack.Item grow>
        <DatePicker
          firstDayOfWeek={DayOfWeek.Monday}
          openOnClick={false}
          allowTextInput
          id={props.id}
          key={props.id}
          value={value}
          placeholder={props.placeholder}
          strings={props.strings}
          onSelectDate={onChangeValue}
          formatDate={onFormatDate}
          parseDateFromString={parseDateFromString} />
      </Stack.Item>
      {value &&
        <IconButton onClick={() => setValue(undefined)} buttonType={ButtonType.compound} iconProps={{iconName: 'Trash'}} />
      }
    </Stack>
    );
  const ViewComponent = <Text>{getFormattedDate(value)}</Text>

  return(
    <RcFormBaseField fieldProps={props} value={value} editModeComponent={EditComponent} viewModeComponent={ViewComponent} />
  )
}



