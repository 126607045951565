import React, {useEffect, useState} from "react";
import {IconButton, Separator, Stack, Text} from "@fluentui/react";
import {Icon} from "@fluentui/react/lib/Icon";
import {IRcDataGridColumn} from "../../RcDataGrid";

export interface IRcDataGridColumnsChooserProps {
  title: string,
  columns: IRcDataGridColumn[],
  onDismiss: () => void,
  onChange: (columnKey: string, isVisible: boolean) => void,
}

export const RcDataGridColumnsChooser = (props: IRcDataGridColumnsChooserProps): JSX.Element => {

  return(
    <Stack>
      <Stack horizontal>
        <Stack.Item grow>
          <Text block variant="large">
            <Icon iconName={'ColumnOptions'} styles={{root: {marginRight: 10, fontSize: 14}}} />
            {props.title}
          </Text>
        </Stack.Item>
        <Text block variant="large" onClick={props.onDismiss} style={{cursor: 'pointer'}}>
          <Icon iconName={'ChromeClose'} styles={{root: {marginRight: 10, fontSize: 12}}} />
        </Text>
      </Stack>
      <Separator />
      <Stack>
        {
          props.columns.map((col) => {
            return <RcDataGridColumnsChooserItem visible={col.visible} columnKey={col.key} displayName={col.name} onChange={(c, v) => props.onChange(c,v)} />
          })
        }
      </Stack>
    </Stack>
  )
}

export interface IRcDataGridColumnsChooserItemProps {
  columnKey: string,
  displayName: string,
  visible: boolean,
  onChange: (columnKey: string, isVisible: boolean) => void,
}

export const RcDataGridColumnsChooserItem = (props: IRcDataGridColumnsChooserItemProps): JSX.Element => {

  const [isVisible, setIsVisible] = useState<boolean>(props.visible);

  useEffect(() => {
    props.onChange(props.columnKey, isVisible);
  }, [isVisible])

  return (
    <Stack horizontal>
      <Stack.Item grow>
        <Text style={{lineHeight: '32px'}}>{props.displayName}</Text>
      </Stack.Item>
      <Stack.Item>
        <IconButton
          toggle
          text={isVisible ? 'Visibile' : 'Nascosta'}
          iconProps={isVisible ? {iconName: 'Hide'} : {iconName: 'RedEye'}}
          onClick={() => setIsVisible(!isVisible)}
          allowDisabledFocus
        />
      </Stack.Item>
    </Stack>
  )
}
