import React from "react";
import { AuthConsumer } from "../../../providers/RcAuthProvider";
import {Spinner, SpinnerSize} from "@fluentui/react";

export const Callback = () => {
  return (
    <AuthConsumer>
      {({ signinRedirectCallback }) => {
        signinRedirectCallback();
        return (
          <div style={{
            position: 'absolute', left: '50%', top: '50%',
            transform: 'translate(-50%, -50%)'
          }}>
            <Spinner label="Attendi..." size={SpinnerSize.large} />
          </div>
        );
      }}
    </AuthConsumer>
  )
};
