import React, {Suspense} from "react";
import {Spinner, SpinnerSize} from "@fluentui/react";

export const RcSuspenseComponent = ({ component }: any) => {
  const Component = component;

  return (
    <Suspense fallback={
      <div style={{
        position: 'absolute', left: '50%', top: '50%',
        transform: 'translate(-50%, -50%)'
      }}>
        <Spinner label="Attendi..." size={SpinnerSize.large} />
      </div>
    }>
      <Component {...component.props} />
    </Suspense>
  )

}
