import React from "react";
import {Label, Stack, TooltipHost} from "@fluentui/react";
import {Icon, IIconStyles} from "@fluentui/react/lib/Icon";

const iconStyles: Partial<IIconStyles> = { root: { padding: "8px 0", marginLeft: 10 } };

export interface IRcFormFieldLabelProps {
  id: string,
  label: string,
  infoText?: string,
  required: boolean,
  type?: 'span' | 'label',
}

export const RcFormFieldLabel = (props: IRcFormFieldLabelProps): JSX.Element => {
  return(
    <Stack horizontal horizontalAlign="start" style={{alignItems: 'center'}}>
      {(props.type === undefined || props.type === 'label') ? (
          <Label htmlFor={props.id}>
            {props.label}
          </Label>
        ) : (
          <span style={{lineHeight: '100%'}}>{props.label}</span>
        )
      }
      {props.infoText &&
        <TooltipHost
          content={props.infoText}
          calloutProps={{gapSpace: 0}} >
          <Icon iconName="Info" title="Info" ariaLabel="Info" styles={iconStyles} />
        </TooltipHost>
      }
    </Stack>
  )
}
