export class SortingData {
  propertyName: string;
  isDescending: boolean;
  priority: number;

  constructor(propertyName: string, isDescending: boolean) {
    this.propertyName = propertyName;
    this.isDescending = isDescending;
    this.priority = 0;
  }
}
