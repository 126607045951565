import React, {FormEvent, useContext, useEffect, useState} from 'react';
import {DefaultButton, DefaultEffects, mergeStyleSets, TextField} from "@fluentui/react";
import {Text, Stack} from "@fluentui/react";
import {RcAuthContext} from "../../../providers/RcAuthProvider";
import {AuthInterface} from "../../../services/auth/auth.interface";
import {RcThemeContext} from "../../../providers/RcThemeProvider";
import {useNavigate} from "react-router-dom";

export function Login() {

  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const [email, setEmail] = useState<string | undefined>();
  const [password, setPassword] = useState<string | undefined>();
  const authContext = useContext<AuthInterface>(RcAuthContext);
  const history = useNavigate();
  const themeProvider = useContext(RcThemeContext);

  const styleContainer = mergeStyleSets({
    loginContainer: {
      width: '100vw',
      height: '100vh',
      display: 'flex',
    },
    loginBox: {
      boxShadow: DefaultEffects.elevation8,
      borderRadius: DefaultEffects.roundedCorner4,
      padding: 20,
      maxWidth: '400px',
      height: 'auto',
      width: '100%',
      margin: 'auto'
    },
    loginBoxWrapper: {
      padding: 20,
      height: 'auto',
      width: '100%',
      margin: 'auto'
    },
    logoContainer: {
      maxHeight: 80,
    },
    logoImg: {
      maxHeight: 80,
      width: 'auto'
    },
    errorMessage: {
      textAlign: 'center',
      width: '100%',
      color: 'red'
    }
  });

  useEffect(() => {

    if(authContext.isAuthenticated()) {
      history('/');
    }

  }, [authContext]);

  const onLoginClickHandler = () => {
    if(email && password) {
      authContext.login(email, password)
        .catch(_err => setErrorMessage("Errore durante il login"));
    }
  }

  const onFormSubmit = (event: FormEvent<any>) => {
    event.preventDefault();
    onLoginClickHandler();
  }

  return(
    <div className={styleContainer.loginContainer}>
      <div className={styleContainer.loginBoxWrapper}>
        {themeProvider?.rcTheme?.authRocket?.beforeLoginFormLabel &&
          <div style={{marginTop: 32, marginBottom: 32, textAlign: 'center', width: '100%'}}>
            <Text variant={"large"} style={{width: '100%', textAlign: 'center'}}>{themeProvider?.rcTheme?.authRocket?.beforeLoginFormLabel}</Text>
          </div>
        }
        <div className={styleContainer.loginBox}>
          <form onSubmit={onFormSubmit}>
            <Stack tokens={{childrenGap: 32}}>

              {(themeProvider?.rcTheme?.authRocket?.logo?.text) &&
                <Text variant={"xxLarge"}>{themeProvider?.rcTheme?.authRocket?.logo?.text}</Text>
              }

              {(themeProvider?.rcTheme?.authRocket?.logo?.path) &&
                <div className={styleContainer.logoContainer}>
                  <img className={styleContainer.logoImg} src={themeProvider?.rcTheme?.authRocket?.logo?.path} />
                </div>
              }

              <Text variant={"large"}>Effettua il login</Text>

              <TextField id={'field-email'} placeholder={'Inserisci il tuo nome utente'} onChange={(_event, newValue) => setEmail(newValue)} />
              <TextField id={'field-password'} placeholder={'Inserisci la tua password'} onChange={(_event, newValue) => setPassword(newValue)} type={'password'} canRevealPassword />
              {errorMessage &&
                <Text variant={"medium"} className={styleContainer.errorMessage}>{errorMessage}</Text>
              }
              <DefaultButton disabled={!email || !password} primary type={'submit'} onClick={onLoginClickHandler}>Accedi</DefaultButton>

            </Stack>
          </form>
        </div>
      </div>
    </div>
  )
}

export default Login;
