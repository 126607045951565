import {getTheme, Stack, Text} from "@fluentui/react";
import React, {useState} from "react";
import {IRcSidebarProps} from "./RcSidebar.types";
import {IRcSidebarItem, RcSidebarItem} from "./RcSidebarItem";
import {generateUniqueID} from "web-vitals/dist/modules/lib/generateUniqueID";

export const RcSidebar = (props: IRcSidebarProps): JSX.Element => {

    const currentTheme = getTheme();
    // const themeContext = useContext(RcThemeContext);
    const [currentOverSidebarItem, setCurrentOverSidebarItem] = useState<IRcSidebarItem | null>(null)

    const styles : any = {
        root: {
            width: (props.isNavCollapsed ? 48 : 228),
            borderRightStyle: 'solid',
            borderRightWidth: 1,
            borderRightColor: currentTheme.semanticColors.bodyFrameDivider,
            height: 'calc(100vh - 48px)',
            position: "fixed",
            overflow: "scroll",
            zIndex: 999,
            background: currentTheme.palette.neutralLighter,
            //boxShadow: "0 6.4px 14.4px 0 rgb(0 0 0 / 13%), 0 1.2px 3.6px 0 rgb(0 0 0 / 11%)",
        },
        minimalSubMenuContainer: {
          width: 228,
          borderRightStyle: 'solid',
          borderRightWidth: 1,
          borderRightColor: currentTheme.semanticColors.bodyFrameDivider,
          height: 'calc(100vh - 48px)',
          position: "fixed",
          overflow: "scroll",
          zIndex: 999,
          background: '#fff',
          marginLeft: '48px !important',
          display: (props.isNavCollapsed && currentOverSidebarItem?.children && currentOverSidebarItem?.children.filter(a => a.visible).length > 0) ? 'block' : 'none',
          //boxShadow: "0 6.4px 14.4px 0 rgb(0 0 0 / 13%), 0 1.2px 3.6px 0 rgb(0 0 0 / 11%)",
        },
        headerText: {
          paddingLeft: 16,
          fontWeight: 600,
          fontSize: 14,
          marginTop: 16,
          marginBottom: 8,
        },
        headerTextMinIcon: {
          height: 'auto',
          width: 'auto',
          alignItems: 'center',
          display: 'flex',
          flex: '0 0 40px',
          justifyContent: 'center',
          zIndex: 100,
        }
    }

    const onMouseOverHandler = (item: IRcSidebarItem) => {
      if(currentOverSidebarItem?.id !== item?.id) {
        setCurrentOverSidebarItem(item);
      }
    }

    const onMouseLeaveHandler = () => {
      if(currentOverSidebarItem) {
        setCurrentOverSidebarItem(null);
      }
    }

    return (
      <div onMouseLeave={onMouseLeaveHandler}>
        <Stack id={'rc-sidebar'} className={styles.root}>
          {props.items.filter(a => a.visible).map((route: IRcSidebarItem) => {
            if(!route.isHeader) {
              return (
                <RcSidebarItem {...route} onMouseOver={onMouseOverHandler} key={route.id}
                               isMinimal={props.isNavCollapsed} isFloat={false}/>
              )
            }

            if(!props.isNavCollapsed) {
              return <Text key={generateUniqueID()} style={styles.headerText} variant={'small'}>{route.title}</Text>
            } else {
              return <span key={generateUniqueID()} style={styles.headerTextMinIcon}>&nbsp;</span>
            }
          })}
        </Stack>
        {(props.isNavCollapsed) &&
          <Stack id={'rc-sidebar-submenu'} className={styles.minimalSubMenuContainer}>
            <p style={{margin: 10, fontSize: 14}}><b>{currentOverSidebarItem?.title}</b></p>
            {currentOverSidebarItem?.children?.filter(a => a.visible).map((item: IRcSidebarItem) => {
              return (
                <RcSidebarItem {...item} key={item.id} isMinimal={false} isFloat={true} />
              )
            })}
          </Stack>
        }
      </div>
    )
}
