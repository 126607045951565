import {IFieldOperationStrategy} from "../filters/IFieldOperationStrategy";
import {AndOperatorStrategy} from "./AndOperatorStrategy";
import {OrOperatorStrategy} from "./OrOperatorStrategy";
import {IFieldOperator} from "../models/IFieldOperator";

export class CompositeOperatorStrategy implements IFieldOperationStrategy{

  _items: IFieldOperationStrategy[] = [];

  constructor(operator: IFieldOperator) {
    switch (operator.operator) {
      case "and":
        this._items.push(new AndOperatorStrategy(operator.operands))
        break;
      case "or":
        this._items.push(new OrOperatorStrategy(operator.operands))
        break;
      case "not":
        break;
      default:
        break;
    }
  }

  add(_operator: IFieldOperationStrategy): void {

    const operatorType = typeof(_operator);

    switch (operatorType.constructor) {
      case AndOperatorStrategy.constructor:
        this._items.push(_operator);
        break;
      case OrOperatorStrategy.constructor:
        this._items.push(_operator);
        break;
      default:
        throw new Error("Unrecognized operator");
    }
  }

  stringify(): string {

    return this._items.map((x) => x.stringify()).join(' ');

  }

}
