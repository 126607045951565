import React from 'react';
import {ReactComponent as FilterIcon} from "../assets/icons/filter.svg";
import {ReactComponent as FilterSolidIcon} from "../assets/icons/filter-solid.svg";
import {ReactComponent as SortUpIcon} from "../assets/icons/sort-up.svg";
import {ReactComponent as SortDownIcon} from "../assets/icons/sort-down.svg";

export enum RcIcons {
  filter,
  filterSolid,
  sortUp,
  sortDown
}

export class IconFactory {

  public static get(iconName: RcIcons, id: string, fill: string): JSX.Element {

    const style = {
      verticalAlign: 'top',
      height: '100%',
      fill: fill,
    };

    switch (iconName) {
      case RcIcons.filter:
        return <FilterIcon id={id} style={style} />
      case RcIcons.filterSolid:
        return <FilterSolidIcon id={id} style={style} />
      case RcIcons.sortUp:
        return <SortUpIcon id={id} style={style} />
      case RcIcons.sortDown:
        return <SortDownIcon id={id} style={style} />
      default:
        return <div>no_icon</div>
    }
  }
}
