import React, {SyntheticEvent, useEffect, useState} from "react";
import {Checkbox} from '@fluentui/react';
import {Text} from "@fluentui/react/lib/Text";
import {IRcFormFieldProps, RcFormBaseField} from "./RcFormBaseField";
import {RcFormFieldLabel} from "./RcFormFieldLabel";

export interface IRcFormCheckboxFieldProps extends IRcFormFieldProps {
  onText?: string,
  offText?: string,
}

export const RcFormCheckboxField = (props: IRcFormCheckboxFieldProps): JSX.Element => {

  const [value, setValue] = useState<boolean | undefined>(undefined);

  useEffect(() => {
    if(props.initialValue !== undefined) {
      setValue(props.initialValue);
    }
  }, [])

  const onChangeValue = React.useCallback(
    (_event: SyntheticEvent<HTMLElement, Event>, _newValue?: boolean | undefined) => {
      setValue(_newValue);
    },
    [],
  );

  const _renderLabel = () => {
    return (
      <RcFormFieldLabel type={'span'} label={props.label} infoText={props.tooltip} id={'label_checkbox_' + props.id} required={false} />
    );
  }

  const EditComponent = <Checkbox onRenderLabel={_renderLabel} id={props.id} key={props.id} defaultChecked={value} onChange={onChangeValue} />
  const ViewComponent = <Text>{value ? props.label + ' (' + (props.onText ? props.onText : 'on' + ')') : props.label + ' (' + (props.offText ? props.offText : 'off' + ')')}</Text>

  if((props.initialValue && value !== undefined) || (props.initialValue === undefined)) {
    return(
      <RcFormBaseField fieldProps={props} value={value} editModeComponent={EditComponent} viewModeComponent={ViewComponent} showLabel={false} />
    )
  }

  return(
    <div></div>
  )
}



