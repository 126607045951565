import 'reflect-metadata';
import {RcEntityMetadataTypes} from "../rcEntityMetadataTypes";

export function rcProperty<T>(displayName: string, complexTypeDisplayValue: ((item: T) => string) | null = null) {
  return function(target: Object, propertyName: string) {
    Reflect.defineMetadata(RcEntityMetadataTypes.rcDomainEntityPropertyDisplayName, displayName, target, propertyName);
    Reflect.defineMetadata(RcEntityMetadataTypes.rcDomainEntityProperty, true, target, propertyName);
    if(complexTypeDisplayValue) {
      Reflect.defineMetadata(RcEntityMetadataTypes.rcDomainEntityPropertyComplexType, true, target, propertyName);
      Reflect.defineMetadata(RcEntityMetadataTypes.rcDomainEntityPropertyComplexTypeDisplayValue, complexTypeDisplayValue, target, propertyName);
    }
  }
}
