import {IFieldOperationStrategy} from "./IFieldOperationStrategy";
import {IFieldOperand} from "../models/IFieldOperand";
import {ComparisonConditionOperandTypes} from "../models/ComparisonConditionOperandTypes";

export class ComparisonOperationStrategy implements IFieldOperationStrategy {

  _item: IFieldOperand;

  constructor(item: IFieldOperand) {
    this._item = item;
  }

  stringify(): string {

    switch (this._item.condition) {
      case ComparisonConditionOperandTypes.equal:
        return 'obj.' + this._item.propertyName + " == " + this._escapeValueByType(this._item.propertyType, this._item.value);
      case ComparisonConditionOperandTypes.notequal:
        return 'obj.' + this._item.propertyName + " != " + this._escapeValueByType(this._item.propertyType, this._item.value);
      case ComparisonConditionOperandTypes.lessthen:
        return 'obj.' + this._item.propertyName + " < " + this._escapeValueByType(this._item.propertyType, this._item.value);
      case ComparisonConditionOperandTypes.morethen:
        return 'obj.' + this._item.propertyName + " > " + this._escapeValueByType(this._item.propertyType, this._item.value);
      case ComparisonConditionOperandTypes.lessorequalto:
        return 'obj.' + this._item.propertyName + " <= " + this._escapeValueByType(this._item.propertyType, this._item.value);
      case ComparisonConditionOperandTypes.moreorequalto:
        return 'obj.' + this._item.propertyName + " >= " + this._escapeValueByType(this._item.propertyType, this._item.value);
      case ComparisonConditionOperandTypes.between:
        return '(obj.' + this._item.propertyName + " >= " + this._escapeValueByType(this._item.propertyType, this._item.value) + ' && ' + 'obj.' + this._item.propertyName + " <= " + this._escapeValueByType(this._item.propertyType, this._item.value2) + ')';
      default:
        return "";
    }
  }

  add(_operation: IFieldOperationStrategy): void {
    throw new Error("Not implemented");
  }

  _escapeValueByType(type: 'string' | 'number' | 'date', value: any) {
    if(type === 'string' || type === 'date')
      return "\"" + value + "\"";

    return value;
  }
}
