import React, {useEffect, useState} from "react";
import {IRcPaginatedResult} from "../../models/IRcPaginatedResult";
import {IColumn, IconButton, Stack} from "@fluentui/react";
import {CrudService} from "../../services/crud/crud.service";
import {useBoolean} from "@fluentui/react-hooks";
import {RcCrudPageCreateEdit} from "./components/RcCrudPageCreateEdit";
import {AxiosInstance} from "axios";
import {RcEntityFilterType} from "../../aop";
import {IRcDataGridColumn, RcDataGrid} from "../RcDataGrid";
import {IRcCommandBarButtonProps} from "../RcPageToolbar";
import {RcDialog} from "../RcDialog";
import {RcConfirmDialog} from "../RcConfirmationDialog";
import {RcPageWithToolbar} from "../RcPageWithToolbar";
import {IRcFormDropdownFieldLocalOptionsProps, IRcFormValidationRule} from "../RcForm";


export abstract class RcCrudPageBaseFieldType {
  validationRules?: IRcFormValidationRule[];
  filterType?: RcEntityFilterType;

  setFilterType = (filterType: RcEntityFilterType) => {
    this.filterType = filterType;
    return this;
  }

  setValidationRules = (rules: IRcFormValidationRule[]) => {
    this.validationRules = rules;
    return this;
  }
}

export class RcCrudPageTextFieldType extends RcCrudPageBaseFieldType {

}

export class RcCrudPageLookupFieldType extends RcCrudPageBaseFieldType {
  fieldValue: string = "id";
  fieldDisplayValue: string = "name";
  enableAdvancedSearch: boolean = false;
  emptyResultText: string = "Nessun risultato";
  isMultiselect: boolean = false;
  searchDelay: number | undefined = 500;
  minCharactersForSearch: number = 3;

  retrieveDataFunction: ((q: string) => Promise<any[]>) | undefined = undefined;

  setFieldValue = (fieldValue: string) => {
    this.fieldValue = fieldValue;
    return this;
  }

  setFieldDisplayValue = (fieldValue: string) => {
    this.fieldDisplayValue = fieldValue;
    return this;
  }

  setEnabledAdvancedSearch = (enabled: boolean) => {
    this.enableAdvancedSearch = enabled;
    return this;
  }

  setEmptyResultText = (emptyResultText: string) => {
    this.emptyResultText = emptyResultText;
    return this;
  }

  setMultiselect = (enabled: boolean) => {
    this.isMultiselect = enabled;
    return this;
  }

  setSearchDelay = (delay: number) => {
    this.searchDelay = delay;
    return this;
  }

  setMinCharactersForSearch = (characters: number) => {
    this.minCharactersForSearch = characters;
    return this;
  }

  setRetrieveDataFunction = (func: (q: string) => Promise<any[]>) => {
    this.retrieveDataFunction = func;
    return this;
  }
}

export class RcCrudPageNumericFieldType extends RcCrudPageBaseFieldType {

  setMinMaxInfo = (min: number | undefined, max: number | undefined) => {
    this.min = min;
    this.max = max;
    return this;
  }

  min?: number;
  max?: number;
}

export class RcCrudPageDropdownFieldType extends RcCrudPageBaseFieldType {
  fieldValue: string = "id";
  fieldDisplayValue: string = "name";
  isMultiselect: boolean = false;
  options: IRcFormDropdownFieldLocalOptionsProps[] = [];

  setFieldValue = (fieldValue: string) => {
    this.fieldValue = fieldValue;
    return this;
  }

  setFieldDisplayValue = (fieldValue: string) => {
    this.fieldDisplayValue = fieldValue;
    return this;
  }

  setMultiselect = (enabled: boolean) => {
    this.isMultiselect = enabled;
    return this;
  }

  setOptions = (options: IRcFormDropdownFieldLocalOptionsProps[]) => {
    this.options = options;
    return this;
  }
}

export interface IRcCrudPageField {
  /**
   * Unique field id
   */
  id: string,

  /**
   * Label used for grid column and form label
   */
  label: string,

  /**
   * Dto binded property
   */
  fieldName: string,

  gridColumnMinWidth?: number,
  gridColumnMaxWidth?: number,

  /**
   * True if field is visible in form
   */
  visibleInForm: boolean,

  /**
   * True if field is visible in grid
   */
  visibleInGrid: boolean,

  /**
   * True if field is required in form submit
   */
  isRequired: boolean,

  /**
   * Tooltip used in form
   */
  tooltip?: string;

  /**
   * Custom form validation rules (create/edit forms)
   */
  formValidationRules?: IRcFormValidationRule[];

  /**
   * Custom renderer for grid column
   */
  gridColumnRenderer?: ((item?: any, index?: (number | undefined), column?: (IColumn | undefined)) => any);

  type: RcCrudPageTextFieldType | RcCrudPageNumericFieldType | RcCrudPageLookupFieldType | RcCrudPageDropdownFieldType
}

export interface IRcCrudPageProps {

  /**
   * Id of the RcCrudPage (used also for RcGrid)
   */
  id: string,

  /**
   * Title of the RcPageWithToolbar
   */
  title: string,

  /**
   * Subtitle of the RcPageWithToolbar
   */
  subtitle?: string,

  /**
   * Array of fields of the CRUD entity
   */
  fields: IRcCrudPageField[],

  /**
   * REST resource path of the CRUD entity
   */
  resourcePath: string,

  /**
   * DTO id property
   */
  fieldIdName: string,

  /**
   * Single name of the entity. Ex: User
   */
  entityNameSingle: string,

  /**
   * Plural name of the entity. Ex: Users
   */
  entityNamePlural: string,

  /**
   * Component rendered before the RcGrid
   */
  beforeGridComponent?: JSX.Element,

  /**
   * Component rendered after the RcGrid
   */
  afterGridComponent?: JSX.Element,

  enableEditMode?: boolean,

  enableCreateMode?: boolean,

  enableDeleteMode?: boolean,

  globalSearchFields?: string[],

  httpClient: AxiosInstance,

  pageMaxWidth?: number,

  paginated: boolean,

  otherActions?: [{ iconName: string, onClick: (item: any) => void, title: string }],
  otherActionsPosition?: 'start' | 'end';

  disableEdit?: (item: any) => boolean,
  disableDelete?: (item: any) => boolean,
}

export function RcCrudPage<T>(props: IRcCrudPageProps) {

  const [items, setItems] = useState<IRcPaginatedResult<T> | T[]>();
  const [dataLoading, setDataLoading] = useState<boolean>(false);
  const [gridColumns, setGridColumns] = useState<IRcDataGridColumn[] | undefined>(undefined);
  const [isAddEntityModalOpened, {toggle: toggleAddEntityModal}] = useBoolean(false);
  const [isEditEntityModalOpened, {toggle: toggleEditEntityModal}] = useBoolean(false);
  const [isDeleteEntityModalOpened, {toggle: toggleDeleteEntityModal}] = useBoolean(false);
  const [operationError, setOperationError] = useState<string | undefined>(undefined);

  const [currentItem, setCurrentItem] = useState<any | undefined>(undefined);
  const crudService = new CrudService<T>(props.httpClient, props.resourcePath);

  const [toolbarItems, setToolbarItems] = useState<IRcCommandBarButtonProps[]>([]);

  const [gridSkip, setGridSkip] = useState<number>(-1);
  const [gridTake, setGridTake] = useState<number>(-1);

  const [updateDataCalled, setUpdateDataCalled] = useState<Date | undefined>(undefined);

  const openEditModal = (entity: T) => {
    setCurrentItem(entity);
    toggleEditEntityModal();
  }

  const openDeleteModal = (entity: T) => {
    setCurrentItem(entity);
    toggleDeleteEntityModal();
  }

  useEffect(() => {
    const item: IRcCommandBarButtonProps[] = [];

    if (props.enableCreateMode) {
      item.push({
        key: 'crudpage-commandbar-add',
        title: `Aggiungi ${props.entityNameSingle}`,
        text: `Aggiungi ${props.entityNameSingle}`,
        onClick: toggleAddEntityModal,
        iconProps: {iconName: 'Add'},
      });
    }

    item.push({
      key: 'crudpage-commandbar-refrsh',
      title: `Aggiorna ${props.entityNamePlural}`,
      text: `Aggiorna ${props.entityNamePlural}`,
      onClick: () => setUpdateDataCalled(new Date()),
      iconProps: {iconName: 'Refresh'}
    })

    setToolbarItems(item);
  }, [])


  useEffect(() => {

    const fieldsArray: IRcDataGridColumn[] = [];
    props.fields.forEach((field, index) => {
      if (!field.visibleInGrid)
        return;

      fieldsArray.push({
        key: field.id,
        fieldName: field.fieldName,
        name: field.label,
        visible: true,
        minWidth: field.gridColumnMinWidth ? field.gridColumnMinWidth : 100,
        maxWidth: !field.gridColumnMaxWidth ? (index === props.fields.length - 1 ? 9999 : 200) : field.gridColumnMaxWidth,
        isResizable: true,
        onRender: field.gridColumnRenderer,
        filterType: field?.type?.filterType
      })
    });

    if (props.enableEditMode || props.enableDeleteMode) {
      fieldsArray.push({
        key: 'actions',
        name: 'Azioni',
        minWidth: 100,
        visible: true,
        fieldName: 'actions',
        onRender: (item: T, _index: number | undefined, _column: IColumn | undefined) => {
          return (
            <Stack horizontal>

              {(props.otherActions && props.otherActions.length > 0 && props.otherActionsPosition === 'start') &&
                generateOtherActionsButton(item)
              }

              {(props.enableDeleteMode && (!props.disableDelete || props.disableDelete(item))) &&
              <IconButton onClick={() => openDeleteModal(item)} iconProps={{iconName: 'Trash'}}
                          title={`Elimina ${props.entityNameSingle}`}
                          ariaLabel={`Elimina ${props.entityNameSingle}`}/>
              }
              {(props.enableEditMode && (!props.disableEdit || props.disableEdit(item))) &&
              <IconButton onClick={() => openEditModal(item)} iconProps={{iconName: 'Edit'}}
                          title={`Modifica ${props.entityNameSingle}`}
                          ariaLabel={`Modifica ${props.entityNameSingle}`}/>
              }

              {(props.otherActions && props.otherActions.length > 0 && props.otherActionsPosition === 'end') &&
                generateOtherActionsButton(item)
              }

            </Stack>
          );
        }
      })
    }

    setGridColumns(fieldsArray);

  }, [props.fields])

  useEffect(() => {
    if (gridColumns) {
      retrieveData();
    }
  }, [gridColumns])


  const generateOtherActionsButton = (item: any) => {

    const actionButtons: JSX.Element[] = [];

    props.otherActions?.forEach((action, index) => {
      actionButtons.push(
        <IconButton key={'custom-grid-action-' + index} onClick={() => action.onClick(item)} iconProps={{iconName: action.iconName}}
                    title={action.title}
                    ariaLabel={action.title}/>
      )
    });

    return actionButtons;
  }

  useEffect(() => {
    retrieveData();
  }, [updateDataCalled])

  const retrieveData = (skip: number = -1, take: number = -1, filters?: string, globalSearchQuery?: string) => {
    setDataLoading(true);

    let cSkip = 0;
    let cTake = 0;

    if(skip === -1)
      cSkip = gridSkip !== -1 ? gridSkip : 0;
    else
      cSkip = skip;

    if(take === -1)
      cTake = gridTake !== -1 ? gridTake : 50;
    else
      cTake = take;

    setGridSkip(cSkip);
    setGridTake(cTake);


    let filtersString = filters ? filters : '';
    let globalSearchFilterArray: string[] = [];

    if (globalSearchQuery && props.globalSearchFields) {
      let gFilter = '';

      props.globalSearchFields.forEach((field) => {
        gFilter = `(obj.${field}.ToLower().Contains("${globalSearchQuery}".ToLower()))`;
        globalSearchFilterArray.push(gFilter);
      });

    }

    if (globalSearchFilterArray.length > 0) {

      if (filtersString !== '')
        filtersString = filtersString + ' or ' + globalSearchFilterArray.join(" or ")
      else
        filtersString = globalSearchFilterArray.join(" or ")
    }


    crudService.find(cTake, cSkip, filtersString)
      .then((res) => {
        setItems(res);
        setDataLoading(false);
      })
      .catch((_err) => {
        setDataLoading(false);
      });
  }

  const deleteEntity = () => {
    crudService.delete(currentItem[props.fieldIdName])
      .then((_res) => {
        setUpdateDataCalled(new Date());
        setOperationError(undefined);
        toggleDeleteEntityModal();
      })
      .catch((_err) => {
        setOperationError(`${props.entityNamePlural}: Errore durante l'eliminazione.`);
      });
  }

  return (
    <RcPageWithToolbar title={props.title} subtitle={props.subtitle} maxWidth={props.pageMaxWidth} items={toolbarItems}
                       overflowItems={[]} farItems={[]}>
      <div>
        {props.beforeGridComponent &&
          props.beforeGridComponent
        }

        {(gridColumns) &&
        <RcDataGrid
          id={props.id}
          items={props.paginated ? ((items as IRcPaginatedResult<T>)?.results) : (items as T[])}
          dataLoading={dataLoading}
          paginationInfo={props.paginated ? {totalResultCount: (items as IRcPaginatedResult<T>)?.totalResultCount, skip: (items as IRcPaginatedResult<T>)?.skip, take: (items as IRcPaginatedResult<T>)?.take} : undefined}
          columns={gridColumns}
          itemSelectionMode={'none'}
          enableGlobalSearch={!!props.globalSearchFields}
          onDataReloadRequired={(currentPage, itemsPerPage, filters, globalQuery) => retrieveData(((currentPage - 1) * itemsPerPage), itemsPerPage, filters, globalQuery)}/>
        }

        {props.afterGridComponent &&
          props.afterGridComponent
        }

        {props.enableCreateMode &&
          <RcDialog isOpen={isAddEntityModalOpened} title={`Aggiungi ${props.entityNameSingle}`}
                    subTitle={`Aggiungi ${props.entityNameSingle}`}>
            <RcCrudPageCreateEdit
              httpClient={props.httpClient}
              entityId={0}
              resourcePath={props.resourcePath}
              onCreateAction={() => {
                toggleAddEntityModal();
                setUpdateDataCalled(new Date())
              }}
              onCancelAction={toggleAddEntityModal}
              fields={props.fields}/>
          </RcDialog>
        }

        {(currentItem && isEditEntityModalOpened && props.enableEditMode) &&
        <RcDialog isOpen={isEditEntityModalOpened} title={`Modifica ${props.entityNameSingle}`}
                  subTitle={`Modifica ${props.entityNameSingle}`}>
          <RcCrudPageCreateEdit
            httpClient={props.httpClient}
            entityId={currentItem[props.fieldIdName]}
            resourcePath={props.resourcePath}
            onCreateAction={() => {
              toggleEditEntityModal();
              setUpdateDataCalled(new Date())
            }}
            onCancelAction={toggleEditEntityModal}
            fields={props.fields}
            entityToEdit={currentItem}/>
        </RcDialog>
        }

        {(currentItem && isDeleteEntityModalOpened && props.enableDeleteMode) &&
        <RcConfirmDialog
          isOpen={isDeleteEntityModalOpened}
          title={`Elimina ${props.entityNameSingle}`}
          subTitle={'Sei sicuro di voler effettuare l\'eliminazione?'}
          onConfirmAction={deleteEntity}
          errorMessageText={operationError}
          onCancelAction={toggleDeleteEntityModal}/>
        }

      </div>
    </RcPageWithToolbar>
  )
}

RcCrudPage.defaultProps = {
  enableEditMode: true,
  enableCreateMode: true,
  enableDeleteMode: true,
  otherActionsPosition: 'start'
}
