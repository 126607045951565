import {IButtonStyles, IconButton, IRawStyle, Stack, Icon} from '@fluentui/react';
import React from 'react';

export interface IRcTagItem {
  deleteAction?: (value: string | number) => void;
  label: string,
  value: string | number,
  background?: string,
  textColor?: string,
  buttonStyle?: IButtonStyles
  iconName?: string,
}

export function RcTagItem(props: IRcTagItem) {

  const rootStyle: IRawStyle = {
      fontSize: 14,
      fontWeight: 400,
      outline: 'transparent',
      position: 'relative',
      boxSizing: 'content-box',
      flexShrink: 1,
      margin: 2,
      height: 26,
      lineHeight: 26,
      cursor: 'default',
      display: 'flex',
      flexWrap: 'nowrap',
      minWidth: 0,
      maxWidth: 300,
      borderRadius: 2,
      color: props.textColor ? props.textColor : 'rgb(50, 49, 48)',
      background: props.background ? props.background : 'rgb(243, 242, 241)',
      userSelect: 'none',
      width: 'fit-content',
  }

  const textStyle: IRawStyle = {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    minWidth: 30,
    margin: '0px 8px'
  }

  const iconStyle: IRawStyle = {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    margin: '0px 8px'
  }

  const buttonStyle: IButtonStyles = {
    root: {
      height: 26,
    },
    icon: {
      fontSize: 14,
    }
  }

  return(
    <Stack horizontal styles={{root: rootStyle}} maxWidth={'auto'}>
      {props.iconName &&
        <Stack.Item styles={{root: iconStyle}}>
          <Icon iconName={props.iconName} />
        </Stack.Item>
      }
      <Stack.Item styles={{root: textStyle}}>
        {props.label}
      </Stack.Item>
      {props.deleteAction &&
        <Stack.Item>
          <IconButton styles={buttonStyle} iconProps={{iconName: 'Cancel'}} onClick={() => props.deleteAction!(props.value)} />
        </Stack.Item>
      }
    </Stack>
  )
}
