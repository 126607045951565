export enum RcEntityMetadataTypes {
  rcDomainEntity                        = 'rc:domain:entity',
  rcDomainEntityPromise                 = 'rc:domain:entity:promise',
  rcDomainEntityDefaultDisplayProperty  = 'rc:domain:entity:promise',
  rcDomainEntityPropertyDisplayName     = 'rc:domain:entity:property:displayName',
  rcDomainEntityPropertyKey             = 'rc:domain:entity:property:key',
  rcDomainEntityProperty                = 'rc:domain:entity:property',
  rcDomainEntityPropertyFilter          = 'rc:domain:entity:property:filter',
  rcDomainEntityPropertyComplexType     = 'rc:domain:entity:property:complexType',
  rcDomainEntityPropertyComplexTypeDisplayValue     = 'rc:domain:entity:property:complexTypeDisplayValue',
}
