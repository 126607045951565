import {DefaultButton, DialogFooter, MessageBar, MessageBarType, Separator} from "@fluentui/react";
import React, {useCallback, useEffect, useState} from "react";
import {useBoolean} from "@fluentui/react-hooks";
import {CrudService} from "../../../services/crud/crud.service";
import {
  IRcCrudPageField, RcCrudPageDropdownFieldType,
  RcCrudPageLookupFieldType,
  RcCrudPageNumericFieldType,
  RcCrudPageTextFieldType
} from "../RcCrudPage";
import {AxiosInstance} from "axios";
import {
  IRcFormValidationRule,
  RcForm, RcFormDropdownField, RcFormLookupField,
  RcFormNumericField,
  RcFormRequiredValidationRule,
  RcFormSubmitButton,
  RcFormTextField
} from "../../RcForm";

export interface IRcCrudPageCreateEditProps {
    entityId: number;
    resourcePath: string;
    onCreateAction: () => void,
    onCancelAction: () => void,
    entityToEdit?: any,
    fields: IRcCrudPageField[],
    httpClient: AxiosInstance,
}

export function RcCrudPageCreateEdit(props: IRcCrudPageCreateEditProps) {

    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
    const [createRequestSent, {toggle: toggleCreateRequestSent}] = useBoolean(false);
    const requiredValidationRule: RcFormRequiredValidationRule = new RcFormRequiredValidationRule('Campo obbligatorio');

    const crudService = new CrudService(props.httpClient, props.resourcePath);

    function getFormFieldFromType(field: IRcCrudPageField) {

        if(field.type instanceof RcCrudPageTextFieldType)
            return <RcFormTextField key={'key-' + field.fieldName} id={field.fieldName} label={field.label} validationRules={generateValidationRulesForField(field)} mode={'edit'} tooltip={field.tooltip ? field.tooltip : ''} placeholder={'Inserisci ' + field.label} initialValue={props.entityToEdit ? props.entityToEdit[field.fieldName] : undefined} />

        else if(field.type instanceof RcCrudPageNumericFieldType)
            return <RcFormNumericField key={'key-' + field.fieldName} min={field.type.min} max={field.type.max} id={field.fieldName} label={field.label} validationRules={generateValidationRulesForField(field)} mode={'edit'} tooltip={field.tooltip ? field.tooltip : ''} placeholder={'Inserisci ' + field.label} initialValue={props.entityToEdit ? props.entityToEdit[field.fieldName] : undefined} />

        else if(field.type instanceof RcCrudPageDropdownFieldType)
            return <RcFormDropdownField id={field.fieldName} label={field.label} validationRules={generateValidationRulesForField(field)} mode={'edit'} tooltip={field.tooltip ? field.tooltip : ''} placeholder={'Inserisci ' + field.label} options={(field.type as RcCrudPageDropdownFieldType).options} initialValue={props.entityToEdit ? props.entityToEdit[field.fieldName] : undefined} />

        else if(field.type instanceof RcCrudPageLookupFieldType) {
            return <RcCrudPageLookupFieldWrapper lookupField={field.type} field={field} validationRules={generateValidationRulesForField(field)} entityToEdit={props.entityToEdit} />
        }

        return <div></div>
    }

    function onSubmit(data: any): void {

        const dataToSend: any = {};

        props.fields.forEach((field) => {

          if(data[field.fieldName] !== undefined) {
            if (field.type instanceof RcCrudPageNumericFieldType) {
              dataToSend[field.fieldName] = +data[field.fieldName];
            } else {
              dataToSend[field.fieldName] = data[field.fieldName];
            }
          }
        })

        if(props.entityToEdit){
            updateEntity(dataToSend);
        } else {
            createEntity(dataToSend);
        }

    }

    function updateEntity(data: any): void {
        if(errorMessage)
            setErrorMessage(undefined);

        toggleCreateRequestSent();

        crudService.update(props.entityId, data)
            .then((_res: any) => {
                toggleCreateRequestSent();
                props.onCreateAction();
            })
            .catch((err) => {
                toggleCreateRequestSent();
                setErrorMessage(err.response.data);
            })
    }

    function createEntity(data: any): void {
        if(errorMessage)
            setErrorMessage(undefined);

        toggleCreateRequestSent();

        crudService.create(data)
            .then((_res) => {
                toggleCreateRequestSent();
                props.onCreateAction();
            })
            .catch((err) => {
                toggleCreateRequestSent();
                setErrorMessage(err.response.data);
            })
    }

    const generateValidationRulesForField = (field: IRcCrudPageField) => {
        const rules: IRcFormValidationRule[] = [];

        if(field.isRequired)
            rules.push(requiredValidationRule);

        if(field.type.validationRules) {
            rules.push(...field.type.validationRules);
        }

        return rules;
    }

    return(
        <RcForm mode={'edit'} onSubmit={() => onSubmit} validationMode={'onSubmit'} validationSummary={'none'} onDataChange={() => console.log('')}>
            {errorMessage &&
                <MessageBar messageBarType={MessageBarType.error} className="rc-margin-b-16">
                    {errorMessage}
                </MessageBar>
            }
            <div className="ms-Grid" dir="ltr">
                <div className="ms-Grid-row rc-margin-b-8">
                    {
                        props.fields.filter(a => a.visibleInForm).map((field) => {
                            return(
                                <div key={'field-key-' + field.fieldName} className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 rc-margin-b-16">
                                    {getFormFieldFromType(field)}
                                </div>
                            )
                        })
                    }
                </div>
            </div>
            <Separator />
            <DialogFooter>
                <RcFormSubmitButton primary={true} disabled={createRequestSent}>{props.entityToEdit ? 'Modifica' : 'Crea'}</RcFormSubmitButton>
                <DefaultButton onClick={() => {props.onCancelAction(); setErrorMessage(undefined)}} text="Annulla" disabled={createRequestSent}/>
            </DialogFooter>
        </RcForm>
    )
}

export interface IRcCrudPageLookupFieldWrapperProps {
    field: IRcCrudPageField
    lookupField: RcCrudPageLookupFieldType;
    validationRules: IRcFormValidationRule[];
    entityToEdit?: any;
}

function RcCrudPageLookupFieldWrapper(props: IRcCrudPageLookupFieldWrapperProps) {

    const [dataLoading, setDataLoading] = useState<boolean>(false);
    const [items, setItems] = useState<any[]>([]);
    const [searchQuery, setSearchQuery] = useState<string | undefined>(undefined)


    const fetchMyAPI = useCallback(async () => {
        if(dataLoading) {
            const items = await props.lookupField.retrieveDataFunction!(searchQuery!);
            setItems(items);
            setDataLoading(false);
        }
    }, [dataLoading])

    useEffect(() => {
        if(dataLoading)
            fetchMyAPI();

    }, [dataLoading])

    useEffect(() => {
        if(searchQuery){
            setDataLoading(true)
        }
    }, [searchQuery])


    return (
        <RcFormLookupField key={'key-' + props.field.fieldName}
                           id={props.field.fieldName}
                           label={props.field.label}
                           validationRules={props.validationRules}
                           mode={'edit'}
                           tooltip={props.field.tooltip ? props.field.tooltip : ''}
                           placeholder={'Inserisci ' + props.field.label}
                           initialValue={props.entityToEdit ? props.entityToEdit[props.field.fieldName] : undefined}
                           multiselect={props.lookupField.isMultiselect}
                           searchDelay={props.lookupField.searchDelay}
                           fieldDisplayValue={props.lookupField.fieldDisplayValue}
                           emptyResultText={props.lookupField.emptyResultText}
                           fieldValue={props.lookupField.fieldValue}
                           //enableAdvancedSearch={props.lookupField.enableAdvancedSearch}
                           onSearchQueryChanged={async (q: string) => {
                               setSearchQuery(q);
                           }}
                           minCharactersForSearch={props.lookupField.minCharactersForSearch}
                           items={items}
                           dataLoading={dataLoading}
        />
    )
}
