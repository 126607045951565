import {IFieldOperationStrategy} from "./IFieldOperationStrategy";
import {StringConditionOperandTypes} from "../models/StringConditionOperandTypes";
import {IFieldOperand} from "../models/IFieldOperand";

export class StringOperationStrategy implements IFieldOperationStrategy {

  _item: IFieldOperand;

  constructor(item: IFieldOperand) {
    this._item = item;
  }

  stringify(): string {

    switch (this._item.condition) {
      case StringConditionOperandTypes.equal:
        return 'obj.' + this._item.propertyName + ".ToLower() == \"" + this._item.value + "\".ToLower()";
      case StringConditionOperandTypes.notequal:
        return 'obj.' + this._item.propertyName + ".ToLower() != \"" + this._item.value + "\".ToLower()";
      case StringConditionOperandTypes.contains:
        return 'obj.' + this._item.propertyName + ".ToLower().Contains(\"" + this._item.value + "\".ToLower())";
      case StringConditionOperandTypes.endswith:
        return 'obj.' + this._item.propertyName + ".ToLower().EndsWith(\"" + this._item.value + "\".ToLower())";
      case StringConditionOperandTypes.startswith:
        return 'obj.' + this._item.propertyName + ".ToLower().StartsWith(\"" + this._item.value + "\".ToLower())";
      case StringConditionOperandTypes.notcontains:
        return '!obj.' + this._item.propertyName + ".ToLower().Contains(\"" + this._item.value + "\".ToLower())";
      default:
        return "";
    }
  }

  add(_operation: IFieldOperationStrategy): void {
    throw new Error("Not implemented");
  }
}
