import React, {useEffect, useState} from "react";
import {TextField} from "@fluentui/react";
import {Text} from "@fluentui/react/lib/Text";
import {IRcFormFieldProps, RcFormBaseField} from "./RcFormBaseField";

export interface IRcFormPasswordFieldProps extends IRcFormFieldProps {

}

export const RcFormPasswordField = (props: IRcFormPasswordFieldProps): JSX.Element => {

  const [value, setValue] = useState<string | undefined>(undefined);
  const [errorMessage] = useState<string | undefined>(undefined);

  useEffect(() => {
    props.mode = 'edit';
  }, [])

  const EditComponent = <TextField autoComplete={'off'} errorMessage={errorMessage} type={'password'} canRevealPassword id={props.id} key={props.id} placeholder={props.placeholder} onChange={(_v,e) => { setValue(e) }}/>;
  const ViewComponent = <Text>ciao</Text>

  return(
    <RcFormBaseField fieldProps={props} value={value} editModeComponent={EditComponent} viewModeComponent={ViewComponent} />
  )
}
