import React, {createContext, useState} from "react";
//import {generateRoutesFromSidebarCommands} from "../utilities/routing";
import {IRcRoute} from "../components/RcRoute/IRcRoute";
import {generateRoutesFromSidebarCommands} from "../utilities/routing";
import {matchPath} from "react-router-dom";
import {RcApplicationMenu} from "../common/RcApplicationMenu";

const LS_SIDEBAR_ITEM = '_rcSidebarState';

export interface IRcLayoutActionButton {
  id: string,
  label: string,
  icon: string,
  action: () => void,
  scope: string,
}

export interface RcLayoutProviderProps {
  sidebarCommands: RcApplicationMenu[],
  children: JSX.Element,
}

export type RcLayoutContextState = {

    /**
     * Check if RCSidebar is collapsed or not
     */
    isNavCollapsed: boolean;

    /**
     * Toggle RcSideBar state (Normal or Minimal)
     */
    toggleNavState: () => void;

    /**
     * Current Header Action buttons
     */
    actionButtons: IRcLayoutActionButton[],

    /**
     * Attach new Action button to HeaderBar
     * @param actionButton
     */
    attachActionButton: (actionButton: IRcLayoutActionButton) => void;

    /**
     * Detach button from HeaderBar by key or scope
     * @param value
     * @param type
     */
    detachActionButton: (value:string, type: 'key' | 'scope') => void;

    getCurrentRoute: () => IRcRoute | undefined;
};

const contextDefaultValues: RcLayoutContextState = {
    isNavCollapsed: localStorage.getItem(LS_SIDEBAR_ITEM) === 'true',
    toggleNavState: () => {},
    actionButtons: [],
    attachActionButton: (_actionButton: IRcLayoutActionButton) => {},
    detachActionButton: (_value:string, _type: 'key' | 'scope') => {},
    getCurrentRoute: () => new class implements IRcRoute {
      breadcrumb: string;
      component: any;
      isPublic: boolean;
      key: string;
      layout: "default" | "blank" | React.Component;
      path: string;
    },
};

export const RcLayoutContext = createContext<RcLayoutContextState>(
    contextDefaultValues
);

const RcLayoutProvider = (props: RcLayoutProviderProps) => {
    const [isNavCollapsed, setIsNavCollapsed] = useState<boolean>(contextDefaultValues.isNavCollapsed);
    const [actionButtons, setActionButtons] = useState<IRcLayoutActionButton[]>(contextDefaultValues.actionButtons);
    let routesCache: IRcRoute[] | null = null;

    /**
     * Toggle RcSideBar state (Normal or Minimal)
     */
    const toggleNavState = () => {
      localStorage.setItem(LS_SIDEBAR_ITEM, !isNavCollapsed ? 'true': 'false');
      setIsNavCollapsed((isNavCollapsed) => !isNavCollapsed);
    }

    /**
     * Attach new Action button to HeaderBar
     * @param actionButton
     */
    const attachActionButton = (actionButton: IRcLayoutActionButton) => {
      setActionButtons(old => [...old, actionButton])
    }

    /**
     * Detach button from HeaderBar by key or scope
     * @param value
     * @param type
     */
    const detachActionButton = (value: string, type: 'key' | 'scope') => {
      let buttons: IRcLayoutActionButton[] = [];

      if(type === 'key') {
        buttons = actionButtons.filter((button) => button.id !== value);
      } else {
        buttons = actionButtons.filter((button) => button.scope !== value);
      }

      setActionButtons(_old => buttons)
    }

    /**
     * Get current route info
     */
    const getCurrentRoute = (): IRcRoute | undefined => {
        let routes: IRcRoute[];
        let current: IRcRoute | undefined;

        if(routesCache)
          routes = routesCache;
        else
          routes = generateRoutesFromSidebarCommands(props.sidebarCommands);

        routes.every((route) => {
          const tmpMatch = matchPath(route.path,location.pathname);
          /*const tmpMatch = matchPath(location.pathname, {
            path: route.path,
            exact: true,
            strict: true,
            sensitive: false
          });*/

          if(tmpMatch) {
            current = route;
            return false;
          }
          return true;
        })
        return current;
      }

    return (
        <RcLayoutContext.Provider
          value={{
            isNavCollapsed,
            toggleNavState,
            actionButtons,
            attachActionButton,
            detachActionButton,
            getCurrentRoute,
          }}>
            {props.children}
        </RcLayoutContext.Provider>
    );
};

export default RcLayoutProvider;
