import React, {useEffect, useState} from "react";
import {Text, IconButton, Stack, CommandBarButton, Dropdown, IDropdownOption} from "@fluentui/react";

export interface IRcDataGridPaginationProps {
  totalItems: number,
  limit: number,
  onPaginationChanged: (limit: number, page: number) => void;
}

export const RcDataGridPagination = (props: IRcDataGridPaginationProps): JSX.Element => {

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [itemsPerPageItems] = useState<IDropdownOption[]>([{key: 5, text: '5'},{key: 10, text: '10'},{key: 15, text: '15'},{key: 20, text: '20'},{key: 50, text: '50'},{key: 100, text: '100'}]);
  const [itemsPerPage, setItemsPerPage] = useState<number>(props.limit);

  const [firstLoading, setFirstLoading] = useState<boolean>(true);

  useEffect(() => {
    const pages = Math.ceil(props.totalItems / itemsPerPage);
    setTotalPages(pages);
    if(props.totalItems !== 0) {
      setCurrentPage(1)
    }
  }, [props.totalItems])

  useEffect(() => {
    const totalPages = Math.ceil(props.totalItems / itemsPerPage);
    setTotalPages(totalPages);

    if(totalPages < currentPage)
      setCurrentPage(totalPages);

  }, [itemsPerPage])

  useEffect(() => {
    if(!firstLoading)
      props.onPaginationChanged(itemsPerPage, currentPage);
    else
      setFirstLoading(false);
  }, [currentPage, itemsPerPage])

  const _generatePagesButtons = (current: number, total: number) => {
    const center = [current - 2, current - 1, current, current + 1, current + 2],
      filteredCenter = center.filter((p) => p > 1 && p < total),
      includeThreeLeft = current === 5,
      includeThreeRight = current === total - 4,
      includeLeftDots = current > 5,
      includeRightDots = current < total - 4;

    if (includeThreeLeft) filteredCenter.unshift(2)
    if (includeThreeRight) filteredCenter.push(total - 1)

    if (includeLeftDots) filteredCenter.unshift(-1);
    if (includeRightDots) filteredCenter.push(-1);

    if(total === 1)
      return [...filteredCenter, total];
    else
      return [1, ...filteredCenter, total]
  }

  return(
    <div>
      {totalPages > 0 ?
        <Stack horizontal tokens={{childrenGap: 10}} verticalAlign={"center"}>
          <Stack.Item>
            <IconButton iconProps={{iconName: 'ChevronLeft'}} onClick={() => setCurrentPage(currentPage - 1)} disabled={currentPage === 1} title="Previous page" ariaLabel="Previous page" />
          </Stack.Item>
          <Stack.Item>
            {
              _generatePagesButtons(currentPage, totalPages).map((a, index) => {
                if(a === -1) {
                  return <CommandBarButton key={'RcDataGrid_pagination_page_' + index} styles={{root: {height: 32}}} text={'...'} disabled={true}/>
                } else {
                  return <CommandBarButton key={'RcDataGrid_pagination_page_' + index} checked={currentPage === a} styles={{root: {height: 32}}} onClick={() => setCurrentPage(a)} text={a.toString()} title={'Page ' + a} ariaLabel={'Page ' + a} disabled={false}/>
                }
              })
            }
          </Stack.Item>
          <Stack.Item>
            <IconButton iconProps={{iconName: 'ChevronRight'}} onClick={() => setCurrentPage(currentPage + 1)} title="Next page" ariaLabel="Next page" disabled={currentPage >= totalPages} />
          </Stack.Item>
          <Stack.Item>
            <Dropdown
              selectedKey={itemsPerPage}
              options={itemsPerPageItems}
              onChange={(_ev, option) => setItemsPerPage(parseInt(option?.key ? option.key.toString() : '1'))}
            />
          </Stack.Item>
          <Stack.Item>
            <Text>({props.totalItems} risultati)</Text>
          </Stack.Item>
        </Stack> :
        <Text>(0 risultati)</Text>
      }
    </div>
  )
}
