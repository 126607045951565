import React, {useEffect, useState} from "react";
import {
  DefaultButton,
  Dropdown,
  IComboBoxOption,
  IDropdownOption,
  Position,
  PrimaryButton,
  SpinButton,
  Stack,
} from "@fluentui/react";
import {IRcDataGridFilterBaseProps} from "./RcDataGridFilterSelect";
import {NumericFieldFilter} from "../../models/numericFieldFilter";

export interface IRcDataGridFilterNumericProps extends IRcDataGridFilterBaseProps<NumericFieldFilter> {

}

const conditionOptions: IComboBoxOption[] = [
  {key: '=', text: 'Uguale'},
  {key: '<>', text: 'Diverso'},
  {key: '<', text: 'Minore'},
  {key: '>', text: 'Maggiore'},
  {key: '<=', text: 'Minore uguale a'},
  {key: '=>', text: 'Maggiore uguale a'},
  {key: 'between', text: 'Da: A:'}
];

export const RcDataGridFilterNumeric = (props: IRcDataGridFilterNumericProps): JSX.Element => {

  const [currentFilter, setCurrentFilter] = useState<NumericFieldFilter | undefined>(undefined);
  const [numberValue, setNumberValue] = useState<string>('');
  const [numberValue2, setNumberValue2] = useState<string>('');
  const [condition, setCondition] = useState<string>('');
  const [firstMountDone, setFirstMountDone] = useState<boolean>(false);

  useEffect(() => {
    if(firstMountDone) {
      if (currentFilter)
        props.onFilterChanged(props.propertyKey, currentFilter);
      else {
        props.onFilterChanged(props.propertyKey, undefined);
      }
    }
  }, [currentFilter, firstMountDone])

  useEffect(() => {
    if(props.initialValue){
      setCondition(props.initialValue.condition);
      setNumberValue(props.initialValue.value.toString());
      setNumberValue2(props.initialValue.value2? props.initialValue.value2.toString() : '');
      setCurrentFilter(props.initialValue);
    }
  }, [])

  const onFilterSubmitHandler = () => {

    const filter = new NumericFieldFilter(props.propertyKey, props.propertyDisplayName, condition, numberValue, numberValue2);

    setCurrentFilter(filter);

    if(!firstMountDone)
      setFirstMountDone(true);
  }

  const onResetFilterHandler = () => {
    setNumberValue('');
    setNumberValue2('');
    setCondition('');
    setCurrentFilter(undefined);

    if(!firstMountDone)
      setFirstMountDone(true);
  }

  const onConditionChanged = (_event: React.FormEvent<HTMLDivElement>, option?: (IDropdownOption<any> | undefined), _index?: (number | undefined)) => {
    if(option)
      setCondition(option.key.toString())
  }

  const onNumberValueChanged = (_event: React.SyntheticEvent<HTMLElement, Event>, newValue?: (string | undefined)) => {
    setNumberValue(newValue || '')
  }

  const onNumberValue2Changed = (_event: React.SyntheticEvent<HTMLElement, Event>, newValue?: (string | undefined)) => {
    setNumberValue2(newValue || '')
  }

  return (
    <Stack tokens={{childrenGap: 10}}>
      <Dropdown label={'Condizione'} placeholder={'Seleziona una condizione...'} required options={conditionOptions} onChange={onConditionChanged} selectedKey={condition} />

      <SpinButton labelPosition={Position.top} label={condition === "between" ? "Da" : "Valore"} placeholder={''} value={numberValue} onChange={onNumberValueChanged}/>

      {condition === "between" &&

        <SpinButton labelPosition={Position.top} label={"A"} placeholder={''} value={numberValue2} onChange={onNumberValue2Changed}/>
      }

      <PrimaryButton default={true} onClick={onFilterSubmitHandler} disabled={!condition || !numberValue || (condition === "between" && !numberValue2) }  >Applica</PrimaryButton>
      {currentFilter !== undefined &&
        <DefaultButton onClick={onResetFilterHandler}>Reset</DefaultButton>
      }
    </Stack>
  )
}
