import React from "react";
import {
  CommandBar,
  ICommandBarItemProps,
  Separator,
  Stack
} from "@fluentui/react";
import { Text } from '@fluentui/react/lib/Text';


export interface IRcPageToolbarProps {
  title: string | undefined;
  subtitle?: string | undefined;
  items: IRcCommandBarButtonProps[];
  overflowItems: IRcCommandBarButtonProps[];
  farItems: IRcCommandBarButtonProps[];

}

export interface IRcCommandBarButtonProps extends ICommandBarItemProps {
}

export const RcPageToolbar = (props : IRcPageToolbarProps): JSX.Element => {

  return (
    <Stack>
      <Text variant={'xxLarge'} style={{marginTop: 8, marginBottom: 8}} key={props.title}>{props.title}</Text>
      {props.subtitle &&
        <Text variant={'medium'} style={{marginTop: 8, marginBottom: 8}} key={props.subtitle}>{props.subtitle}</Text>
      }
      <Separator/>
      <div>
        <CommandBar
          styles={{root: {padding: 0}}}
          items={props.items}
          overflowItems={props.overflowItems}
          farItems={props.farItems}
        />
      </div>
      <Separator/>
    </Stack>
  )
}
