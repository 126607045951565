import React from "react";
import {CommandBar, Stack} from "@fluentui/react";
import {RcPage} from "../RcPage/RcPage";
import {IRcCommandBarButtonProps} from "../RcPageToolbar";

export interface IRcPageWithToolbarProps {
  title: string | undefined;
  subtitle?: string | undefined;
  items: IRcCommandBarButtonProps[];
  overflowItems: IRcCommandBarButtonProps[];
  farItems: IRcCommandBarButtonProps[];
  children: JSX.Element,
  maxWidth?: number | undefined;
}

export const RcPageWithToolbar = (props: IRcPageWithToolbarProps): JSX.Element => {

  return (
    <Stack>
      <CommandBar
        styles={{root: {padding: '0px 17px', borderBottom: '1px solid rgb(243, 242, 241)'}}}
        items={props.items}
        overflowItems={props.overflowItems}
        farItems={props.farItems}
      />
      <RcPage {...props}>
        {props.children}
      </RcPage>
    </Stack>
  )
}
