import {Link, Separator, Stack, Text} from "@fluentui/react";
import React, {useContext, useState} from "react";
import {Icon} from "@fluentui/react/lib/Icon";
import {FilterComponentFactory} from "./filterComponentFactory";
import {RcDataGridContext} from "../../providers/RcDataGridProvider";
import {IRcDataGridColumn} from "../../RcDataGrid";

export interface IRcDataGridFilterPanelProps {
  title: string,
  properties:  IRcDataGridColumn[] | undefined;
  onDismiss?: () => void,
}

export const RcDataGridFilterPanel = (props: IRcDataGridFilterPanelProps): JSX.Element => {

  const [propertySelected, setPropertySelected] = useState<IRcDataGridColumn | undefined>(undefined);
  const [filterSelected, setFilterSelected] = useState<IRcDataGridColumn | undefined>(undefined);
  const rcDataGridContext = useContext(RcDataGridContext);

  const onFilterSelected = (column: IRcDataGridColumn | undefined) => {
    setFilterSelected(column)
    setPropertySelected(column);
  }

  const onFilterGoBack = () => {
    setFilterSelected(undefined);
    setPropertySelected(undefined);
  }

  const onFilterChanged = (propertyName: string, data: any) => {
    rcDataGridContext?.addOrUpdateFilter(propertyName, data);
  }


  return(
    <div>
      <Stack horizontal>
        <Stack.Item grow>
          <Text block variant="large">
            <Icon iconName={'Filter'} styles={{root: {marginRight: 10, fontSize: 14}}} />
            {!propertySelected ? props.title : propertySelected.name}
          </Text>
        </Stack.Item>
        {filterSelected ? (
          <Stack.Item>
            <Text block variant="large">
              <Icon style={{cursor: 'pointer'}} onClick={onFilterGoBack} iconName={'ReturnKey'} styles={{root: {marginRight: 10, marginTop: 5, fontSize: 14}}} />
            </Text>
          </Stack.Item>
        ) : (
          <Text block variant="large" onClick={props.onDismiss} style={{cursor: 'pointer'}}>
            <Icon iconName={'ChromeClose'} styles={{root: {marginRight: 10, fontSize: 12}}} />
          </Text>
        )
        }
      </Stack>

      <Separator />
      {(!filterSelected) &&
        <Stack tokens={{childrenGap: 10}}>
          {
            props?.properties?.filter(a => a.filterType !== undefined).map((prop) => {
              console.log(prop)
              return (
                <Link key={'rcDataGrid_property_filter_' + prop.key} onClick={() => onFilterSelected(prop)}>
                  <Icon iconName={'MultiSelect'} styles={{root: {fontSize: 12, marginRight: 10}}}/>
                  {prop.name}
                </Link>
              )
            })
          }
        </Stack>
      }
      {(propertySelected && propertySelected.filterType) &&
        FilterComponentFactory.getFilter(propertySelected, (propertyName, data) =>  onFilterChanged(propertyName, data), undefined)
      }
    </div>
  )
}
