import React, {useEffect, useState} from "react";
import AuthIdentityService from "../services/auth/auth-identity.service";
import {AuthInterface} from "../services/auth/auth.interface";
import AuthRocketService from "../services/auth/auth-rocket.service";
import {AuthNoneService} from "../services/auth/auth-none.service";
import {RcAppAuthTypes} from "../components/RcReactApp/RcReactApp";
import {AuthNotDefinedService} from "../services/auth/auth-not-defined.service";

export const RcAuthContext = React.createContext<AuthInterface>(new AuthNotDefinedService());

export const AuthConsumer = RcAuthContext.Consumer;

export interface RcAuthProviderProps {
  authType: RcAppAuthTypes;
  children: any;
  routerBasename?: string | undefined;
}

export function RcAuthProvider(props: RcAuthProviderProps) {
  const [authService, setAuthService] = useState<AuthInterface>(new AuthNotDefinedService());

  useEffect(() => {
    switch (props.authType) {
      case RcAppAuthTypes.identity:
        setAuthService(new AuthIdentityService());
        break;
      case RcAppAuthTypes.none:
        setAuthService(new AuthNoneService());
        break;
      case RcAppAuthTypes.rocket:
        setAuthService(new AuthRocketService(props.routerBasename));
        break;
    }
  }, []);

  return (
    <RcAuthContext.Provider value={authService}>{props.children}</RcAuthContext.Provider>
  )
}
