import {IFieldFilter} from "./IFieldFilter";

export class NumericFieldFilter implements IFieldFilter{
  propertyName: string;
  propertyDisplayName: string;
  condition: string;
  context: 'filter' | 'sort' | 'group' | 'paging';
  value: any;
  value2?: any;
  filterType: 'string' | 'comparison' | 'array';
  propertyType: "string" | "number" | "date";

  constructor(propertyName: string, propertyDisplayName: string, condition: string, value: string, value2?: string) {
    this.propertyName = propertyName;
    this.propertyDisplayName = propertyDisplayName;
    this.condition = condition;
    this.value = value;
    this.value2 = value2;
    this.filterType = 'comparison';
    this.propertyType = 'number';
    this.context = 'filter';
  }

  displayValue(): string {
    return this.propertyDisplayName + " " + this.condition + " " + this.value;
  }
}
