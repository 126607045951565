import React, {SyntheticEvent, useEffect, useState} from "react";
import {SpinButton} from '@fluentui/react';
import {Text} from "@fluentui/react/lib/Text";
import {IRcFormFieldProps, RcFormBaseField} from "./RcFormBaseField";

export interface IRcFormNumericFieldProps extends IRcFormFieldProps {
  min: number | undefined,
  max: number | undefined,
}

export const RcFormNumericField = (props: IRcFormNumericFieldProps): JSX.Element => {

  const [value, setValue] = useState<string | undefined>('0');

  useEffect(() => {
    if(props.initialValue !== undefined) {
      setValue(props.initialValue);
    }
  }, [])

  const onChangeValue = React.useCallback(
    (_event: SyntheticEvent<HTMLElement, Event>, _newValue?: string | undefined) => {
      setValue(_newValue);
    },
    [],
  );

  const EditComponent = <SpinButton id={props.id} key={props.id} min={props.min} max={props.max} value={value} placeholder={props.placeholder} onChange={onChangeValue}/>;
  const ViewComponent = <Text>{value}</Text>

  if((props.initialValue !== undefined && value !== undefined) || (props.initialValue === undefined)) {
    return(
      <RcFormBaseField fieldProps={props} value={value} editModeComponent={EditComponent} viewModeComponent={ViewComponent} />
    )
  }

  return(
    <div></div>
  )
}



