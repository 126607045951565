import {ProgressIndicator} from "@fluentui/react";
import React from "react";

export function RcPageLoaderComponent() {
    return(
        <div>
            <ProgressIndicator styles={{
                root: {
                    margin: 0,
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    msTransform: 'translate(-50%, -50%)',
                    transform: 'translate(-50%, -50%)',
                    minWidth: 400,
                    textAlign: 'center'
                }
            }} label="Attendi per favore..." />
        </div>
    )
}
