import {Stack} from "@fluentui/react";
import React, {useContext} from "react";
import {RcHeaderBar} from "../RcHeaderBar";
import {RcSidebar} from "../RcSidebar";
import {RcMainContent} from "../RcMainContent/RcMainContent";
import {
  generateIndexableCommand,
  generateSidebarItemsFromSidebarCommands
} from "../../utilities/routing";
import {RcLayoutContext} from "../../providers/RcLayoutProvider";
import {IRcSearchBarCustomSearchResult} from "../RcHeaderBar/components/RcSearchBar";
import {RcApplicationMenu} from "../../common/RcApplicationMenu";

export interface RcLayoutContainerProps {
    sidebarCommands: RcApplicationMenu[];
    children: any;
    searchbarCustomSearches?: ((q: string) => Promise<IRcSearchBarCustomSearchResult>)[] | undefined;
}

export const RcLayoutContainer = (props: RcLayoutContainerProps): JSX.Element => {

  const layoutContext = useContext(RcLayoutContext);

  const styles: any = {
        root: {
            top: 48,
            width: "auto",
            height: "auto",
            position: "absolute",
            overflow: "hidden auto",
            zIndex: 0,
            transition: "left 0.5s ease 0s",
        }
    }

    const sidebarItemsFromSidebarCommand = generateSidebarItemsFromSidebarCommands(props.sidebarCommands);
    const indexableCommands = generateIndexableCommand(props.sidebarCommands);

    return (
      <Stack>
          <RcHeaderBar searchbarCustomSearches={props.searchbarCustomSearches} indexableCommands={indexableCommands} />
          <Stack horizontal className={styles.root}>
              <Stack.Item>
                  <RcSidebar key="rc-layout-sidebar" items={sidebarItemsFromSidebarCommand} isNavCollapsed={layoutContext.isNavCollapsed} />
              </Stack.Item>
              <Stack.Item>
                  <RcMainContent>
                    {props.children}
                  </RcMainContent>
              </Stack.Item>
          </Stack>
      </Stack>
    )
}
