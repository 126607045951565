export abstract class RcAbstractService {

  protected constructor() {}

  calculateUrl(urlTemplate: string, pathVariables: {} | null | undefined, queryString: string | null | undefined): string {

    let calculatedUrl = urlTemplate;

    for (let key in pathVariables) {
      let value = pathVariables[key];
      calculatedUrl.replace(`{${key}}`, value);
    }

    if(queryString && !urlTemplate.includes('?'))
      calculatedUrl += '?' + queryString;

    if(queryString && urlTemplate.includes('?') && !queryString.endsWith('?'))
      calculatedUrl += '&' + queryString;

    return calculatedUrl;
  }

  abstract rcFind(pathVariables: {}, queryString: string): Promise<any>;
}
