import {useParams, useLocation, useNavigate} from "react-router-dom";
//import {Omit, RouteComponentProps, WithRouterProps, WithRouterStatics} from "react-router";

export function useRcRouteParams(): any {
  return useParams();
}

export function useRcRouteQuery() {
  return new URLSearchParams(useLocation().search);
}

export function useRcRouteHistory() {
  return useNavigate();
}

/*
export function withRcRouter<P extends RouteComponentProps<any>, C extends React.ComponentType<P>>(
  component: C & React.ComponentType<P>,
): React.ComponentClass<Omit<P, keyof RouteComponentProps<any>> & WithRouterProps<C>> & WithRouterStatics<C> {
  return withRouter(component)
}
*/
