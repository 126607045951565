import {IFieldOperationStrategy} from "./IFieldOperationStrategy";
import {IFieldOperand} from "../models/IFieldOperand";

export class ArrayOperationStrategy implements IFieldOperationStrategy {

  _item: IFieldOperand;

  constructor(item: IFieldOperand) {
    this._item = item;
  }

  stringify(): string {

    switch (this._item.condition) {
      case "in":
        return '(' + this._generateInClause() + ')'
      default:
        return "";
    }
  }

  _generateInClause(): string {

    let clause: string = '';
    let counter = 0;

    this._item.value.forEach((item: {key: string, value: any}) => {

      if(counter > 0)
        clause += " || ";

      clause += 'obj.' + this._item.propertyName + ' == ' + this._escapeValueByType(this._item.propertyType, item.value);
      counter++;
    })

    return clause;
  }

  _escapeValueByType(type: 'string' | 'number' | 'date', value: any): string {
    if(type === 'string' || type === 'date')
      return "\"" + value + "\"";

    return value;
  }

  add(_operation: IFieldOperationStrategy): void {
    throw new Error("Not implemented");
  }
}
