import React from "react";
import {ContextualMenu, Dialog, DialogType} from "@fluentui/react";

export interface IRcDialogProps {
  isOpen: boolean,
  children: any,
  title: string,
  subTitle: string,
  minWidth?: number | string | undefined,
  enableDrag?: boolean,
  eventBubblingEnabled?: boolean,
}

export function RcDialog(props: IRcDialogProps) {
    return(
        <Dialog
            isOpen={props.isOpen}
            isBlocking={true}
            minWidth={props.minWidth}
            modalProps={props.enableDrag ?
              {dragOptions: {
                    moveMenuItemText: 'Move',
                    closeMenuItemText: 'Close',
                    menu: ContextualMenu,
                    keepInBounds: true,
                },
                layerProps: {eventBubblingEnabled: props.eventBubblingEnabled}
              } :
              {
                layerProps:
                  {eventBubblingEnabled: props.eventBubblingEnabled}
              }
            }
            dialogContentProps={{
                type: DialogType.largeHeader,
                title: props.title,
                subText: props.subTitle
            }}>
            {props.children}
        </Dialog>
    )
}

RcDialog.defaultProps = {
  minWidth: 500,
  enableDrag: true,
  eventBubblingEnabled: true,
}
