import {IBreadcrumbItem, Stack} from "@fluentui/react";
import React, {useContext, useEffect} from "react";
import {RcLayoutContext} from "../../providers/RcLayoutProvider";
export const RcMainContent = ({children} : any): JSX.Element => {

    const layoutContext = useContext(RcLayoutContext);
    // const themeContext = useContext(RcThemeContext);
    // const [breadcrumb, setBreadcrumb] = useState<IBreadcrumbItem[]>([]);

    const styles : any = {
      root: {
        display: "flex",
        flexFlow: "column nowrap",
        width: "calc(100% - " + (layoutContext.isNavCollapsed ? '48' : 228) + "px)",
        boxSizing: "border-box",
        marginLeft: layoutContext.isNavCollapsed ? 48 : 228,
        position: "fixed",
        overflow: "scroll",
        height: "calc(100vh - 0px)",
        /*padding: "0px 25px",*/
      },
      breadcrumb: {
        display: "flex",
        flexFlow: "column nowrap",
        width: "calc(100% - " + (layoutContext.isNavCollapsed ? '48' : 228) + "px)",
        boxSizing: "border-box",
        marginLeft: layoutContext.isNavCollapsed ? 48 : 228,
        position: "fixed",
        overflow: "scroll",
        height: 32,
        padding: "0px 25px",
      },
      footer: {
        display: "flex",
        flexFlow: "column nowrap",
        width: "calc(100% - " + (layoutContext.isNavCollapsed ? '48' : 228) + "px)",
        boxSizing: "border-box",
        marginLeft: layoutContext.isNavCollapsed ? 48 : 228,
        position: "fixed",
        bottom: 0,
        height: 32,
        background: '#f4f4f4',
        paddingLeft: 25,
        paddingRight: 25,
      }
    }

    useEffect(() => {
      _handleBreadcrumb();
    }, [])

    /*useNavigate().listen((_location, _action) => {
      _handleBreadcrumb();
    })*/


    /* const onRenderBreadcrumbItem = (item: IBreadcrumbItem): JSX.Element => {
      return <a href={item.href} style={{marginRight: 5, marginLeft: 5}}>{item.text}</a>;
    } */

    const _handleBreadcrumb = () => {
      let breadcrumbItems: IBreadcrumbItem[] = [];
      const currentRoute = layoutContext.getCurrentRoute();
      const breadcrumbArray = currentRoute?.breadcrumb?.split('>');

      breadcrumbArray?.forEach((breadcrumb) => {
        let breadcrumbItem = {} as IBreadcrumbItem;
        breadcrumbItem.key = breadcrumb;
        breadcrumbItem.text = breadcrumb;
        breadcrumbItems.push(breadcrumbItem);
      })
      // setBreadcrumb(breadcrumbItems);
    }

    return (
      <Stack>
        <Stack className={styles.root}>
          {/* <Breadcrumb onRenderItem={onRenderBreadcrumbItem} style={{height: 32}} items={breadcrumb}/> */}
          {children}
        </Stack>
        {/*<Stack className={styles.footer}><p style={{textAlign: 'right', marginTop: 8}}>Staff Network - v. 1.0.3</p></Stack>*/}
      </Stack>
    )
}
