import { IDENTITY_CONFIG, METADATA_OIDC } from "../../utilities/authConst";
import {UserManager, WebStorageStateStore, Log} from "oidc-client";
import {AuthInterface} from "./auth.interface";

export default class AuthIdentityService implements AuthInterface{
  UserManager: UserManager;
  type: "notDefined" | "none" | "rocket" | "identity" = 'identity';

  constructor() {
    this.UserManager = new UserManager({
      ...IDENTITY_CONFIG,
      userStore: new WebStorageStateStore({ store: window.localStorage }),
      metadata: {
        ...METADATA_OIDC
      }
    });

    // Logger
    Log.logger = console;
    Log.level = Log.DEBUG;
    this.UserManager.events.addUserLoaded((_user) => {
      if (window.location.href.indexOf("signin-oidc") !== -1) {
        this.navigateToScreen();
      }
    });
    this.UserManager.events.addSilentRenewError((e) => {
      console.log("silent renew error", e.message);
    });

    /**
     * Event raised whem user logout from IdentityServer.
     * The user is not logged in and we need to logout from application
     */
    this.UserManager.events.addUserSignedOut(() => {
      this.logout();
    })

    this.UserManager.events.addAccessTokenExpired(() => {
      console.log("token expired");
      this.signinSilent();
    });
  }

  signinRedirectCallback = () => {
    this.UserManager.signinRedirectCallback().then(() => {
      console.log("this.navigateToScreen()");
    });
  };

  getUser = async () => {
    const user = await this.UserManager.getUser();
    if (!user) {
      return await this.UserManager.signinRedirectCallback();
    }
    return user;
  };

  parseJwt = (token: string) => {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace("-", "+").replace("_", "/");
    return JSON.parse(window.atob(base64));
  };


  signinRedirect = () => {
    localStorage.setItem("redirectUri", window.location.pathname);
    this.UserManager.getUser().then((user) => {
      if(!user)
        this.UserManager.signinRedirect({});
    })
  };


  navigateToScreen = () => {
    window.location.href = process.env.REACT_APP_PUBLIC_URL ? process.env.REACT_APP_PUBLIC_URL : '';
  };


  isAuthenticated = () => {

    const parsed = localStorage.getItem(`oidc.user:${process.env.REACT_APP_AUTH_URL}:${process.env.REACT_APP_IDENTITY_CLIENT_ID}`);
    const oidcStorage = parsed ? JSON.parse(parsed) : null

    return (!!oidcStorage && !!oidcStorage.access_token)
  };

  signinSilent = () => {
    this.UserManager.signinSilent()
      .then((user) => {
        console.log("signed in", user);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  signinSilentCallback = () => {
    this.UserManager.signinSilentCallback();
  };

  createSigninRequest = () => {
    return this.UserManager.createSigninRequest();
  };

  logout = () => {
    this.UserManager.signoutRedirect({
      id_token_hint: localStorage.getItem("id_token")
    });
    this.UserManager.clearStaleState();
  };

  signoutRedirectCallback = () => {
    this.UserManager.signoutRedirectCallback().then(() => {
      localStorage.clear();
      window.location.replace(process.env.REACT_APP_PUBLIC_URL ? process.env.REACT_APP_PUBLIC_URL : "/");
    });
    this.UserManager.clearStaleState();
  };

  // @ts-ignore
  login = (username: string, password: string) => {
    return Promise.resolve();
  }
}
