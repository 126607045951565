import {Component} from "react";

export class RcApplicationMenu {

  /**
   * Menu identifier
   */
  id: string;

  /**
   * Menu icon (used only if menu item is not Header type)
   */
  icon?: string;

  /**
   * Menu title
   */
  title: string;

  /**
   * Specifies whether the command is indexable (for full-text search, used only if menu item is not Header type)
   */
  hasIndex?: boolean = true;

  /**
   * Make item visible in menu (used only if menu item is not Header type, header are always visible).
   */
  visible?: boolean | ((user: any) => boolean) = true;

  /**
   * Enable route if true
   */
  enableRoute?: boolean | ((user: any) => boolean) = true;

  /**
   * If specified, the command acts like a router node
   */
  routerLink: string;

  /**
   * List of command children
   */
  children?: RcApplicationMenu[] = [];

  /**
   * Define if the item is a Header in sidebar
   */
  isHeader?: boolean = false;

  /**
   * React component attach to command (for routing purpose)
   */
  component?: any;

  /**
   * Set specific layout for route
   */
  layout?: 'default' | 'blank' | Component = 'default';

  /**
   * Props to pass to layout component if defined
   */
  layoutProps?: any;

  /**
   * Define if route is public. Private by default
   */
  isPublic: boolean;
}
