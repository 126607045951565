import {
  FontIcon,
  mergeStyleSets,
  Stack, useTheme
} from "@fluentui/react";
import { Text } from '@fluentui/react/lib/Text';
import React, {useEffect, useState} from "react";
import {matchPath, useNavigate, useLocation} from "react-router-dom";

export interface IRcSidebarItem {
  id: string,
  title: string,
  icon: string | undefined,
  children?: IRcSidebarItem[],
  routerLink: string | undefined,
  isMinimal: boolean,
  isChildren: boolean,
  onMouseOver?: (item: IRcSidebarItem) => void,
  onMouseLeave?: (item: IRcSidebarItem) => void,
  isFloat?: boolean,
  isHeader: boolean,
  visible?: boolean | ((user: any) => boolean),
}

export const RcSidebarItem = (props: IRcSidebarItem): JSX.Element => {
  const [showChildren, setShowChildren] = useState(false);
  const [isActive, setIsActive] = useState(false);

  const currentTheme = useTheme();
  const location = useLocation();
  const history = useNavigate();

  const styles = mergeStyleSets({
      root: {
          height: 40,
          width: "100%",
          maxWidth: 228,
          ':hover': {
              background: "#f3f2f1",
          },
          cursor: "pointer",
      },
      stackItemIcon: {
          alignItems: 'center',
          display: 'flex',
          flex: (props.isFloat) ? "0 0 20px" : "0 0 48px",
          justifyContent: 'center',
          fontSize: 16,
          zIndex: 100,
          selectors: {
            '&:before': {
              content: "''",
              position: "absolute",
              left: (props.isChildren && !props.isFloat) ? 34 : (props.isFloat) ? 10 : 4,
              width: 4,
              height: 16,
              backgroundColor: isActive ? currentTheme.palette.themePrimary : 'transparent',
              opacity: 1,
              transition: "all .5s ease",
              WebkitTransition: "all .5s ease",
              MozTransition: "all .5s ease"
            }
          }
      },
      stackItemIconChevron: {
        width: 40,
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        fontSize: 12,
        zIndex: 100,
      },
      stackItemText: {
          alignItems: 'center',
          display: 'flex',
          flex: "1 1 auto",
          justifyContent: 'start',
          fontSize: 14,
      },
      itemText: {
        fontSize: 14,
      }
  });

  useEffect(() => {

    // if current routerLink is / and actual location is different from /
    // we set isActive = false for avoid the activation of the / router link
    // only if the router link and location are / the current sidebar menu item can be active
    if(props.routerLink === '/' && location.pathname !== '/') {
      setIsActive(false);
      return;
    }

    // Check if current pathname match (partially or totally) with the
    // current router link
    const tmpMatch = matchPath(props.routerLink!,location.pathname);

    /*const tmpMatch = matchPath(location.pathname, {
      path: props.routerLink,
      exact: false,
      strict: true,
      sensitive: false
    });*/

    // If the path match, active the current sidebar menu item only if
    // the children are closed or the sidebar is in minimal mode
    if(tmpMatch && (!showChildren || props.isMinimal)) {
      setIsActive(true);
    } else {
      setIsActive(false);
    }
  })


  /**
   * Handle the click on sidebar menu item.
   * If the Sidebar menu Item is a group, show children, if not, navigate to route
   * @param item
   */
  function _handleItemClick(item: IRcSidebarItem) {
      if(item.children && item.children.filter(a => a.visible).length > 0) {
          setShowChildren(!showChildren)
          return;
      }

      if(item.routerLink?.includes('http://') || item.routerLink?.includes('https://')) {
        window.location.href = item.routerLink;
        return;
      }

      if(item.routerLink)
          history(item.routerLink);
  }

  const onMouseOverHandler = () => {
    if(props.onMouseOver)
      props.onMouseOver(props);
  }

  const onMouseLeaveHandler = () => {
    if(props.onMouseLeave)
      props.onMouseLeave(props);
  }

  return (
    <div>
      <div className={'rc-sidebar-item'} onMouseLeave={onMouseLeaveHandler} onMouseOver={onMouseOverHandler}>
        <Stack horizontal className={styles.root} onClick={() => _handleItemClick(props)}>
          <Stack.Item className={styles.stackItemIcon}>
            {!props.isChildren &&
              <FontIcon className={'rc-sidebar-item-icon'} aria-label={props.icon} iconName={props.icon} />
            }
          </Stack.Item>


          {!props.isMinimal &&
            <Stack.Item grow className={styles.stackItemText}>
              <Text className={'rc-sidebar-item-text'} styles={{root: styles.itemText}}>{props.title}</Text>
            </Stack.Item>
          }
          {(!props.isMinimal && props.children && props.children.filter(a => a.visible).length > 0) &&
            <Stack.Item className={styles.stackItemIconChevron}>
              <FontIcon className={'rc-sidebar-item-icon-chevron'} aria-label="Chevron" iconName="ChevronDown" />
            </Stack.Item>
          }
        </Stack>
        <div style={{display: showChildren && !props.isMinimal ? 'block' : 'none'}}>
          <Stack>
            {props?.children?.filter(a => a.visible).map((child) => {
              child.isChildren = true;
              return <RcSidebarItem key={child.id} {...child} id={child.id} />
            })}
          </Stack>
        </div>
      </div>
    </div>
  )
}
