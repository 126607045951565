import {AuthInterface} from "./auth.interface";

export class AuthNoneService implements AuthInterface{

  type: "notDefined" | "none" | "rocket" | "identity" = 'none';

  getUser(): Promise<any> {
    return Promise.resolve({});
  }

  isAuthenticated(): boolean {
    return true;
  }

  signinRedirect() {
  }

  signinRedirectCallback() {
  }

  signinSilentCallback() {
  }

  logout() {
  }

  signoutRedirectCallback() {
  }

  // @ts-ignore
  login(username: string, password: string): Promise<any> {
    return Promise.resolve();
  }

}
