import React, {useEffect, useState} from "react";
import {Dropdown, IDropdownOption, Text} from '@fluentui/react';
import {IRcFormFieldProps, RcFormBaseField} from "./RcFormBaseField";


export interface IRcFormDropdownFieldProps extends IRcFormFieldProps {
  multiselect?: boolean,
  options?: IRcFormDropdownFieldLocalOptionsProps[],
  remoteOptions?: IRcFormDropdownFieldRemoteOptionsProps,
}

export interface IRcFormDropdownFieldLocalOptionsProps extends IDropdownOption<any> {

}

export interface IRcFormDropdownFieldRemoteOptionsProps {
  promise: Promise<any>,
  key: string,
  text: string,
  path?: string,
}

export const RcFormDropdownField = (props: IRcFormDropdownFieldProps): JSX.Element => {

  const [value, setValue] = useState<any | undefined>(undefined);
  const [options, setOptions] = useState<IDropdownOption[]>(props.options ? props.options : []);
  const [multiselect, setMultiselect] = useState<boolean>(false);
  const [dataLoaded, setDataLoaded] = useState<boolean>(false);

  useEffect(() => {

    if(props.multiselect === undefined || props.multiselect === false)
      setMultiselect(false);
    else
      setMultiselect(true);

    if(props.options === undefined) {
      setOptions([]);
    } else {
      setOptions(props.options);
      setDataLoaded(true);
    }
  }, [])

  useEffect(() => {
    if(props.initialValue !== undefined && dataLoaded && (options.length > 0)) {
      setValue(props.initialValue);
    }
  }, [dataLoaded])

  useEffect(() => {
    if(props.remoteOptions !== undefined) {
      props.remoteOptions.promise.then((result) => {
        const sKey = props.remoteOptions ? props.remoteOptions.key : '';
        const sText = props.remoteOptions ? props.remoteOptions.text : '';
        const sPath = props.remoteOptions ? props.remoteOptions.path : '';
        _getRemoteData(result, sKey, sText, sPath).then((data) => {
          setOptions(data);
          setDataLoaded(true);
        })
      })
    }
  }, [props.remoteOptions])

  const _getRemoteData = async (result: any, key: string, text: string, path: string | undefined = undefined): Promise<IRcFormDropdownFieldRemoteOptionsProps[]> => {
    if(result.constructor === Response) {

      // Workaround: Failed to execute 'json' on 'Response': body stream is locked
      const json = await result.clone().json();

      return _mapRemoteDatasetToOptions(json, key, text, path);
    } else {
      return _mapRemoteDatasetToOptions(result, key, text, path);
    }
  }

  const _mapRemoteDatasetToOptions = (result: any, key: string, text: string, path: string | undefined = undefined): IRcFormDropdownFieldRemoteOptionsProps[] => {
    if(!!path) {
      result = _resolve(path, result);
    }

    return result.map(function (_key: any, index: any) {
      const obj: IDropdownOption = {
        key: result[index][key],
        text: result[index][text],
      }
      return obj;
    });
  }

  const _resolve = (path: string, obj: any): any =>  {
    return path.split('.').reduce(function(prev, curr) {
      return prev ? prev[curr] : null
    }, obj || self)
  }

  const onChangeValue = (_event: React.FormEvent<HTMLDivElement>, _option?: IDropdownOption, _index?: number) => {
    if(props.multiselect) {
      if(_option?.selected) {
        let old = value === undefined ? [] : value;
        old = [...old, _option.key];
        setValue(old);
      } else {
        setValue(value.filter((key: string) => key !== _option?.key))
      }
    } else {
      setValue(_option?.key !== undefined ? _option.key : undefined);
    }
  }

  if(dataLoaded && (props.initialValue === undefined || (value !== undefined && value !== ''))) {
    return <RcFormBaseField fieldProps={props} value={value}
                            editModeComponent={
                                  <Dropdown id={props.id}
                                            multiSelect={multiselect}
                                            defaultSelectedKeys={props.multiselect ? value : undefined}
                                            defaultSelectedKey={!props.multiselect ? value : undefined}
                                            onChange={onChangeValue}
                                            key={props.id}
                                            placeholder={props.placeholder}
                                            options={options}/>
                                }
                            viewModeComponent={
                                  <Text>{props.initialValue}</Text>
                                }
          />
  } else {
    return <RcFormBaseField fieldProps={props} value={value}
                            editModeComponent={
                                  <Dropdown disabled options={[]}/>
                                }
                            viewModeComponent={
                                  <Text>{value}</Text>
                                }
    />
  }
}



