import React, {useEffect, useState} from "react";
import {v4 as uuidv4} from 'uuid';
import {useWindowSize} from "../../hooks/useWindowSize";

type Cols = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;

export interface IRcFieldGroupProps {
  children: any;
  colXxxl?: Cols;
  colXxl?: Cols;
  colXl?: Cols;
  colLg?: Cols;
  colMd?: Cols;
  colSm?: Cols;
  itemStyles?: React.CSSProperties;
}

export function RcFieldGroup(props: IRcFieldGroupProps){

  const [colXxxlNumber,setColXxxlNumber] = useState<Cols>(6);
  const [colXxlNumber,setColXxlNumber] = useState<Cols>(6);
  const [colXlNumber,setColXlNumber] = useState<Cols>(6);

  const [colLgNumber,setColLgNumber] = useState<Cols>(6);
  const [colMdNumber,setColMdNumber] = useState<Cols>(3);
  const [colSmNumber,setColSmNumber] = useState<Cols>(1);
  const [generatedChildren, setGeneratedChildren] = useState<JSX.Element[]>([]);
  const size = useWindowSize();

  const [fluentUiBreakpoint, setFluentUiBreakpoint] = useState<string>("");
  const [gridRowsNumber, setGridRowsNumber] = useState<number>(0);

  const [renderedChildren, setRenderedChildren] = useState<any[] | undefined>(undefined);

  useEffect(() => {

    if(size.width >= 320 && size.width <= 479) {
      if (fluentUiBreakpoint !== "SM")
        setFluentUiBreakpoint("SM")
    }
    else if(size.width >= 480 && size.width <= 639) {
      if (fluentUiBreakpoint !== "MD")
        setFluentUiBreakpoint("MD")
    }
    else if(size.width >= 640 && size.width <= 1023) {
      if (fluentUiBreakpoint !== "LG")
        setFluentUiBreakpoint("LG")
    }
    else if(size.width >= 1024 && size.width <= 1365) {
      if (fluentUiBreakpoint !== "XL")
        setFluentUiBreakpoint("XL")
    }
    else if(size.width >= 1366 && size.width <= 1919) {
      if (fluentUiBreakpoint !== "XXL")
        setFluentUiBreakpoint("XXL")
    }
    else if(size.width >= 1920) {
      if (fluentUiBreakpoint !== "XXXL")
        setFluentUiBreakpoint("XXXL")
    }

  }, [size])

  useEffect(() => {

    let tmpGridRowsNumber = 0;

    if(fluentUiBreakpoint === "SM")
      tmpGridRowsNumber = Math.ceil(generatedChildren.length / colSmNumber)

    if(fluentUiBreakpoint === "MD")
      tmpGridRowsNumber = Math.ceil(generatedChildren.length / colMdNumber)

    if(fluentUiBreakpoint === "LG")
      tmpGridRowsNumber = Math.ceil(generatedChildren.length / colLgNumber)

    if(fluentUiBreakpoint === "XL")
      tmpGridRowsNumber = Math.ceil(generatedChildren.length / colXlNumber)

    if(fluentUiBreakpoint === "XXL")
      tmpGridRowsNumber = Math.ceil(generatedChildren.length / colXxlNumber)

    if(fluentUiBreakpoint === "XXXL")
      tmpGridRowsNumber = Math.ceil(generatedChildren.length / colXxxlNumber)

    setGridRowsNumber(tmpGridRowsNumber)

  }, [fluentUiBreakpoint])

  useEffect(() => {

    if(props.children.map){
      setGeneratedChildren(props.children);
    } else {
      setGeneratedChildren([props.children]);
    }

  }, [props.children])


  useEffect(() => {
    if(gridRowsNumber !== 0)
      setRenderedChildren(generateRows());

  }, [gridRowsNumber])


  useEffect(() => {

    if(props.colXxxl)
      setColXxxlNumber(props.colXxxl);

    if(props.colXxl)
      setColXxlNumber(props.colXxl);

    if(props.colXl)
      setColXlNumber(props.colXl);

    if(props.colLg)
      setColLgNumber(props.colLg);

    if(props.colMd)
      setColMdNumber(props.colMd);

    if(props.colSm)
      setColSmNumber(props.colSm);

  }, [props.colLg, props.colMd, props.colSm, props.colXxxl, props.colXxl, props.colXl]);


  const getMsColFromColumns = (type: string, colNumbers: number): string => {
    switch (type) {
      case 'xxxl':
        return 'ms-xxxl' + (12 / colNumbers);
      case 'xxl':
        return 'ms-xxl' + (12 / colNumbers);
      case 'xl':
        return 'ms-xl' + (12 / colNumbers);
      case 'lg':
        return 'ms-lg' + (12 / colNumbers);
      case 'md':
        return 'ms-md' + (12 / colNumbers);
      case 'sm':
        return 'ms-sm' + (12 / colNumbers);
      default:
        return '';
    }
  }

  const generateRows = (): any[] => {

    let childCounter = {value: 0};
    let childPerRow = 0;

    switch (fluentUiBreakpoint) {
      case "SM":
        childPerRow = colSmNumber;
        break;
      case "MD":
        childPerRow = colMdNumber;
        break;
      case "LG":
        childPerRow = colLgNumber;
        break;
      case "XL":
        childPerRow = colXlNumber;
        break;
      case "XXL":
        childPerRow = colXxlNumber;
        break;
      case "XXXL":
        childPerRow = colXxxlNumber;
        break;
    }

    const rows: any[] = [];

    for(let i = 0; i < gridRowsNumber && childCounter.value < generatedChildren.length; i++) {
      const cols = generateCols(childPerRow, childCounter);
      rows.push(cols)
    }

    return rows;
  }

  const generateCols = (childPerRow: number, childCounter: {value: number}) => {
    const childrens: JSX.Element[] = []

    for(let i = 0; i < childPerRow; i++) {
      const a  = <div key={'field-group-' + i} style={{marginBottom: 32, ...props.itemStyles}}
                      className={`ms-Grid-col ` +
                      `${getMsColFromColumns('xxxl', colXxxlNumber)} ` +
                      `${getMsColFromColumns('xxl', colXxlNumber)} ` +
                      `${getMsColFromColumns('xl', colXlNumber)} ` +
                      `${getMsColFromColumns('lg', colLgNumber)} ` +
                      `${getMsColFromColumns('md', colMdNumber)} ` +
                      `${getMsColFromColumns('sm', colSmNumber)} `
                      }>
        {generatedChildren[childCounter.value]}
      </div>

      childrens.push(a);
      childCounter.value++;
    }

    return childrens;
  }

  return (
    <div className="ms-Grid" dir="ltr">
      {(renderedChildren && renderedChildren.length > 0) &&
        renderedChildren?.map((_a, index) => {
          return(
            <div key={uuidv4() + '-' + index} id={uuidv4() + '-' + index} className="ms-Grid-row">
              {
                renderedChildren[index]?.map((b: JSX.Element, _i: number) => {
                  return b;
                })
              }
            </div>
          )
        })
      }
    </div>
  );
}
