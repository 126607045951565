import {matchPath, useLocation} from "react-router-dom";
import {RcLayoutContainer} from "../RcLayoutContainer";
import {BlankLayout, NotFoundLayout} from "../../layouts";
import React, {useContext} from "react";
import {generateRoutesFromSidebarCommands} from "../../utilities/routing";
import {IRcRoute} from "./IRcRoute";
import {RcAuthContext} from "../../providers/RcAuthProvider";
import {Spinner, SpinnerSize} from "@fluentui/react";
import {IRcSearchBarCustomSearchResult} from "../RcHeaderBar/components/RcSearchBar";
import {RcApplicationMenu} from "../../common/RcApplicationMenu";
import {RcAppAuthTypes} from "../RcReactApp/RcReactApp";

export interface IRcRouterSwitchWithLayoutProps {
  applicationMenu: RcApplicationMenu[],
  children: any;
  searchbarCustomSearches?: ((q: string) => Promise<IRcSearchBarCustomSearchResult>)[] | undefined;
  authType: RcAppAuthTypes
}

export const RcRouterSwitchWithLayout = (props: IRcRouterSwitchWithLayoutProps) => {

  const location = useLocation();
  let matched: any = null;
  let matchedRoute: IRcRoute | undefined;
  const routesFromSidebarCommands = generateRoutesFromSidebarCommands(props.applicationMenu);
  const authContext = useContext(RcAuthContext);

  routesFromSidebarCommands.every((route) => {
    const tmpMatch = matchPath(route.path,location.pathname);

    /*const tmpMatch = matchPath(location.pathname, {
      path: route.path,
      exact: true,
      strict: true,
      sensitive: false
    });*/

    if(tmpMatch){
      matched = route.layout ? route.layout : 'default';
      matchedRoute = route;
      return false;
    }
    return true;
  })

  if(matchedRoute && (matchedRoute?.isPublic === false && props.authType !== RcAppAuthTypes.none && authContext.type !== 'notDefined')) {
    if(!authContext.isAuthenticated()) {

      /*setTimeout(() => {
        authContext.signinRedirect()
      }, 2000);*/

      setTimeout(authContext.signinRedirect, 2000);

      return (
        <BlankLayout>
          <div style={{
            position: 'absolute', left: '50%', top: '50%',
            transform: 'translate(-50%, -50%)'
          }}>
            <Spinner label="Attendi..." size={SpinnerSize.large} />
          </div>
        </BlankLayout>
      )
    }
  }

  if(matched === 'default') {
    return(
      <RcLayoutContainer searchbarCustomSearches={props.searchbarCustomSearches} sidebarCommands={props.applicationMenu}>
        {props.children}
      </RcLayoutContainer>
    )
  } else if(matched === 'blank' || location.pathname.includes("identity")) {
    return(
      <BlankLayout>
        {props.children}
      </BlankLayout>
    )
  } else {
    return(
      <NotFoundLayout>
        {props.children}
      </NotFoundLayout>
    )
  }
}
