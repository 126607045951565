import {AuthInterface} from "./auth.interface";
import jwt_decode from "jwt-decode";

export default class AuthRocketService implements AuthInterface {
  private _routeBasename: string | undefined;

  constructor(routeBasename: string | undefined) {
    this._routeBasename = routeBasename;
  }

  type: "notDefined" | "none" | "rocket" | "identity" = 'rocket';

  getUser(): Promise<any> {
    let userData = localStorage.getItem('user-data');

    if(userData) {
      userData = JSON.parse(userData);
      return Promise.resolve(userData);
    } else {
      return Promise.reject();
    }
  }

  isAuthenticated(): boolean {
    const token: string | null = localStorage.getItem('access-token');

    try {
      if(token) {
        const decodedToken: any = jwt_decode(token);

        if (decodedToken.exp < (new Date().getTime() + 1) / 1000) {
          return false;
        }

      } else {
        return false;
      }

    } catch (err) {
      return false;
    }

    return true;
  }

  signinRedirect = () => {
    window.location.href = this._routeBasename ? this._routeBasename + "/identity/login" : "/identity/login";
  }

  signinRedirectCallback() {
  }

  signinSilentCallback() {
  }

  logout() {
    localStorage.removeItem('access-token');
    localStorage.removeItem('user-data');
    this.navigateToScreen();
  }

  signoutRedirectCallback() {
  }

  login(username: string, password: string): Promise<any> {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ username: username, password: password })
    };

    const loginEndpoint = process.env.REACT_APP_AUTH_ROCKET_LOGIN_ENDPOINT ? process.env.REACT_APP_AUTH_ROCKET_LOGIN_ENDPOINT : '';

    return fetch(loginEndpoint, requestOptions)
            .then(response => response.json())
            .then((data: any) => {

              this.saveUserDataToLocalStorage(data);
              this.saveJwtToLocalStorage(data['token']);
              this.navigateToScreen();

              return data;
            })
            .catch(_err => {
              throw new Error(_err);
            });
  }

  private navigateToScreen = () => {
    window.location.href = this._routeBasename ? this._routeBasename : "/";
  };

  private saveUserDataToLocalStorage(user: any) {
    localStorage.setItem("user-data", JSON.stringify(user));
  }

  private saveJwtToLocalStorage(jwt: string) {
    localStorage.setItem("access-token", jwt);
  }
}
