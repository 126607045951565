import {
  FocusZone,
  getFocusStyle, getTheme,
  List, mergeStyleSets,
  SearchBox, Separator,
  Stack,
} from "@fluentui/react";
import React, {useContext, useState} from "react";
import {IRcApplicationRouteIndex} from "../../../common/IRcApplicationRouteIndex";
import {useNavigate} from "react-router-dom";
import {Icon} from "@fluentui/react/lib/Icon";
import {RcThemeContext} from "../../../providers/RcThemeProvider";

export interface IRcSearchBarProps {
  commands: IRcApplicationRouteIndex[];
  customSearches?: ((q: string) => Promise<IRcSearchBarCustomSearchResult>)[];
}

export interface IRcSearchBarCustomSearchResult {
  title: string,
  items: IRcSearchBarCustomSearchResultItem[],
}

export interface IRcSearchBarCustomSearchResultItem {
  icon: JSX.Element,
  description: string,
  link: string,
}

export const RcSearchBar = (props: IRcSearchBarProps): JSX.Element => {

  const [filteredCommands, setFilteredCommands] = useState<IRcApplicationRouteIndex[]>([]);
  const [customSearchesResult, setCustomSearchesResult] = useState<IRcSearchBarCustomSearchResult[]>([]);
  const [searchText, setSearchText] = useState<string>('');
  const [suggestionsOpened, setSuggestionsOpened] = useState<boolean>(false);
  let timeOutId: number;

  const theme = getTheme();
  const themeContext = useContext(RcThemeContext);
  const history = useNavigate();

  const styles: any = mergeStyleSets({
    root: {
      width: "100%",
      position: "relative",
    },
    itemCell: [
      getFocusStyle(theme, { inset: -1 }),
      {
        alignItems: 'center',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        fontSize: 14,
        paddingBottom: 5,
        cursor: 'pointer',
        selectors: {
          '&:hover': { background: theme.palette.neutralLight },
          '&:focus': { background: theme.palette.neutralLight },
        },
      },
    ],
    searchSummaryContainer: {
      boxShadow: "0 25.6px 57.6px 0 rgb(0 0 0 / 22%), 0 4.8px 14.4px 0 rgb(0 0 0 / 18%)",
      borderRadius: 2,
      width: "100%",
      border: "1px solid rgb(204, 204, 204)",
      backgroundColor: "rgb(255, 255, 255)",
      zIndex: 3,
      position: "absolute",
      top: 45,
      margin: 0,
      padding: '0 16px',
      maxHeight: 375,
      overflowY: "auto",
    },
    searchSummaryHeader: {
      padding: "0 10px",
      borderBottom: "1px solid #ccc",
    },
    searchSummary: {
      width: "100%",
      backgroundColor: "rgb(255, 255, 255)",
      fontSize: "0.9rem",
      zIndex: 3,
      top: 45,
      margin: 0,
      padding: 0,
      listStyle: "none",
      overflowY: "auto",
    },
    itemContent: {
      marginLeft: 10,
      overflow: 'hidden',
      flexGrow: 1,
      display: 'flex',
      alignItems: 'center',
    },
    searchCategoryLabel: {
      padding: '8px 0',
      fontWeight: 900,
      height: 24,
      display: 'flex',
      flexWrap: 'nowrap',
      alignItems: 'center',
    }
  });

  const onClickItem = (item: IRcApplicationRouteIndex): void => {
    history(item.routerLink);
    //history.push(item.routerLink);
    setSearchText('');
    setFilteredCommands([]);
    setSuggestionsOpened(false);
  }

  const onClickItemCustomSearch = (item: IRcSearchBarCustomSearchResultItem): void => {
    history(item.link);
    //history.push(item.link);
    setSearchText('');
    setCustomSearchesResult([]);
    setFilteredCommands([]);
    setSuggestionsOpened(false);
  }

  const onRenderCell = (item: IRcApplicationRouteIndex, _index: number): JSX.Element => {
    return (
      <div className={styles.itemCell} data-is-focusable={true} onClick={() => onClickItem(item)}>
        <div className={styles.itemContent}>
          <Icon iconName={item.icon} style={{marginRight: 5}} />
          {item.title}
        </div>
      </div>
    );
  };

  const onRenderCellCustomSearch = (item: IRcSearchBarCustomSearchResultItem, _index: number): JSX.Element => {
    return (
      <div className={styles.itemCell} data-is-focusable={true} onClick={() => onClickItemCustomSearch(item)}>
        <div className={styles.itemContent}>
          <div style={{marginRight: 5, minWidth: 18}}>{item.icon}</div>
          {item.description}
        </div>
      </div>
    );
  };

  const onSearchChanged = async (_event: React.ChangeEvent<HTMLInputElement>, newValue?: string): Promise<void> => {
    setSearchText(newValue ? newValue : '');
    if(newValue && newValue !== '') {
      const filtered = props.commands.filter((cmd: IRcApplicationRouteIndex) => cmd.hasIndex && cmd.title.toLowerCase().includes(newValue.toLowerCase()));
      setFilteredCommands(filtered);

      // Custom searches

      if(props.customSearches && newValue) {
        const promises: Promise<IRcSearchBarCustomSearchResult>[] = [];

        props.customSearches.forEach((customSearch) => {

          promises.push(customSearch(newValue));

          Promise.all(promises).then((values) => {
            setCustomSearchesResult([]);
            values.forEach((res) => {
              setCustomSearchesResult(old => [...old, res]);
            })
          })

        })
      }

      // End custom searches

      if(filtered.length > 0 || (customSearchesResult && customSearchesResult.length > 0) )
        setSuggestionsOpened(true);

      return;
    }

    setCustomSearchesResult([]);
    setFilteredCommands([]);
    setSuggestionsOpened(false);
  }

  const onSearchCleared = (): void => {
    setFilteredCommands([]);
    setSuggestionsOpened(false);
  }

  const onBlurHandler = (): void => {
    timeOutId = setTimeout(() => {
      setSearchText('');
      setSuggestionsOpened(false);
    })
  }

  const onFocusHandler = (): void => {
    clearTimeout(timeOutId);
  }

  return (
    <Stack onBlur={onBlurHandler} onFocus={onFocusHandler} className={styles.root}>
      <FocusZone style={{background: '#fff', borderRadius: 2}}>
        <SearchBox styles={{root: {background: themeContext?.rcTheme?.headerBar?.backgroundColor! + '33', border: 'none'}}} autoComplete={'off'} placeholder={'Cerca all\'interno della piattaforma'} showIcon onClear={onSearchCleared} onChange={onSearchChanged} value={searchText} />
        {(suggestionsOpened) &&
          <div className={styles.searchSummaryContainer}>
            <Stack wrap>
              {(filteredCommands && filteredCommands.length > 0) &&
                <Stack grow>
                  <Separator className={styles.searchCategoryLabel} alignContent="start">Servizi</Separator>
                  <List className={styles.searchSummary} items={filteredCommands} onRenderCell={onRenderCell}/>
                </Stack>
              }
              {(customSearchesResult && customSearchesResult.length > 0) &&
                customSearchesResult
                  .filter((csr) => csr.items?.length > 0)
                  .map((result: IRcSearchBarCustomSearchResult, index) => {
                    return (
                      <Stack grow key={'rc-custom-search-' + index}>
                        <Separator className={styles.searchCategoryLabel} alignContent="start">{result.title}</Separator>
                        <List className={styles.searchSummary} items={result.items} onRenderCell={onRenderCellCustomSearch}/>
                      </Stack>
                    )
                  })
              }
            </Stack>
            <Separator className={styles.searchCategoryLabel} />
          </div>
        }
      </FocusZone>
    </Stack>
  )
}
