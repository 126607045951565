import React, {useEffect, useState} from "react";
import {MaskedTextField, TextField} from '@fluentui/react';
import {Text} from "@fluentui/react/lib/Text";
import {IRcFormFieldProps, RcFormBaseField} from "./RcFormBaseField";

export interface IRcFormTextFieldProps extends IRcFormFieldProps {
  /**
   * Prefix displayed before the text field contents. This is not included in the value. Ensure a descriptive label is present to assist screen readers, as the value does not include the prefix.
   */
  prefix?: string,

  /**
   * Suffix displayed after the text field contents. This is not included in the value. Ensure a descriptive label is present to assist screen readers, as the value does not include the suffix.
   */
  suffix?: string,

  /**
   * The masking string that defines the mask's behavior. A backslash will escape any character. Special format characters are: '9': [0-9] 'a': [a-zA-Z] '*': [a-zA-Z0-9]
   */
  mask?: string,

  /**
   * The character to show in place of unfilled characters of the mask. (default _ )
   */
  maskChar?: string,

  /**
   * An object defining the format characters and corresponding regexp values. Default format characters: { '9': /[0-9]/, 'a': /[a-zA-Z]/, '*': /[a-zA-Z0-9]/ }
   */
  maskFormat?: { [key: string]: RegExp; }
}

export const RcFormTextField = (props: IRcFormTextFieldProps): JSX.Element => {

  const [value, setValue] = useState<string | undefined>('');

  useEffect(() => {
    if(props.initialValue !== undefined) {
      setValue(props.initialValue);
    }
  }, [props.initialValue])

  // todo: Bug sul value quando viene utilizzata una MaskedTextField, indagare.
  const onChangeValue = React.useCallback(
    (_event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
      setValue(newValue || '');
    },
    [],
  );

  let EditComponent = null;

  if(props.mask) {
    EditComponent = <MaskedTextField maskFormat={props.maskFormat} mask={props.mask} maskChar={props.maskChar} suffix={props.suffix} prefix={props.prefix} autoComplete={'off'} id={props.id} key={props.id} value={value} placeholder={props.placeholder} onChange={onChangeValue}/>;
  } else {
    EditComponent = <TextField suffix={props.suffix} prefix={props.prefix} autoComplete={'off'} id={props.id} key={props.id} value={value} placeholder={props.placeholder} onChange={onChangeValue}/>;
  }

  const ViewComponent = <Text>{value}</Text>

  return(
    <RcFormBaseField fieldProps={props} value={value} editModeComponent={EditComponent} viewModeComponent={ViewComponent} />
  )
}



