import React from "react";
import {Stack} from "@fluentui/react";
import {Text} from "@fluentui/react/lib/Text";

export interface IRcPageProps {
  title?: string,
  subtitle?: string,
  children: any,
  maxWidth?: number | undefined;
}

export const RcPage = (props : IRcPageProps): JSX.Element => {

  const styles : any = {
    root: {
      maxWidth: props.maxWidth ? props.maxWidth : '100%',
      width: '100%',
      margin: '0 auto',
      paddingTop: 32,
      paddingBottom: 64,
      paddingRight: 32,
      paddingLeft: 32
    },
  }

  return (
    <Stack className={styles.root}>
      {props.title &&
        <Text variant={'xLarge'} style={{marginTop: 8, marginBottom: 8}} key={props.title}>{props.title}</Text>
      }
      {props.subtitle &&
        <Text variant={'medium'} style={{marginTop: 8, marginBottom: 8}} key={props.subtitle}>{props.subtitle}</Text>
      }
      <div style={{marginBottom: 32}}>
        {props.children}
      </div>
    </Stack>
  )
}
