import React from 'react';
import {RcEntityFilterType} from "../../../../aop";
import {IRcDataGridColumn} from "../../RcDataGrid";
import {RcDataGridFilterText} from "./RcDataGridFilterText";
import {RcDataGridFilterNumeric} from "./RcDataGridFilterNumeric";

export class FilterComponentFactory {

  static getFilter(columnDefinition: IRcDataGridColumn, onFilterChanged: (propertyName: string, data: any) => void, initialValue: any) {

    // Check if filterField is set. If not, get the fieldName as filter
    const filterPropertyName = columnDefinition.filterField ? columnDefinition.filterField : columnDefinition.fieldName!;

    switch (columnDefinition.filterType) {
      case RcEntityFilterType.text:
        return <RcDataGridFilterText onFilterChanged={onFilterChanged} propertyDisplayName={columnDefinition.name} propertyKey={filterPropertyName} filter={null} initialValue={initialValue}/>
      case RcEntityFilterType.numeric:
        return <RcDataGridFilterNumeric onFilterChanged={onFilterChanged} propertyDisplayName={columnDefinition.name} propertyKey={filterPropertyName} filter={null} initialValue={initialValue} />
      default:
        return <div>no filter</div>
    }
  }

  /*static getFilter(filterMetadata: RcEntityPropertyFilterMetadata, onFilterChanged: (propertyName: string, data: any) => void, initialValue: any, propertyDisplayName: string ) {
    switch (filterMetadata.filterType) {
      case RcEntityFilterType.select:
        return <RcDataGridFilterSelect propertyKey={filterMetadata.fieldName} propertyDisplayName={propertyDisplayName} filter={filterMetadata} onFilterChanged={onFilterChanged} initialValue={initialValue} />
      case RcEntityFilterType.multiselect:
        return <RcDataGridFilterSelect propertyKey={filterMetadata.fieldName} propertyDisplayName={propertyDisplayName} filter={filterMetadata} onFilterChanged={onFilterChanged} initialValue={initialValue} />
      case RcEntityFilterType.text:
        return <RcDataGridFilterText onFilterChanged={onFilterChanged} propertyDisplayName={propertyDisplayName} propertyKey={filterMetadata.fieldName} filter={filterMetadata} initialValue={initialValue}/>
      case RcEntityFilterType.numeric:
        return <RcDataGridFilterNumeric onFilterChanged={onFilterChanged} propertyDisplayName={propertyDisplayName} propertyKey={filterMetadata.fieldName} filter={filterMetadata} initialValue={initialValue}/>
      case RcEntityFilterType.date:
        return <RcDataGridFilterDate onFilterChanged={onFilterChanged} propertyDisplayName={propertyDisplayName} propertyKey={filterMetadata.fieldName} filter={filterMetadata} initialValue={initialValue}/>
      case RcEntityFilterType.composite:
        return <div>Composite filter</div>
      default:
        return <div>no filter</div>
    }
  }*/
}
