import {
    ContextualMenu,
    DefaultButton,
    Dialog,
    DialogFooter,
    DialogType, getTheme, MessageBar, MessageBarType,
    PrimaryButton,
    Separator, TextField
} from "@fluentui/react";
import React, {useEffect, useState} from "react";

export interface IRcConfirmDialogProps {
    isOpen: boolean,
    title: string,
    subTitle: string,
    onConfirmAction: () => void,
    onCancelAction?: () => void,
    confirmText?: string,
    cancelText?: string,
    doubleCheckText?: string,
    doubleCheckMessageText?: string,
    doubleCheckTextFieldPlaceholderText?: string,
    draggable?: boolean,
    errorMessageText?: string | undefined,
}

export function RcConfirmDialog(props: IRcConfirmDialogProps) {

    const [doubleCheckTextValue, setDoubleCheckTextValue] = useState<string | undefined>(undefined);
    const [doubleCheckOriginalTextValue, setDoubleCheckOriginalTextValue] = useState<string | undefined>();

    useEffect(() => {
        if(props.doubleCheckText)
            setDoubleCheckOriginalTextValue(props.doubleCheckText?.trim());

    }, [props.doubleCheckText])

    return(
        <Dialog
            isOpen={props.isOpen} isBlocking={true}
            minWidth={500}
            modalProps={props.draggable ? {dragOptions: {
                    moveMenuItemText: 'Move',
                    closeMenuItemText: 'Close',
                    menu: ContextualMenu,
                    keepInBounds: true,
                }} : undefined}
            dialogContentProps={{
                type: DialogType.largeHeader,
                title: props.title,
                subText: props.subTitle
            }}>
            {props.errorMessageText &&
                <MessageBar messageBarType={MessageBarType.error} className="rc-margin-b-16">
                    {props.errorMessageText}
                </MessageBar>
            }
            {props.doubleCheckText &&
                <div>
                    <p>{props.doubleCheckMessageText}</p>
                    <p style={{border: '1px dashed ' + getTheme().palette.themePrimary, padding: 5}}>{props.doubleCheckText}</p>
                    <TextField onChange={(_event, newValue) => setDoubleCheckTextValue(newValue)} placeholder={props.doubleCheckTextFieldPlaceholderText} />
                </div>
            }

            <Separator />
            <DialogFooter>
                <PrimaryButton onClick={props.onConfirmAction} disabled={props.doubleCheckText ? doubleCheckOriginalTextValue !== doubleCheckTextValue : false}>{props.confirmText}</PrimaryButton>
                <DefaultButton onClick={props.onCancelAction}>{props.cancelText}</DefaultButton>
            </DialogFooter>
        </Dialog>
    )
}

RcConfirmDialog.defaultProps = {
  confirmText: 'Conferma',
  cancelText: 'Annulla',
  doubleCheckMessageText: 'Inserisci il testo sottostante nella casella di testo per confermare.',
  doubleCheckTextFieldPlaceholderText: 'Inserisci il testo di conferma',
  draggable: false,
}
