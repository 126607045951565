import {IFieldOperationStrategy} from "../filters/IFieldOperationStrategy";
import {IOperationMarker} from "../models/IOperationMarker";
import {AndOperatorStrategy} from "./AndOperatorStrategy";
import {StringOperationStrategy} from "../filters/StringOperationStrategy";
import {ComparisonOperationStrategy} from "../filters/ComparisonOperationStrategy";
import {ArrayOperationStrategy} from "../filters/ArrayOperationStrategy";
import {IFieldOperand} from "../models/IFieldOperand";

export class OrOperatorStrategy implements IFieldOperationStrategy {

  _items: IFieldOperationStrategy[] = [];
  _list: IOperationMarker[] = [];

  constructor(list: IOperationMarker[]) {
    this._list = list;

    this._list.forEach((operation) => {
      if(operation instanceof AndOperatorStrategy) {
        this._items.push(new AndOperatorStrategy([(operation as IOperationMarker)]));
      } else if(operation instanceof OrOperatorStrategy) {
        this._items.push(new OrOperatorStrategy([(operation as IOperationMarker)]));
      } else {

        const castOperand = (operation as IFieldOperand);

        if(castOperand.filterType === 'string') {

          this._items.push(new StringOperationStrategy(castOperand))

        } else if(castOperand.filterType === 'comparison') {

          this._items.push(new ComparisonOperationStrategy(castOperand))

        } else if (castOperand.filterType === 'array') {

          this._items.push(new ArrayOperationStrategy(castOperand))

        }
      }
    })

  }

  add(_operation: IFieldOperationStrategy): void {
    this._items.push(_operation)
  }

  stringify(): string {
    let ret = '(';

    ret += this._items.map((operand) => operand.stringify()).join(' or ');

    ret += ')'

    return ret;
  }

}
