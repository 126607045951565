import React, {ReactNode, useContext, useEffect, useState} from "react";
import RcDataGridProvider, {RcDataGridContext} from "./providers/RcDataGridProvider";
import {
  Callout,
  ConstrainMode,
  DetailsListLayoutMode,
  DirectionalHint,
  getTheme,
  IColumn,
  IconButton,
  IDetailsListStyles,
  SearchBox,
  SelectionMode,
  Shimmer,
  ShimmeredDetailsList,
  Stack,
  Text
} from "@fluentui/react";
import {useBoolean} from "@fluentui/react-hooks";
import {RcDataGridFilterPanel} from "./components";
import {RcEntityFilterType} from "../../aop";
import {RcIcon} from "../RcIcon";
import {RcIcons} from "../../utilities/iconFactory";
import {KeyedCollection} from "../../utilities/keyedCollection";
import {SortingData} from "./models/sortingData";
// @ts-ignore
import {RcDataGridSortingPanel} from "./components/sorting/RcDataGridSortingPanel";
import {RcDataGridPagination} from "./components/pagination/RcDataGridPagination";
import {RcDataGridColumnsChooser} from "./components/columns-chooser/RcDataGridColumnsChooser";
import {IRcCommandBarButtonProps} from "../RcPageToolbar";
import {FilterComponentFactory} from "./components/filters/filterComponentFactory";
import {RcTagItem} from "../RcTagItem";

export interface IRcDataGridPaginationInfo {
  skip?: number,
  take?: number,
  totalResultCount?: number,
}

export interface IRcDataGridColumn extends IColumn {
  visible: boolean,

  fieldName: string,

  /**
   * Set the filter type
   */
  filterType?: RcEntityFilterType,

  /**
   * Set the filter property name if server side entity property name is different
   */
  filterField?: string,
}

export interface IRcDataGridProps {

  id: string,

  items: any[] | undefined,

  paginationInfo: IRcDataGridPaginationInfo,

  columns: IRcDataGridColumn[],

  onDataReloadRequired: (currentPage: number, itemsPerPage: number, filters: string | undefined, globalQuery: string | undefined) => void,

  requireOnDataChangeOnFirstLoad?: boolean,

  onItemInvoked?: (item?: any, index?: (number | undefined), ev?: (Event | undefined)) => void,

  /**
   * No content text
   */
  noContentText?: string | JSX.Element | undefined,

  /**
   * Enable filtering feature
   */
  enableFiltering?: boolean,

  /**
   * Show global filter button
   */
  showGlobalFilterButton?: boolean,

  /**
   * Show columns chooser
   */
  showColumnsChooser?: boolean;

  /**
   * Show filters summary
   */
  showFilterSummary?: boolean,

  /**
   * Enable sorting feature
   */
  enableSorting?: boolean,

  /**
   * Enable global search
   */
  enableGlobalSearch?: boolean,

  /**
   * Enable global search
   */
  itemSelectionMode?: 'none' | 'single' | 'multiple',

  /**
   * If provided, will be the “default” item column renderer method. This affects cells within the rows, not the rows themselves.
   * If a column definition provides its own onRender method, that will be used instead of this.
   */
  onRenderItemColumn?: (item?: any, index?: number | undefined, column?: IRcDataGridColumn | undefined) => ReactNode,

  onToolbarGenerated?: (items: IRcCommandBarButtonProps[]) => void,

  onRowClicked?: (item: any) => void,

  compact?: boolean,

  dataLoading: boolean,
}

export const RcDataGrid = (props: IRcDataGridProps): JSX.Element => {
  return(
    <RcDataGridProvider>
      <RcDataGridComponent {...props} />
    </RcDataGridProvider>
  )
}

RcDataGrid.defaultProps = {
  paginationInfo: undefined,
  columns: [],
  enableFiltering: true,
  showGlobalFilterButton: true,
  showColumnsChooser: false,
  noContentText: undefined,
  showFilterSummary: true,
  enableSorting: false,
  globalSearchQueryChanged: undefined,
  itemSelectionMode: 'single',
  onRenderItemColumn: undefined,
  compact: false,
  dataLoading: false,
  requireOnDataChangeOnFirstLoad: false,
};

const convertSelectionMode = (selectionMode: 'single' | 'multiple' | 'none'): SelectionMode => {
  switch (selectionMode) {
    case 'none':
      return SelectionMode.none;
    case 'single':
      return SelectionMode.single;
    case 'multiple':
      return SelectionMode.multiple;
    default:
      return SelectionMode.none;
  }
}

const RcDataGridComponent = (props: IRcDataGridProps): JSX.Element => {

  const { filters, sorting, addOrUpdateFilter, addOrUpdateSorting, updatePagination, pagination, filterString } = useContext(RcDataGridContext);
  const [filterPanelVisible, {setTrue: openFilterPanel, setFalse: dismissFilterPanel}] = useBoolean(false);
  const [columnsChooserPanelVisible, {setTrue: openColumnsChooserPanel, setFalse: dismissColumnsChooserPanel}] = useBoolean(false);
  // @ts-ignore
  const [sortingPanelVisible, {setTrue: openSortingPanel, setFalse: dismissSortingPanel}] = useBoolean(false);
  const [columns, setColumns] = useState<IRcDataGridColumn[] | undefined>(undefined);
  const [allColumns, setAllColumns] = useState<IRcDataGridColumn[] | undefined>(undefined);

  const [currentColumn, setCurrentColumn] = useState<IRcDataGridColumn | undefined>(undefined);

  const [currentHeaderFilter, setCurrentHeaderFilter] = useState<string | undefined>(undefined);
  const [globalSearchQuery, setGlobalSearchQuery] = useState<string | undefined>(undefined);

  const [firstLoad, setFirstLoad] = useState<boolean>(true);

  const gridStyles: Partial<IDetailsListStyles> = {
    root: {
      selectors: {
        "& [role=grid]": {
          overflowX: "auto",
          display: "flex",
          flexDirection: "column",
          alignItems: "normal",
          paddingBottom: 0
        },
        "& [role=gridcell]": {
          display: "flex",
          alignItems: 'center'
        },
        "& [role=row]": {
          paddingTop: 0,
        },
        ".ms-TextField": {
          padding: "0px 3px;"
        }
      }
    },
  };

  /**
   * Triggered when component mount
   */
  useEffect(() => {

    if(props.onToolbarGenerated) {
      const _farItems: IRcCommandBarButtonProps[] = [
        {
          key: 'rcDataGridExternalToolbarFilter',
          id: 'rcDataGridExternalToolbarFilter',
          cacheKey: 'rcDataGridExternalToolbarFilterCacheKey', // changing this key will invalidate this item's cache
          iconProps: { iconName: 'Filter' },
          onClick: () => openFilterPanel()
        },
        {
          key: 'rcDataGridExternalToolbarColumnsChooser',
          id: 'rcDataGridExternalToolbarColumnsChooser',
          cacheKey: 'rcDataGridExternalToolbarColumnsChooserCacheKey', // changing this key will invalidate this item's cache
          iconProps: { iconName: 'ColumnOptions' },
          onClick: () => openColumnsChooserPanel()
        },
      ]

      props.onToolbarGenerated(_farItems);
    }

    _generateDataGridColumns();

  },[]);

  useEffect(() => {
    if(!firstLoad || props.requireOnDataChangeOnFirstLoad)
      props.onDataReloadRequired(pagination.page, pagination.limit, filterString, globalSearchQuery);
    setFirstLoad(false);

    _generateDataGridColumns();
  }, [filters, sorting, pagination, globalSearchQuery])


  // @ts-ignore
  const _generateDataGridColumns = () => {
    const properties: IRcDataGridColumn[] = [];
    const allColumns: IRcDataGridColumn[] = [];

    props.columns.forEach((currentColumn: IRcDataGridColumn) => {
      // @ts-ignore
      const isComplex: boolean = currentColumn.filterType === RcEntityFilterType.composite;

      // Check if column (field) is visible
      const isVisible = currentColumn.visible;

      const propForGrid: IRcDataGridColumn = {
        key: 'col_' + (currentColumn.key ? currentColumn.key.toLowerCase().trim() : ''),
        name: currentColumn.name ? currentColumn.name : '',
        minWidth: currentColumn.minWidth,
        maxWidth: currentColumn.maxWidth,
        flexGrow: 1,
        fieldName: currentColumn.fieldName ? currentColumn.fieldName : '',
        isResizable: true,
        visible: isVisible,
        filterType: currentColumn.filterType,
        onColumnClick: (_ev, _column) => {
          const sortingData: SortingData[] = [];
          if((_ev.target as HTMLElement).classList.contains('ms-DetailsHeader-cellTitle')) {
            sortingData.push(new SortingData(currentColumn.key, !_getSortDetail(currentColumn.key)?.isDescending));
            addOrUpdateSorting(sortingData)
          }
        },
        onRenderHeader: (_props1) => {

          if(!props.enableFiltering)
            return <span><b>{currentColumn.name}</b></span>;

          return (
            <div>
              <Stack horizontal tokens={{childrenGap: 8}}>
                <Stack.Item>
                  <span><b>{currentColumn.name}</b></span>
                </Stack.Item>
                {currentColumn.filterType &&
                  <Stack.Item id={'rcDataGrid_col_header_filter_' + currentColumn.key} style={{cursor: 'pointer'}} onClick={(_e) => { setCurrentHeaderFilter(currentColumn.key); setCurrentColumn(currentColumn)}}>
                    {filters.ContainsKey(currentColumn.filterField ? currentColumn.filterField : currentColumn.fieldName) ? (
                      <RcIcon id={'btnHeaderColumnFilter'} iconName={RcIcons.filterSolid}
                              height={12}
                              width={12}
                              fill={getTheme().palette.themePrimary}  />
                    ) : (
                      <RcIcon iconName={RcIcons.filter}
                              height={12}
                              width={12}
                              fill={'rgb(96, 94, 92)'}  />
                    )
                    }
                  </Stack.Item>
                }
                {_getSortDetail(currentColumn.key) &&
                <Stack.Item id={'rcDataGrid_col_header_sort_' + currentColumn.key} style={{cursor: 'pointer'}}>
                  <RcIcon iconName={_getSortDetail(currentColumn.key)?.isDescending ? RcIcons.sortDown : RcIcons.sortUp}
                          height={12}
                          width={12}
                          fill={'rgb(96, 94, 92)'}  />
                  {sorting.length > 1 &&
                    <span>{_getSortPriority(currentColumn.key)}</span>
                  }
                </Stack.Item>
                }
              </Stack>
            </div>
          )
        }
      };

      if(currentColumn.onRender) {
        propForGrid.onRender = currentColumn.onRender;
      }

      if(currentColumn.visible)
        properties.push(propForGrid);

      allColumns.push(propForGrid)
    });

    setAllColumns(allColumns);
    setColumns(properties);
  }

  // Sorting handlers
  const _getSortDetail = (propertyName: string): SortingData | undefined => {
    const sort = sorting.filter((a) => a.propertyName === propertyName);
    if(sort.length === 1)
      return sort[0];

    return undefined;
  }

  const _getSortPriority = (propertyName: string): number => {
    const sort = sorting.map((a) => a.propertyName).indexOf(propertyName, 0);
    return sort + 1;
  }

  // @ts-ignore
  const onSortingPanelDismiss = () => {
    dismissSortingPanel();
  }


  // Filtering Handlers
  const onFilterPanelDismiss = () => {
    dismissFilterPanel();
  }

  // @ts-ignore
  const onFilterChanged = (propertyName: string, data: any) => {
    setCurrentHeaderFilter(undefined);
    addOrUpdateFilter(propertyName, data);
  }

  const onFilterDeletedHandler = (propertyName: string) => {
    addOrUpdateFilter(propertyName, undefined);
  }


  // Pagination handlers
  const onPaginationChangedHandler = (limit: number, page: number) => {
    updatePagination(limit, page);
  }

  // Columns chooser handlers
  const onColumnVisibilityChange = (columnKey: string, visibility: boolean) => {

    let cols = [...allColumns!];
    const indexOfColumn = cols.findIndex((a: IRcDataGridColumn) => a.key === columnKey);

    props.columns[indexOfColumn].visible = visibility;
    return;

    if(visibility) {
      let cols = [...allColumns!];
      const indexOfColumn = cols.findIndex((a: IRcDataGridColumn) => a.key === columnKey);

      props.columns[indexOfColumn].visible = visibility;

      setColumns(props.columns);

      if(indexOfColumn !== -1) {
        cols[indexOfColumn].visible = visibility;
        setColumns(cols);
      }
    } else {

    }
  }

  return(
    <div>

      {/* Main toolbar Components */}
      <Stack horizontal tokens={{childrenGap: 10}} verticalAlign={'center'} wrap>

        {/* Pagination Component */}

        <Stack.Item grow>
          {props.paginationInfo ?
            <div>
              {(props.paginationInfo?.totalResultCount !== undefined ) ?
                <RcDataGridPagination onPaginationChanged={onPaginationChangedHandler} totalItems={props.paginationInfo?.totalResultCount} limit={props.paginationInfo?.take!} /> :
                <Shimmer width={'50%'} />
              }
            </div> :
            <div>
              {props.items ?
                <span>{props.items?.length} risultati</span> :
                <Shimmer width={'50%'} />
              }
            </div>
          }
        </Stack.Item>

        {/* Toolbar */}
        {!props.onToolbarGenerated &&
          <Stack.Item>
            <Stack horizontal tokens={{childrenGap: 10}}>

              {props.enableSorting &&
              <Stack.Item>
                {props.items ?
                  <IconButton id={'sortingButton'} onClick={openSortingPanel} iconProps={{iconName: 'Sort'}}/> :
                  <Shimmer width={32}/>
                }
              </Stack.Item>
              }

              {(props.showGlobalFilterButton) &&
              <Stack.Item>
                {props.items ?
                  <IconButton id={'filterButton'} onClick={openFilterPanel} iconProps={{iconName: 'Filter'}} /> :
                  <Shimmer width={32}/>
                }
              </Stack.Item>
              }

              {(props.showColumnsChooser) &&
              <Stack.Item>
                {props.items ?
                  <IconButton id={'columnChooserButton'} onClick={openColumnsChooserPanel} iconProps={{iconName: 'ColumnOptions'}} /> :
                  <Shimmer width={32}/>
                }
              </Stack.Item>
              }
            </Stack>
          </Stack.Item>
        }

        {/* Global search query Component */}
        <Stack.Item>
          {(props.enableGlobalSearch) &&
            <div>
              {(props.items) ?
                <SearchBox placeholder={'Ricerca...'} styles={{root: {minWidth: 200}}} onSearch={(newValue => setGlobalSearchQuery(newValue))} onClear={() => setGlobalSearchQuery(undefined)} />:
                <Shimmer width={200} />
              }
            </div>
          }

        </Stack.Item>

      </Stack>



      {/* Filter Summary Component */}
      {(props.showFilterSummary === true && filters.Count()) > 0 &&
        <Stack horizontal styles={{root: {paddingTop: 16}}}>
          <RcDataGridFilterSummary onFilterDeleted={onFilterDeletedHandler} filters={filters} />
        </Stack>
      }

      {/* Data Grid Component */}
      <Stack horizontal styles={{root: {borderBottom: '0px solid rgb(237, 235, 233)', paddingTop: 16, paddingBottom: 16}}}>
        <Stack.Item styles={{root: {width: '100%'}}}>
          <ShimmeredDetailsList
                                key={props.id}
                                selectionMode={convertSelectionMode(props.itemSelectionMode!)}
                                onActiveItemChanged={props.onRowClicked}
                                compact={props.compact}
                                onRenderItemColumn={props.onRenderItemColumn}
                                shimmerLines={10}
                                onItemInvoked={props.onItemInvoked}
                                onShouldVirtualize={_props1 => false}
                                styles={{root: {display: 'flex', alignItems: 'center'}}}
                                enableShimmer={props.dataLoading}
                                detailsListStyles={gridStyles}
                                constrainMode={ConstrainMode.horizontalConstrained}
                                layoutMode={DetailsListLayoutMode.justified}
                                columns={columns}
                                items={props.items ? props.items : []}/>
          { ((props.items?.length === 0 && !props.dataLoading)) && (
            <Stack horizontalAlign='center'>
              <Text>{props.noContentText ? props.noContentText : 'Nessun risultato trovato'}</Text>
            </Stack>
          )}

        </Stack.Item>


        {/* Callouts */}

        {/* Filter panel Component (Global) */}
        {filterPanelVisible &&
        <Callout
          target={props.onToolbarGenerated ? `#rcDataGridExternalToolbarFilter` : '#filterButton'}
          styles={{root: {padding: 20, backgroundColor: '#fff', maxWidth:250, minWidth: 250}}}
          onDismiss={dismissFilterPanel} isBeakVisible={true} gapSpace={0}
          directionalHint={props.onToolbarGenerated ? DirectionalHint.bottomCenter : DirectionalHint.bottomCenter}>
          <RcDataGridFilterPanel title={'Filtri'} properties={columns} onDismiss={onFilterPanelDismiss} />
        </Callout>
        }

        {/* Columns chooser panel Component (Global) */}
        {columnsChooserPanelVisible &&
        <Callout
          target={props.onToolbarGenerated ? `#rcDataGridExternalToolbarColumnsChooser` : `#columnChooserButton`}
          styles={{root: {padding: 20, backgroundColor: '#fff', maxWidth:250, minWidth: 250}}}
          onDismiss={dismissColumnsChooserPanel} isBeakVisible={true} gapSpace={0}
          directionalHint={props.onToolbarGenerated ? DirectionalHint.bottomCenter : DirectionalHint.rightCenter}>
          <RcDataGridColumnsChooser onChange={onColumnVisibilityChange} title={'Colonne'} columns={allColumns!} onDismiss={dismissColumnsChooserPanel} />
        </Callout>
        }

      </Stack>

      {/* Single column filter panel Component */}
      {((currentHeaderFilter !== undefined) && currentHeaderFilter === currentColumn?.key) &&
        <Callout target={'#rcDataGrid_col_header_filter_' + currentColumn.key}
                 styles={{root: {padding: 20, backgroundColor: '#fff', maxWidth:250, minWidth: 250}}}
                 onDismiss={(_e) => setCurrentHeaderFilter(undefined)}
                 isBeakVisible={true}
                 gapSpace={0}
                 directionalHint={DirectionalHint.bottomCenter}>
          {currentColumn?.filterType &&
            FilterComponentFactory.getFilter(currentColumn, (propertyName, data) =>  onFilterChanged(propertyName, data), filters.Item(currentColumn.fieldName!) ? filters.Item(currentColumn.fieldName!) : undefined)
          }
        </Callout>
      }

    </div>
  )
}

export interface IRcDataGridFilterSummaryProps {
  filters: KeyedCollection<any>,
  onFilterDeleted: (propertyName: string) => void;
}

const RcDataGridFilterSummary = (props: IRcDataGridFilterSummaryProps): JSX.Element => {

  const containerStyle: any = {
    display: 'flex',
    alignItems: 'center',
    lineHeight: '28px',
  }

  const onFilterDeletedHandler = (propertyName: string) => {
    props.onFilterDeleted(propertyName);
  }

  return (
    <Stack wrap horizontal style={containerStyle}>
      {props.filters.Keys().map((filter: string, _index: number) => {
        return <RcTagItem label={`${props.filters.Item(filter).displayValue()}`} iconName={'Filter'} value={`${filter}`} deleteAction={onFilterDeletedHandler} />
      })}
    </Stack>
  )
}
