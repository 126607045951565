import {
  DefaultEffects,
  Icon,
  Stack,
  Text, IIconProps, CommandBarButton
} from "@fluentui/react";
import React, {useEffect, useState} from "react";

export interface IRcCardActionProps {
  onClick?: () => void;
  iconName?: string;
  label?: string;
  buttonStyle?: React.CSSProperties;
}

export interface IRcCardProps {
  title: string;
  children: any;
  iconProps?: IIconProps
  borderRadius?: number,
}

const styleLabel: React.CSSProperties = {
  minHeight: 32
}

function generateActionComponent(props: IRcCardActionProps) {
  return (
    <CommandBarButton style={{...styleLabel, ...props.buttonStyle}} iconProps={{iconName: props.iconName}}
                      onClick={props.onClick}>{props.label}</CommandBarButton>
  )
}

export function RcCardAction(props: IRcCardActionProps) {
  return generateActionComponent(props);
}

export function RcCard(props: IRcCardProps) {

  const [actionChildren, setActionChildren] = useState<any | undefined>(undefined);
  const [genericChildren, setGenericChildren] = useState<any | undefined>(undefined);

  useEffect(() => {

    let actionChildrens = [];
    let genericChildrens = [];

    if(!props.children)
    {
      setActionChildren([]);
      setGenericChildren([]);
      return;
    }

    if (childrenIsArray()) {
      actionChildrens = props.children.filter((a: any) => a.type === RcCardAction);
      genericChildrens = props.children.filter((a: any) => a.type !== RcCardAction);
    } else {
      if (props.children.type === RcCardAction)
        actionChildrens.push(props.children);
      else
        genericChildrens.push(props.children);
    }
    ;

    setActionChildren(actionChildrens);
    setGenericChildren(genericChildrens);

  }, [])

  const topHorizontalContainerStyle: React.CSSProperties = {
    boxShadow: DefaultEffects.elevation4,
    background: '#ffffff',
    borderRadius: props.borderRadius ? props.borderRadius : 0,
  }

  const childrenIsArray = (): boolean => {
    return props.children.constructor.name === "Array";
  }

  const iconWrapperStyle: React.CSSProperties = {
    textAlign: 'center',
    alignContent: 'center',
    display: 'flex',
    marginRight: 8,
  }

  return (
    <div style={topHorizontalContainerStyle}>
      <Stack style={{
        maxHeight: 40,
        borderBottom: '1px solid #eaeef1',
        paddingLeft: 16,
        paddingRight: 16,
        paddingTop: 8,
        paddingBottom: 8
      }} horizontal horizontalAlign={"space-between"} verticalAlign={"center"}>
        {props.iconProps &&
          <Stack.Item>
            <div style={iconWrapperStyle}>
              <Icon {...props.iconProps} />
            </div>
          </Stack.Item>
        }
        <Stack.Item grow={2}>
          <Text variant={'mediumPlus'}>{props.title}</Text>
        </Stack.Item>
        {actionChildren &&
          <Stack.Item className="ms-hiddenMdDown">
            <Stack horizontal horizontalAlign={"end"} verticalAlign={'center'} verticalFill tokens={{childrenGap: 8}}>
              {actionChildren}
            </Stack>
          </Stack.Item>
        }
      </Stack>
      <div style={{padding: 16}}>
        {genericChildren}
      </div>
    </div>
  )
}
