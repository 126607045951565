import React, {useContext, useEffect, useMemo, useState} from "react";
import {IRcTheme} from "../RcTheme";
import {RcAuthContext, RcAuthProvider} from "../../providers/RcAuthProvider";
import {BrowserRouter as Router, Route, Routes, useLocation} from "react-router-dom";
import {Callback} from "../Auth/IdentityServer/Callback";
import {Logout} from "../Auth/IdentityServer/Logout";
import {SilentRenew} from "../Auth/IdentityServer/SilentRenew";
import {generateRoutesFromSidebarCommands} from "../../utilities/routing";
import {RcLayoutContainer} from "../RcLayoutContainer";
import RcLayoutProvider from "../../providers/RcLayoutProvider";
import RcThemeProvider from "../../providers/RcThemeProvider";
import {RcRouterSwitchWithLayout} from "../RcRoute";
import {RcSuspenseComponent} from "../RcRoute/RcSuspenseComponent";
import {LogoutCallback} from "../Auth/IdentityServer/LogoutCallback";
import {IRcSearchBarCustomSearchResult} from "../RcHeaderBar/components/RcSearchBar";
import {RcApplicationMenu} from "../../common/RcApplicationMenu";
import Login from "../Auth/Rocket/Login";
import {AuthInterface} from "../../services/auth/auth.interface";
import {IRcRoute} from "../RcRoute/IRcRoute";

export enum RcAppAuthTypes {
  none,
  identity,
  rocket
}

export interface IRcReactAppProps {
    menu: RcApplicationMenu[],
    theme: IRcTheme,
    searchBarCustomSearches?: ((q: string) => Promise<IRcSearchBarCustomSearchResult>)[];
    authType: RcAppAuthTypes,
    routerBasename?: string | undefined;
}

export interface IRcReactAppInnerProps extends IRcReactAppProps {
  routes: any[]
}

export const RcReactApp = (props: IRcReactAppProps): JSX.Element => {
  return (
    <RcAuthProvider authType={props.authType} routerBasename={props.routerBasename}>
      <RcReactAppInnerWrapper menu={props.menu} theme={props.theme} authType={props.authType} routerBasename={props.routerBasename} />
    </RcAuthProvider>
  )
}

export const RcReactAppInnerWrapper = (props: IRcReactAppProps): JSX.Element => {

  const [routes, setRoutes] = useState<{route: IRcRoute, visible: boolean, enabled: boolean}[] | undefined>(undefined);
  const authContext = useContext<AuthInterface>(RcAuthContext)

  const routesFromSidebarCommands = generateRoutesFromSidebarCommands(props.menu);

  const getUser = async (): Promise<any> => {
    if(authContext.isAuthenticated && authContext.isAuthenticated()) {
      return authContext.getUser();
    }
    return {};
  }

  const generateRoutes = async () => {

    const tmpRoutes: {route: IRcRoute, visible: boolean, enabled: boolean}[] = [];

    const routesToGenerate = routesFromSidebarCommands.filter((route) => route.component !== undefined || route.isHeader);

    for (const route of routesToGenerate) {
      const aa = await getUser();

      let visible = await checkItemVisibility(aa, route.visible)
      let enableIf = await checkItemVisibility(aa, route.enableRoute)

      tmpRoutes.push({route: route, visible: visible, enabled: enableIf});
    }

    return tmpRoutes;
  }

  useEffect(() => {
    if(authContext.type !== 'notDefined') {
      generateRoutes().then((res) => {
        setRoutes(res);
      });
    }
  }, [authContext])

  const checkItemVisibility = async (user: any, condition: boolean | ((user: any) => boolean) | undefined): Promise<boolean> => {

    if(condition === undefined)
      return true;

    if(typeof condition == "boolean")
      return condition;

    return condition(user);
  }

  const generateMenuForRcReactApp = (appMenu: RcApplicationMenu[], isChildren: boolean) => {
    const result: RcApplicationMenu[] = [];

    for (const menu of appMenu) {

      let isVisible: boolean | undefined;

      isVisible = routes?.filter(b => menu.id === b.route.key)[0]?.visible;
      menu.visible = isVisible;

      if(menu.children && menu.children.length > 0) {
        generateMenuForRcReactApp(menu.children, true)
      }

      if(!isChildren)
        result.push(menu);
    }

    return result;
  }

  const generateMenuForRcReactApp1 = useMemo(() => (appMenu: RcApplicationMenu[], isChildren: boolean) => {
    const aaaa = generateMenuForRcReactApp(appMenu, isChildren);
    return aaaa;
  }, [routes]);

  const generateRoutesForRcReactApp = () => {
    const res = routes?.filter(a => a.enabled);
    const rou = res?.map(a => a.route);
    return rou!;
  }

  return (
    <div>
      {(routes ? routes?.length > 0 : false) &&
        <RcReactAppInner menu={generateMenuForRcReactApp1(props.menu, false)} theme={props.theme} authType={props.authType} routes={generateRoutesForRcReactApp()} routerBasename={props.routerBasename} />
      }
    </div>
  );
}

export const RcReactAppInner = (props: IRcReactAppInnerProps): JSX.Element => {

  const NoMatch = (): JSX.Element => {
    const location = useLocation();
    console.log('no match')
    return (
      <h3>
        No match for <code>{location.pathname}</code>
      </h3>
    );
  }

  const generateRoutesByAuthProvider = (authType: RcAppAuthTypes) => {

    const routes: any[] = [];

    switch (authType) {

      case RcAppAuthTypes.identity:
        routes.push(<Route key={'identity-signin-oidc'} path={'/identity/signin-oidc'} element={<RcSuspenseComponent key={'signin-oidc'} exact={true} path="/identity/signin-oidc" component={Callback} />} />)
        routes.push(<Route key={'identity-logout'} path={"/identity/logout"}  element={<RcSuspenseComponent key={'logout'} component={Logout} />} />);
        routes.push(<Route key={'identity-logout-callback'} path={'/identity/logout/callback'} element={<RcSuspenseComponent key={'callback'} exact={true} path="/identity/logout/callback" component={LogoutCallback} />} />);
        routes.push(<Route key={'identity-silentrenew'} path={'/identity/silentrenew'} element={<RcSuspenseComponent key={'silentrenew'} exact={true} path="/identity/silentrenew" component={SilentRenew} />} />);
        break;

      case RcAppAuthTypes.rocket:
        routes.push(<Route key={'identity-login'} path={'/identity/login'} element={<RcSuspenseComponent key={'login'} exact={true} path="/identity/login" component={Login} />} />);
        break;
    }

    return routes;
  }


  return (
    <RcThemeProvider theme={props.theme}>
      <RcLayoutProvider sidebarCommands={props.menu}>
        <Router basename={props.routerBasename}>
          <RcRouterSwitchWithLayout searchbarCustomSearches={props.searchBarCustomSearches} applicationMenu={props.menu} authType={props.authType}>
            <Routes>
              {generateRoutesByAuthProvider(props.authType).map((route) => {
                return route;
              })}

              {props.routes.filter(a => !a.isHeader).map((route1, index) => {
                return <Route key={'rc-route-' + index} path={route1.path} element={<RcSuspenseComponent layout={RcLayoutContainer} layoutProps={{sidebarCommands: props.menu}} key={route1.key} path={route1.path} exact component={route1.component}/>} />
              })}

              <Route key="route-no-match" path="*" element={<NoMatch />}/>
            </Routes>

          </RcRouterSwitchWithLayout>
        </Router>
      </RcLayoutProvider>
    </RcThemeProvider>
  )
}
