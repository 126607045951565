import React, {createContext, ReactElement, useState} from "react";
import {ThemeProvider} from "@fluentui/react";
import {IRcTheme, IRcThemeLayout} from "../components/RcTheme";

export type RcThemeContextState = {
    rcTheme?: IRcThemeLayout
} | undefined;

export const RcThemeContext = createContext<RcThemeContextState>(undefined);

export interface RcThemeProviderProps {
    theme: IRcTheme,
    children?: ReactElement;
}

const RcThemeProvider = (props: RcThemeProviderProps) => {

    const [rcTheme] = useState<IRcThemeLayout | undefined>(props.theme.rc);

    return (
        <RcThemeContext.Provider value={{rcTheme}}>
            <ThemeProvider theme={props.theme.fluentUi}>
                {props.children}
            </ThemeProvider>
        </RcThemeContext.Provider>
    );
};

export default RcThemeProvider;
