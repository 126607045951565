import React, {useContext, useEffect, useState} from "react";
import {Stack, TooltipHost} from "@fluentui/react";
import {RcFormFieldLabel} from "./RcFormFieldLabel";
import {Text} from "@fluentui/react/lib/Text";
import {IRcFormValidationRule} from "../../validations";
import {RcFormContext} from "../../providers/RcFormProvider";
import {IRcFormData} from "../../RcForm";

export interface IRcFormBaseFieldProps {
  fieldProps: IRcFormFieldProps,
  value: any,
  editModeComponent: JSX.Element,
  viewModeComponent: JSX.Element,
  showLabel?: boolean,
}

export interface IRcFormFieldProps {
  id: string,
  label: string,
  validationRules: IRcFormValidationRule[],
  mode: 'edit' | 'view' | 'all',
  tooltip: string,
  placeholder: string,
  initialValue?: any,
}

export const RcFormBaseField = (props: IRcFormBaseFieldProps): JSX.Element => {

  const [isValid, setIsValid] = useState<boolean | undefined>(undefined);
  const [isValidErrorVisible, setIsValidErrorVisible] = useState<boolean>(false);
  const [validationMessage, setValidationMessage] = useState<string | undefined>(undefined);
  const formContext = useContext(RcFormContext);

  let _isValid: boolean = false;
  let _validationMessage: string | undefined = undefined;

  const errorTextStyle = {
    color: "rgb(164, 38, 44)",
    borderColor: "#00f"
  };

  // useEffect on props.value change
  useEffect(() => {

    // check if value is valid and set corresponding state variable
    if(formContext.getValidationMode() === 'onChange') {
      setIsValidErrorVisible(true);
    }

    validateValue();
    updateFormData();

  }, [props.value])

  useEffect(() => {

    if(formContext.submitValidationGuid !== '') {
      setIsValidErrorVisible(true);
      validateValue();
    }

  }, [formContext.submitValidationGuid])

  useEffect(() => {

    validateValue();

  }, [formContext.requireValidationGuid])


  const updateFormData = () => {
    // update the state in RcDataFormContext
    const newData: IRcFormData = {
      key: props.fieldProps.id,
      value: props.value,
      isValid: _isValid,
      validationMessage: _validationMessage,
      validationRules: props.fieldProps.validationRules,
    };
    formContext.setData(newData);
  }

  const validateValue = () => {

    _isValid = true;
    _validationMessage = undefined;

    for(let rule of props.fieldProps.validationRules) {

      if(!rule.validate(props.value, formContext.getAllData())) {
        _isValid = false;
        _validationMessage = rule.message;
        break;
      } else {
        _isValid = true;
        _validationMessage = undefined;
      }
    }

    setIsValid(_isValid);
    setValidationMessage(_validationMessage);
  }

  const _showComponentByAspect = () => {
    if(formContext.getFormMode() === 'edit' && (props.fieldProps.mode === 'edit' || props.fieldProps.mode === 'all')) {

      return(
        <Stack>
          {props.fieldProps.label && (props.showLabel === true || props.showLabel === undefined) &&
          <RcFormFieldLabel id={'label_' + props.fieldProps.id} label={props.fieldProps.label} required={true} infoText={props.fieldProps.tooltip}/>
          }
          <div style={{position: 'relative'}}>
            {(isValid === false && isValidErrorVisible) &&
                <div style={{position: 'absolute', zIndex: 999, float: 'right', width: '100%', right: 8, textAlign: 'right', height: '100%', display: 'flex', pointerEvents: 'none', justifyContent: 'flex-end', alignItems: 'center'}}>
                  <TooltipHost styles={{root: {
                    background: 'rgb(217, 83, 79)',
                    padding: 4,
                    borderRadius: '50%',
                    width: 12,
                    height: 12,
                    textAlign: 'center',
                    lineHeight: '14px',
                    pointerEvents: 'all',
                    fontSize: 12}}} content={validationMessage}>
                    <Text style={{color: '#ffffff', fontWeight: 600,}}>!</Text>
                  </TooltipHost>
                </div>
            }
            {props.editModeComponent}
          </div>
          {(isValid === false && isValidErrorVisible) &&
          <Text style={errorTextStyle} variant={'small'} nowrap block>{validationMessage}</Text>
          }
        </Stack>
      )

    } else if(formContext.getFormMode() === 'view' && (props.fieldProps.mode === 'view' || props.fieldProps.mode === 'all')) {

      return (
        <Stack>
          {(props.fieldProps.label && (props.showLabel === true || props.showLabel === undefined)) &&
          <RcFormFieldLabel id={'label_' + props.fieldProps.id} label={props.fieldProps.label} infoText={props.fieldProps.tooltip} required={true}/>
          }
          {props.viewModeComponent}
        </Stack>
      )

    } else {

      return <span></span>

    }
  }

  return _showComponentByAspect();
}
