import {IRcFormValidationRule} from "./IRcFormValidationRule";
import {IRcFormData} from "../RcForm";

export class RcFormCompareValidationRule implements IRcFormValidationRule {
    message: string;
    compareTarget: (value: string, formData: IRcFormData[]) => boolean;

    constructor(message: string, compareTarget: (value: string, formData: IRcFormData[]) => boolean) {
        this.message = message;
        this.compareTarget = compareTarget;
    }

    validate(value: string, data: IRcFormData[]): boolean {
        return this.compareTarget(value, data);
    }

}
