import React from "react";
import {FontIcon, Icon, Link, Stack} from "@fluentui/react";

export interface IRcFieldItemProps {
    label: string;
    value: any;
    onValueRender?: (value: any) => JSX.Element | JSX.Element[];
    layoutMode?: 'vertical' | 'horizontal';
    iconLabelName?: string;
    iconValueName?: string;
    link?: string;
    styles?: {
      label?: React.CSSProperties,
      root?: React.CSSProperties,
      valueContainer?: React.CSSProperties,
      value?: React.CSSProperties,
      iconValue?: React.CSSProperties,
      iconLabel?: React.CSSProperties,
      labelContainer?: React.CSSProperties,
    },
    labelClassName?: string;
    rootClassName?: string;
    valueContainerClassName?: string;
    valueClassName?: string;
    iconValueClassName?: string;
    iconLabelClassName?: string;
    labelContainerClassName?: string;
}

export function RcFieldItem(props:IRcFieldItemProps){

    const subItemLabelStyle: React.CSSProperties={
        fontWeight: 900,
    }

    const iconWrapperStyle: React.CSSProperties = {
      textAlign: 'center',
      alignContent: 'center',
      display: 'flex',
    }

    return (
      <div>
      <Stack className={props.rootClassName} horizontal={props.layoutMode === 'horizontal'} style={props.styles?.root} verticalAlign={'center'} verticalFill={true}>

        <Stack horizontal verticalAlign={'center'} tokens={{childrenGap: 8}} className={props.labelContainerClassName} style={{marginBottom: props.layoutMode === 'vertical' ? 8 : 0, ...props.styles?.labelContainer}}>
          {props.iconLabelName &&
            <Stack.Item>
              <div style={iconWrapperStyle}>
                <FontIcon style={props.styles?.iconLabel} className={props.iconLabelClassName} iconName={props.iconLabelName} />
              </div>
            </Stack.Item>
          }
          <Stack.Item>
            <div style={props.styles?.label ? {...subItemLabelStyle, ...props.styles.label} : subItemLabelStyle} className={props.labelClassName}>{props.label}</div>
          </Stack.Item>
        </Stack>

        <Stack horizontal style={props.styles?.valueContainer} className={props.valueContainerClassName} verticalAlign={"center"} tokens={{childrenGap: 8}}>
            {props.iconValueName &&
              <Stack.Item>
                <div style={iconWrapperStyle}>
                  <Icon style={props.styles?.iconValue} className={props.iconValueClassName} iconName={props.iconValueName} />
                </div>
              </Stack.Item>
            }
            <Stack.Item>
                {props.link ? (
                    <Link href={props.link}>
                      <div style={props.styles?.value} className={props.valueClassName}>
                        {props.value}
                      </div>
                    </Link>
                    ) : (
                    <div style={!props.onValueRender ? props.styles?.value : undefined} className={!props.onValueRender ? props.valueClassName : undefined}>
                      {props.onValueRender ?
                        props.onValueRender(props.value) :
                        props.value
                      }
                    </div>
                )}
            </Stack.Item>
        </Stack>
      </Stack>
      </div>
    );
}

RcFieldItem.defaultProps={
  styles: undefined,
  layoutMode: 'vertical'
}
