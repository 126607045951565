import * as React from 'react';
import {useContext, useEffect, useState} from "react";
import {RcAuthContext} from "../../providers/RcAuthProvider";

export interface RcSecureFragmentProps {
  roles?: string[],
  customRule?: (user: any) => boolean;
  children: any,
}

export const RcSecureFragment = (props: RcSecureFragmentProps): JSX.Element => {

  const [hasPermission, setHasPermission] = useState<boolean>(false);
  const [canRender, setCanRender] = useState<boolean>(false);

  // @ts-ignore
  const [roles, setRoles] = useState<string[] | undefined>(undefined);
  const authContext = useContext(RcAuthContext);

  useEffect(() => {
    authContext.getUser()
      .then((res: any) => {

        if(props.customRule) {
          const customResult = props.customRule(res);
          setHasPermission(customResult);
        } else {
          const role = res.profile?.role;

          if(role) {
            setRoles(res.profile.role);

            if(props.roles?.includes(role))
              setHasPermission(true);
          }
        }

        setCanRender(true);

      });
  }, []);


  return (
    <React.Fragment>
      {canRender &&
        <React.Fragment>
          {hasPermission ?
            <React.Fragment>{props.children}</React.Fragment> :
            <React.Fragment />
          }
        </React.Fragment>
      }
    </React.Fragment>
  )
};
