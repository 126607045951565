import React, {createContext, useState} from "react";
import {GuidGenerator} from "../../../utilities/guid";
import {IRcFormData} from "../RcForm";

export type RcFormContextState = {
  setData: (data: IRcFormData) => void,
  getData: (key: string) => any;
  getAllData: () => {},
  setFormMode: (mode: 'edit' | 'view') => void,
  getFormMode: () => 'edit' | 'view',
  setFormSubmitAction: (action: (data: {}) => void) => void,
  runFormSubmitAction: () => void,
  getValidationMode: () => 'onChange' | 'onSubmit',
  setValidationMode: (mode: 'onChange' | 'onSubmit') => void,
  submitValidationGuid: string,
  requireValidationGuid: string,
  formData: IRcFormData[],
};

const contextDefaultValues: RcFormContextState = {
  setData: () => {},
  getData: () => {},
  getAllData: () => [],
  setFormMode: () => {},
  getFormMode: () => 'edit',
  setFormSubmitAction: () => {},
  runFormSubmitAction: () => {},
  setValidationMode: () => {},
  getValidationMode: () => 'onChange',
  submitValidationGuid: '',
  requireValidationGuid: '',
  formData: [],
};

export const RcFormContext = createContext<RcFormContextState>(
  contextDefaultValues
);

const RcFormProvider = ({children}: any) => {
  const [formData, setFormData] = useState<IRcFormData[]>([]);
  const [settingFormMode, setSettingFormMode] = useState<'edit' | 'view'>('edit');
  const [settingValidationMode, setSettingValidationMode] = useState<'onChange' | 'onSubmit'>('onChange');
  const [settingSubmitAction, setSettingSubmitAction] = useState<(data: {}) => void>(() => {});
  const [submitValidationGuid, setSubmitValidationGuid] = useState<string>('');
  const [requireValidationGuid, setRequireValidationGuid] = useState<string>('');

  const setData = (data: IRcFormData) => {
    const newState = formData.filter((val) => val.key !== data.key);
    newState.push(data);
    setFormData((old) => [...old.filter((val) => val.key !== data.key), data])
    setRequireValidationGuid(GuidGenerator.MakeNew());
  }

  const getData = (key: string): any => {
    formData.forEach((data) => {
      if(data.key === key)
        return data.value;
    })
  }

  const _getAllDataForResult = (): {} => {
    const d = formData;
    let returnArray: {} = {};
    d.forEach((data) => {
      returnArray[data.key] = data.value;
    })

    return returnArray;
  }

  const getAllData = (): IRcFormData[] => {
    return formData;
  }

  const getFormMode = (): 'edit' | 'view' => {
    return settingFormMode;
  }

  const setFormMode = (mode: 'edit' | 'view') => {
    setSettingFormMode(mode);
  }

  const setFormSubmitAction = (action: (data: IRcFormData[]) => void): void => {
    setSettingSubmitAction(action);
  }

  const runFormSubmitAction = () => {

    if(settingValidationMode === 'onSubmit')
      setSubmitValidationGuid(GuidGenerator.MakeNew());

    if(_checkIfFormDataAreValid()) {
      settingSubmitAction(_getAllDataForResult());
    }

  }

  const getValidationMode = (): 'onChange' | 'onSubmit' => {
    return settingValidationMode;
  }

  const setValidationMode = (mode: 'onChange' | 'onSubmit') => {
    setSettingValidationMode(mode);
  }

  const _checkIfFormDataAreValid = (): boolean => {

    for(let field of formData) {
      for(let rule of field.validationRules) {
        if(!rule.validate(field.value, formData)) {
          return false;
        }
      }
    }

    return true;
  }

  return (
    <RcFormContext.Provider
    value={{
      setData,
      getData,
      getAllData,
      setFormMode,
      getFormMode,
      setFormSubmitAction,
      runFormSubmitAction,
      getValidationMode,
      setValidationMode,
      submitValidationGuid,
      formData,
      requireValidationGuid
    }}>
    {children}
  </RcFormContext.Provider>);
};

export default RcFormProvider;
