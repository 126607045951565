import {IRcFormValidationRule} from "./IRcFormValidationRule";

export class RcFormRequiredValidationRule implements IRcFormValidationRule {

  message: string;

  constructor(message: string) {
    this.message = message;
  }

  validate(value: any): boolean {
    if(value?.constructor.name == "Array") {
      return value?.length > 0;
    }

    return value !== undefined && value !== '';
  }

}
