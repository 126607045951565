import 'reflect-metadata';
import {RcEntityMetadataTypes} from "../rcEntityMetadataTypes";
import {RcEntityFilterType} from "../rcEntityFilterType";
import {RcEntityPropertyFilterMetadata} from "../rcEntityPropertyMetadata";

export function rcPropertyFilter(filterType: RcEntityFilterType, promise?: () => Promise<any>, key?: string, text?: string, fieldName?: string) {
  return function(target: Object, propertyName: string) {

    const filterValue = new RcEntityPropertyFilterMetadata();
    filterValue.filterType = filterType;
    filterValue.fieldName = fieldName ? fieldName : propertyName;
    filterValue.key = key;
    filterValue.text = text;
    filterValue.promise = promise;

    Reflect.defineMetadata(RcEntityMetadataTypes.rcDomainEntityPropertyFilter, filterValue, target, propertyName);
  }
}
