import React, {useContext} from "react";
import {DefaultButton} from "@fluentui/react";
import {IButtonProps} from "@fluentui/react/lib/components/Button/Button.types";
import {RcFormContext} from "../../providers/RcFormProvider";

export interface IRcFormSubmitButtonProps extends IButtonProps {

}

export const RcFormSubmitButton = (props: IRcFormSubmitButtonProps): JSX.Element => {

  const formContext = useContext(RcFormContext);

  return(
    <DefaultButton {...props} onClick={formContext.runFormSubmitAction}>
      {props.children}
    </DefaultButton>
  )
}
