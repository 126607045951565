import React, {useContext, useEffect, useState} from "react";
import RcFormProvider, {RcFormContext} from "./providers/RcFormProvider";
import {IRcFormValidationRule} from "./validations/IRcFormValidationRule";

export interface IRcFormData {
  key: string,
  value: any | undefined,
  isValid: boolean,
  validationMessage: string | undefined,
  validationRules: IRcFormValidationRule[]
}

export interface IRcDataFormProps {
  mode: 'edit' | 'view',
  onSubmit: (data: any) => void,
  validationMode: 'onChange' | 'onSubmit'
  validationSummary: 'bottom' | 'top' | 'none',
  onDataChange: () => void,
  children: any,
}

export const RcForm = (props: IRcDataFormProps): JSX.Element => {
  return(
    <RcFormProvider>
      <RcDataFormComponent {...props} />
    </RcFormProvider>
  )
}

const RcDataFormComponent = (props: IRcDataFormProps): JSX.Element => {

  const formProvider = useContext(RcFormContext);
  const [contextLoaded, setContextLoaded] = useState<boolean>(false);

  useEffect(() => {
    formProvider.setFormMode(props.mode);
    formProvider.setFormSubmitAction(props.onSubmit);
    formProvider.setValidationMode(props.validationMode);
    setContextLoaded(true);
  },[props.mode, props.onSubmit, props.validationMode])

  //           <Link onClick={() => formProvider.setFormMode('edit')}>Modifica</Link>
  return(
    <div>
      {contextLoaded &&
        <div>
          {props.children}
        </div>
      }
    </div>
  )
}
