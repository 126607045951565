import React, {useEffect, useState} from "react";
import {
  DefaultButton, Dropdown, IComboBoxOption, IDropdownOption,
  PrimaryButton,
  TextField
} from "@fluentui/react";
import {Stack} from "@fluentui/react";
import {IRcDataGridFilterBaseProps} from "./RcDataGridFilterSelect";
import {TextFieldFilter} from "../../models/textFieldFilter";
import {StringConditionOperandTypes} from "../../../../utilities/query-builder/models/StringConditionOperandTypes";

export interface IRcDataGridFilterTextProps extends IRcDataGridFilterBaseProps<TextFieldFilter> {

}

const conditionOptions: IComboBoxOption[] = [
  {key: StringConditionOperandTypes.contains,     text: 'Contiene'},
  {key: StringConditionOperandTypes.notcontains,  text: 'Non contiene'},
  {key: StringConditionOperandTypes.startswith,   text: 'Iniza con'},
  {key: StringConditionOperandTypes.endswith,     text: 'Finisce con'},
  {key: StringConditionOperandTypes.equal,        text: 'Uguale a'},
  {key: StringConditionOperandTypes.notequal,     text: 'Diverso da'}
];

export const RcDataGridFilterText = (props: IRcDataGridFilterTextProps): JSX.Element => {

  const [currentFilter, setCurrentFilter] = useState<TextFieldFilter | undefined>(undefined);
  const [textValue, setTextValue] = useState<string>('');
  const [condition, setCondition] = useState<string>('');
  const [firstMountDone, setFirstMountDone] = useState<boolean>(false);

  useEffect(() => {
    if(firstMountDone) {
      if (currentFilter)
        props.onFilterChanged(props.propertyKey, currentFilter);
      else {
        props.onFilterChanged(props.propertyKey, undefined);
      }
    }
  }, [currentFilter, firstMountDone])

  useEffect(() => {
    if(props.initialValue){
      setCondition(props.initialValue["condition"]);
      setTextValue(props.initialValue["value"]);
      setCurrentFilter(props.initialValue);
    } else if (condition === '') {
      setCondition(StringConditionOperandTypes.contains)
    }
  }, [])

  const onFilterSubmitHandler = () => {

    const filter = new TextFieldFilter(props.propertyKey, props.propertyDisplayName, condition, textValue);

    setCurrentFilter(filter);

    if(!firstMountDone)
      setFirstMountDone(true);
  }

  const onResetFilterHandler = () => {
    setTextValue('');
    setCondition('');
    setCurrentFilter(undefined);

    if(!firstMountDone)
      setFirstMountDone(true);
  }

  const onConditionChanged = (_event: React.FormEvent<HTMLDivElement>, option?: (IDropdownOption<any> | undefined), _index?: (number | undefined)) => {
    if(option)
      setCondition(option.key.toString())
  }

  const onTextValueChanged = (_event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: (string | undefined)) => {
    setTextValue(newValue || '')
  }

  return (
    <Stack tokens={{childrenGap: 10}}>
      <Dropdown label={'Condizione'} placeholder={'Seleziona una condizione...'} required options={conditionOptions} onChange={onConditionChanged} selectedKey={condition} />

      <TextField autoComplete={'off'} required label={"Valore"} placeholder={''} value={textValue} onChange={onTextValueChanged}/>

      <PrimaryButton default={true} onClick={onFilterSubmitHandler} disabled={!condition || !textValue} >Applica</PrimaryButton>
      {currentFilter !== undefined &&
        <DefaultButton onClick={onResetFilterHandler}>Reset</DefaultButton>
      }
    </Stack>
  )
}
