import React, {createContext, ReactElement, useEffect, useState} from "react";
import {KeyedCollection} from "../../../utilities/keyedCollection";
import {CompositeOperatorStrategy} from "../../../utilities/query-builder/operator/CompositeOperatorStrategy";
import {IFieldOperator} from "../../../utilities/query-builder/models/IFieldOperator";
import {SortingData} from "../models/sortingData";
import {PaginationData} from "../models/paginationData";

export type RcDataGridContextState = {
  filters: KeyedCollection<any>,
  filterString: string | undefined,
  sorting: SortingData[],
  pagination: PaginationData,
  getFilters: () => KeyedCollection<any>,
  addOrUpdateFilter: (propertyKey: string, filterValue: any) => void,
  addOrUpdateSorting: (sortingValue: SortingData[]) => void,
  deleteFilter: (propertyKey: string, filterValue: any) => void,
  updatePagination: (limit: number, page: number) => void,
  getQueryString: () => string,
};

export const RcDataGridContext = createContext<RcDataGridContextState>({
  sorting: [],
  pagination: {limit: 0, page: 0},
  filters: new KeyedCollection<any>(),
  filterString: undefined,
  getQueryString: () => '',
  getFilters: () => new KeyedCollection<any>(),
  addOrUpdateFilter: () => {},
  addOrUpdateSorting: () => {},
  deleteFilter: () => {},
  updatePagination: () => {},
});

export interface RcDataGridProviderProps {
  children?: ReactElement;
}

const RcDataGridProvider = (props: RcDataGridProviderProps) => {

  const [filters, setFilters] = useState<KeyedCollection<any>>(new KeyedCollection<any>());
  const [filterString, setFilterString] = useState<string | undefined>(undefined);
  const [sorting, setSorting] = useState<SortingData[]>([]);
  const [pagination, setPagination] = useState<PaginationData>(new PaginationData(50,1));

  //const [persistentConfiguration, setPersistentConfiguration] = useLocalStorage<any>("gridTestConf", {});

  useEffect(() => {
    return;
    //if(persistentConfiguration.limit)
    //  updatePagination(persistentConfiguration.limit, 1)
  }, [])

  const addOrUpdateSorting = (sortingValue: SortingData[]) => {
    setSorting(sortingValue);
  }

  const addOrUpdateFilter = (propertyKey: string, filterValue: any) => {
    const tmpFilters = new KeyedCollection<any>();

    // Copio i filtri in un nuovo oggetto altrimenti il cambio di stato non viene innestato
    filters.Keys().forEach((key) => {
      tmpFilters.Add(key, filters.Item(key));
    })

    // Se il valore del filtro è vuoto, undefined o in caso di array la lunghezza e 0, procedo con la rimozione del filtro, altrimenti effettuo
    // un aggiornamento o un'aggiunta
    if((Array.isArray(filterValue) && filterValue.length === 0) || filterValue === '' || filterValue === undefined || filterValue === null) {
      if(tmpFilters.ContainsKey(propertyKey)) {
        tmpFilters.Remove(propertyKey);
      }
    } else {
      if(tmpFilters.ContainsKey(propertyKey)) {
        tmpFilters.Add(propertyKey, filterValue);
      } else {
        tmpFilters.Add(propertyKey, filterValue);
      }
    }

    setFilters(tmpFilters);

    const aaa: IFieldOperator = {
      operator: 'and',
      operands: tmpFilters.Values()
    }

    const comp = new CompositeOperatorStrategy(aaa);
    console.log(comp.stringify());

    if(tmpFilters.Count() === 0)
      setFilterString(undefined);
    else
      setFilterString(comp.stringify());
  }

  const deleteFilter = (propertyKey: string, filterValue: any) => {
    const tmpFilters = filters;
    if(tmpFilters.ContainsKey(propertyKey)) {
      //@ts-ignore
      tmpFilters[propertyKey].push(filterValue);
    } else {
      tmpFilters.Add(propertyKey, filterValue);
    }
    setFilters(new KeyedCollection());
  }

  const getFilters = (): KeyedCollection<any> => {
    return filters;
  }

  const updatePagination = (limit: number, page: number) => {
    setPagination(new PaginationData(limit, page));
  }

  const getQueryString = (): string => {

    let q = '';
    if(filters.Count() > 0)
      q += 'filter=' + filterString;

    return encodeURI(q);
  }

  return (
    <RcDataGridContext.Provider value={{
      pagination,
      sorting,
      filters,
      filterString,
      getQueryString,
      getFilters,
      addOrUpdateSorting,
      addOrUpdateFilter,
      deleteFilter,
      updatePagination,
    }}>
      {props.children}
    </RcDataGridContext.Provider>
  );
};

export default RcDataGridProvider;
