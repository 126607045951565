import {IRcPaginatedResult} from "../../models/IRcPaginatedResult";
import {AxiosInstance} from "axios";

export class CrudService<T> {

    constructor(axiosHttpClient: AxiosInstance, resourcePath: string) {
      this.resourcePath = resourcePath;
      this.axiosHttpClientInstance = axiosHttpClient;
    }

    resourcePath: string;
    axiosHttpClientInstance: AxiosInstance;

    public get(id: number): Promise<T> {
        return this.axiosHttpClientInstance.get(`${this.resourcePath}/${id}`);
    }

    public find = async (take: number, skip: number, filters?: string | undefined): Promise<IRcPaginatedResult<T> | T[]> => {
        let url = `${this.resourcePath}/?take=${take}&skip=${skip}&orderBy=id`;

        if (filters)
            url = url + `&filter=${filters}`;

        const a = await this.axiosHttpClientInstance.get(url);

        return a.data;
    }

    create = (payload: T): Promise<any> => {
        return this.axiosHttpClientInstance.post(`${this.resourcePath}`, payload);
    }

    update = (entityId: number | string, payload: T): Promise<any> => {
        return this.axiosHttpClientInstance.put(`${this.resourcePath}/${entityId}`, payload);
    }

    delete = (entityId: number | string): Promise<any> => {
        return this.axiosHttpClientInstance.delete(`${this.resourcePath}/${entityId}`);
    }
}
