import {RcEntityFilterType} from "./rcEntityFilterType";

export class RcEntityPropertyMetadata {
  isDescriber: boolean = true;
  displayName: string = '';
  key: string = '';
  filter?: RcEntityPropertyFilterMetadata;
  isKey: boolean = false;
  complexType: { new(): any ;} | null = null;
  complexTypeDisplayValue: ((item: any) => string) | null;

  constructor() {}
}

export class RcEntityPropertyFilterMetadata {
  filterType: RcEntityFilterType = RcEntityFilterType.text;
  fieldName: string = '';
  key?: string;
  text?: string;
  promise?: () => Promise<any>

  constructor() {}
}
