import React, {SyntheticEvent, useEffect, useRef, useState} from "react";
import {
  FocusZone,
  getFocusStyle,
  getTheme, IColumn,
  IconButton,
  Link,
  List,
  mergeStyleSets,
  Panel,
  PanelType,
  Spinner,
  Stack,
  Text,
  TextField
} from '@fluentui/react';
import {IRcFormFieldProps, RcFormBaseField} from "./RcFormBaseField";
import {RcTagItem} from "../../../RcTagItem/RcTagItem";
import {generateUniqueID} from "web-vitals/dist/modules/lib/generateUniqueID";
import {useBoolean} from "@fluentui/react-hooks";
import {IRcDataGridColumn, IRcDataGridProps, RcDataGrid} from "../../../RcDataGrid";
import Timeout = NodeJS.Timeout;

export interface IRcFormLookupFieldProps extends IRcFormFieldProps {
  onSearchQueryChanged?: (q: string) => void,
  onItemSelected?: (item: any) => void,
  onRenderItem?: (item: any, index: number, isScrolling: boolean) => React.ReactNode,
  items: any[],
  fieldValue: string,
  fieldDisplayValue: string,
  dataLoading: boolean,
  emptyResultText: string,
  multiselect: boolean,
  searchDelay: number,
  minCharactersForSearch: number,
  maxListHeight: number,
  advancedSearchGridProps?: IRcDataGridProps,
}



export const RcFormLookupField = (props: IRcFormLookupFieldProps): JSX.Element => {
  const theme = getTheme();

  const styles: any = mergeStyleSets({
    root: {
      width: "100%",
      position: "relative",
    },
    itemCell: [
      getFocusStyle(theme, { inset: -1 }),
      {
        alignItems: 'center',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        fontSize: 14,
        paddingBottom: 5,
        cursor: 'pointer',
        selectors: {
          '&:hover': { background: theme.palette.neutralLight },
          '&:focus': { background: theme.palette.neutralLight },
        },
      },
    ],
    searchSummaryContainer: {
      boxShadow: "0 25.6px 57.6px 0 rgb(0 0 0 / 22%), 0 4.8px 14.4px 0 rgb(0 0 0 / 18%)",
      borderRadius: 2,
      //width: mainSearchComponentRef!.current!.offsetWidth,
      border: "1px solid rgb(204, 204, 204)",
      backgroundColor: "rgb(255, 255, 255)",
      zIndex: 9999,
      position: "absolute",
      //top: 45,
      margin: 0,
      //padding: '0 16px',
      maxHeight: 375,
      overflowY: "auto",
    },
    searchSummaryHeader: {
      padding: "0 10px",
      borderBottom: "1px solid #ccc",
    },
    searchSummary: {
      width: "100%",
      backgroundColor: "rgb(255, 255, 255)",
      fontSize: "0.9rem",
      zIndex: 3,
      top: 45,
      margin: 0,
      padding: 0,
      listStyle: "none",
      overflowY: "auto",
    },
    itemContent: {
      marginLeft: 10,
      overflow: 'hidden',
      flexGrow: 1,
      display: 'flex',
      alignItems: 'center',
    },
    searchCategoryLabel: {
      padding: '8px 0',
      fontWeight: 900,
      height: 24,
      display: 'flex',
      flexWrap: 'nowrap',
      alignItems: 'center',
    },
    searchItem: {
      ':hover': {
        background: '#edebe8'
      },
      height: 'auto',
      padding: '0px 8px',
      cursor: 'pointer',
      minHeight: 36,
      lineHeight: 20,
      overflowWrap: 'break-word',
      userSelect: 'none',
      display: 'flex',
      alignItems: 'center'
    },
    searchItemInner: {
      display: 'flex',
      height: '100%',
      flexWrap: 'nowrap',
      justifyContent: 'flex-start',
      alignItems: 'center'
    },
    searchLoader: {
      background: '#fff',
      position: 'absolute',
      zIndex: 9999,
      right: 8,
      top: 6,
    },
    clearItemButton: {
      position: 'absolute',
      zIndex: 8888,
      right: 10,
      top: 0,
    }
  });
  const suggestionStyle = {
    title: {
      padding: '0px 12px',
      fontSize: 12,
      color: 'rgb(0, 120, 212)',
      lineHeight: '40px',
      borderBottom: '1px solid rgb(237, 235, 233)',
    },
    footer: {
      padding: '0px 12px',
      fontSize: 12,
      lineHeight: '40px',
      borderTop: '1px solid rgb(237, 235, 233)',
    }
  };

  const [value, setValue] = useState<string | any[] | undefined>(undefined);
  const [textValue, setTextValue] = useState<string | undefined>(undefined);
  const [isSuggestionOpened, setIsSuggestionOpened] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<any>(undefined);
  // @ts-ignore
  const [selectedItems, setSelectedItems] = useState<any>([]);
  const mainSearchComponentRef = useRef<HTMLDivElement>(null);
  const mainMultipleSearchComponentRef = useRef<HTMLDivElement>(null);
  const searchComponentRef = useRef<HTMLDivElement>(null);
  const [disableTextField, setDisableTextField] = useState<boolean>(false);

  const [advancedSearchVisible, {toggle: toggleAdvancedSearchVisible}] = useBoolean(false);
  const [items, setItems] = useState<any[]>(props.items);

  const [advancedSearchGridColumn, setAdvancedSearchGridColumn] = useState<IRcDataGridColumn[]>();

  let timeOutId: Timeout;

  useEffect(() => {
    document.addEventListener("keydown", handleEscKeyPressed, true);
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener("keydown", handleEscKeyPressed, true);
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  useEffect(() => {
    if(props.initialValue !== undefined && !props.multiselect) {
      setValue(props.initialValue[props.fieldValue]);
      setSelectedItem(props.initialValue)
    }
    if(props.initialValue !== undefined && props.multiselect) {

      const values: any[] = [];

      selectedItems.forEach((element: any) => {
        values.push(element[props.fieldValue]);
      })

      setValue(values);
      setSelectedItems(props.initialValue)
    }
  }, []);

  useEffect(() => {
    if(props.advancedSearchGridProps) {
      const col = [...props.advancedSearchGridProps.columns];

      col.push({
        key: 'action',
        fieldName: 'action',
        name: "Azioni",
        minWidth: 100,
        maxWidth: 100,
        visible: true,
        onRender: onSelectItemActionRender
      });

      setAdvancedSearchGridColumn(col);
    }
  }, [selectedItems])

  const onSelectItemActionRender = React.useCallback((item?: any, _index?: (number | undefined), _column?: (IColumn | undefined)) => {
    return (
      <Link onClick={() => onItemSelected(item, true)}>Seleziona</Link>
    )
  }, [selectedItems]);

  useEffect(() => {
    setItems(props.items)
    if(props.items && textValue)
      setIsSuggestionOpened(true);
    else
      setIsSuggestionOpened(false);
  }, [props.items])

  useEffect(() => {
    if(props.multiselect)
      return;

    if(selectedItem) {
      setDisableTextField(true)
      setTextValue(selectedItem[props.fieldDisplayValue]);
      setValue(selectedItem[props.fieldValue]);
    }
    else {
      setDisableTextField(false)
      setTextValue('')
    }
  }, [selectedItem])

  useEffect(() => {
    if(!props.multiselect)
      return;

    if(selectedItems) {

      const values: any[] = [];

      selectedItems.forEach((element: any) => {
        values.push(element[props.fieldValue]);
      })

      setValue(values);
    }
    else {
      setDisableTextField(false)
      setTextValue('')
    }
  }, [selectedItems])

  useEffect(() => {
    if(!isSuggestionOpened)
      setItems([])
  }, [isSuggestionOpened])

  const handleEscKeyPressed = (event: any) => {
    if(searchComponentRef.current && event.keyCode === 27) {
      setIsSuggestionOpened(false)
    }
  }

  const handleClickOutside = (event: any) => {
    if (searchComponentRef.current && !searchComponentRef.current.contains(event.target)) {
      setIsSuggestionOpened(false);
    }
  };

  const doDelayedSearch = (q: string) => {
    if(q && q.length < props.minCharactersForSearch)
      return;

    if (timeOutId) {
      clearTimeout(timeOutId);
    }
    timeOutId = setTimeout(function() {
      if (props.onSearchQueryChanged)
        props.onSearchQueryChanged(q);
    }, props.searchDelay);
  }

  const onChangeValue = React.useCallback(
    (_event: SyntheticEvent<HTMLElement, Event>, _newValue?: string | undefined) => {
      setTextValue(_newValue);

      if(_newValue && props.onSearchQueryChanged) {
        doDelayedSearch(_newValue);
      }

      if(_newValue === undefined || _newValue === '')
        setIsSuggestionOpened(false);
    }, []);

  const onTextFieldFocus = React.useCallback(
    (event) => {
      event.preventDefault();
      mainSearchComponentRef?.current?.focus();
      setSelectedItem(undefined);
    }, []);

  const onTextFieldBlur = React.useCallback(
    (event) => {
      event.preventDefault();
      if(!selectedItem)
        setTextValue('')
    }, []);

  const onRenderItem = (item: any, _index: number, _isScrolling: boolean): JSX.Element => {

    if(props.onRenderItem) {
      return <div id={'rc-lookup-wrapper'} onClick={() => onItemSelected(item)}>{props.onRenderItem(item, _index, _isScrolling)}</div>
    }

    let toShow = "";
    if(props.fieldDisplayValue)
      toShow = item[props.fieldDisplayValue];
    else
      toShow = item['name'];

    return (
      <div id={'rc-lookup-wrapper'} onClick={() => onItemSelected(item)} className={styles.searchItem}>
        <span className={styles.searchItemInner}>{toShow}</span>
      </div>
    )
  }

  const onItemSelected = (item: any, fromAdvancedSearch: boolean = false): void => {

    if(!props.multiselect) {
      setSelectedItem(item);
      setIsSuggestionOpened(false);
      if(fromAdvancedSearch)
        toggleAdvancedSearchVisible();
    } else {
      const itemAlreadySelected = selectedItems.findIndex((a: any) => a[props.fieldValue] === item[props.fieldValue]);
      if(itemAlreadySelected === -1)
        setSelectedItems((old: any) => [...old, item]);

      setIsSuggestionOpened(false);

      if(fromAdvancedSearch)
        toggleAdvancedSearchVisible();
      mainSearchComponentRef?.current?.focus();
    }
  }

  const deleteTagItem = (value: string | number) => {
    const tag = selectedItems.filter((a: any) => a[props.fieldValue] !== value);
    setSelectedItems(tag);
  }



  function generateEditComponent(multiselect: boolean) {
    if(!multiselect) {
      return(
        <div>
          {(props.dataLoading) &&
            <Spinner className={styles.searchLoader} />
          }
          {disableTextField &&
            <IconButton iconProps={{iconName: 'Cancel'}} onClick={() => {setSelectedItem(undefined); setValue(undefined)}} className={styles.clearItemButton} />
          }
          {(props.advancedSearchGridProps && !props.dataLoading && !disableTextField) &&
            <IconButton onClick={() => {setIsSuggestionOpened(false); toggleAdvancedSearchVisible()}} style={{background: 'transparent'}} iconProps={{iconName: 'SearchAndApps'}} className={styles.clearItemButton} />
          }
          <TextField autoComplete={'off'} id={props.id} key={props.id} disabled={disableTextField} value={textValue} placeholder={props.placeholder} onChange={onChangeValue} elementRef={mainSearchComponentRef} onFocus={onTextFieldFocus} onBlur={onTextFieldBlur} />
        </div>
      )
    } else {
      return(
        <div ref={mainMultipleSearchComponentRef}>
          {props.multiselect &&
          <div>
            {(props.dataLoading) &&
            <Spinner className={styles.searchLoader} />
            }
            {(props.advancedSearchGridProps && !props.dataLoading) &&
              <IconButton onClick={() => {setIsSuggestionOpened(false); toggleAdvancedSearchVisible()}} style={{background: 'transparent', marginTop: 4}} iconProps={{iconName: 'SearchAndApps'}} className={styles.clearItemButton} />
            }
            <Stack styles={{root: {border: '1px solid black', padding: 5}}} horizontal tokens={{childrenGap: 8}} style={{background: '#fff'}} wrap>
              {(selectedItems && selectedItems.length > 0) &&
              selectedItems?.map((element: any) => {
                return <RcTagItem key={'rc-tag-element-' + generateUniqueID().toString()} label={element[props.fieldDisplayValue]} value={element[props.fieldValue]} deleteAction={deleteTagItem} />
              })}
              <Stack.Item grow styles={{root: {margin: 0, height: 30}}}>
                <TextField borderless={true} styles={{root: {width: '100%', minWidth: '50px', margin: 0}, fieldGroup: {height: 30}}} autoComplete={'off'} id={props.id} key={props.id} value={textValue} placeholder={props.placeholder} onChange={onChangeValue} onFocus={onTextFieldFocus} onBlur={onTextFieldBlur} />
              </Stack.Item>
            </Stack>
          </div>
          }
        </div>
      )
    }
  }

  const ViewComponent = <Text>{textValue}</Text>

  return(
    <div id={'rc-lookup-field-' + generateUniqueID().toString()}>
      <RcFormBaseField key={props.id} fieldProps={props} value={value} editModeComponent={generateEditComponent(props.multiselect)} viewModeComponent={ViewComponent} />
      {(isSuggestionOpened) &&
      <FocusZone style={{background: '#fff', borderRadius: 2}}>
        <div className={styles.searchSummaryContainer} style={{width: props.multiselect ? mainMultipleSearchComponentRef!.current!.offsetWidth : mainSearchComponentRef!.current!.offsetWidth, height: 'auto', overflow: 'hidden'}} ref={searchComponentRef} >
          {(items && items.length > 0) &&
            <div style={{maxHeight: props.maxListHeight, display: 'block', overflow: 'scroll'}}>
              <List items={items} onRenderCell={onRenderItem}  />
            </div>
          }
          {((!items || items.length === 0)) &&
            <Text styles={{root: {height: 48, lineHeight:48, width: '100%', textAlign: 'center', display: 'block'}}}>{props.emptyResultText}</Text>
          }
          {props.advancedSearchGridProps &&
          <div style={suggestionStyle.footer}>
            <Link onClick={() => {setIsSuggestionOpened(false); toggleAdvancedSearchVisible()}}>Ricerca avanzata</Link>
          </div>
          }
        </div>
      </FocusZone>
      }
      {(props.advancedSearchGridProps) &&
        <Panel type={PanelType.large}
               headerText={'Ricerca avanzata - ' + props.label}
               isOpen={advancedSearchVisible}
               onDismiss={toggleAdvancedSearchVisible}
               hasCloseButton
               closeButtonAriaLabel="Close">
          <div style={{marginTop: 32}}>
            {advancedSearchVisible &&
              <RcDataGrid {...props.advancedSearchGridProps} id={props.advancedSearchGridProps.id + generateUniqueID()} columns={advancedSearchGridColumn} itemSelectionMode={'none'} requireOnDataChangeOnFirstLoad={true} onItemInvoked={(item, _index, _ev) => onItemSelected(item, true)} />
            }
          </div>
        </Panel>
      }
    </div>
  )
}

RcFormLookupField.defaultProps = {
  fieldValue: 'id',
  fieldDisplayValue: 'name',
  dataLoading: false,
  emptyResultText: 'No result found',
  multiselect: false,
  searchDelay: 500,
  minCharactersForSearch: 3,
  minCharactersForSearchMessage: 'Digita almeno %s caratteri...',
  maxListHeight: 150
};



