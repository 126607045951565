import 'reflect-metadata';
import {RcEntityMetadataTypes} from "../rcEntityMetadataTypes";

/**
 * Define RCEntity
 */
export function rcEntity<T extends { new (...args: any[]): {} }>() {
  return function(constructor: T) {
    Reflect.defineMetadata(RcEntityMetadataTypes.rcDomainEntity, true, constructor);
    /*return class extends constructor {
        promise = promise;
    };*/
  }
}
