import {RcEntityMetadata} from "./rcEntityMetadata";
import {RcEntityMetadataTypes} from "./rcEntityMetadataTypes";
import {RcEntityPropertyMetadata} from "./rcEntityPropertyMetadata";

export class RcEntityDescriptor {

  static Describe<T>(type: { new(): T ;}) : RcEntityMetadata | null {

    const isRcEntity = this.getClassMetadata(type, RcEntityMetadataTypes.rcDomainEntity);

    if(isRcEntity) {
      const properties = Object.getOwnPropertyNames(new type())

      const entityMetadata = new RcEntityMetadata();
      const entityMetadataProps: RcEntityPropertyMetadata[] = [];

      properties.forEach((prop) => {
        const entityPropertyMetadata = new RcEntityPropertyMetadata();
        if(RcEntityDescriptor.getPropertyMetadata(type,prop, RcEntityMetadataTypes.rcDomainEntityProperty)) {

          const displayName = RcEntityDescriptor.getPropertyMetadata(type,prop, RcEntityMetadataTypes.rcDomainEntityPropertyDisplayName)

          entityPropertyMetadata.displayName = displayName;
          entityPropertyMetadata.key = prop;
          entityPropertyMetadata.isDescriber = true;
          entityPropertyMetadata.filter = RcEntityDescriptor.getPropertyMetadata(type, prop, RcEntityMetadataTypes.rcDomainEntityPropertyFilter);
          entityPropertyMetadata.isKey = RcEntityDescriptor.getPropertyMetadata(type, prop, RcEntityMetadataTypes.rcDomainEntityPropertyKey);
          entityPropertyMetadata.complexType = RcEntityDescriptor.getPropertyMetadata(type, prop, RcEntityMetadataTypes.rcDomainEntityPropertyComplexType);
          entityPropertyMetadata.complexTypeDisplayValue = RcEntityDescriptor.getPropertyMetadata(type, prop, RcEntityMetadataTypes.rcDomainEntityPropertyComplexTypeDisplayValue);

          entityMetadataProps.push(entityPropertyMetadata);

        } else {

          entityMetadataProps.push({isDescriber: false, key: prop, displayName: prop, isKey: false, complexType: null, complexTypeDisplayValue: null});

        }
      });

      entityMetadata.properties = entityMetadataProps;

      return entityMetadata;
    }

    return null;
  }

  private static getClassMetadata<T>(type:  { new(): T ;}, metadataType: RcEntityMetadataTypes): any {
    return Reflect.getMetadata(metadataType, type);
  }

  private static getPropertyMetadata<T>(type:  { new(): T ;}, propertyKey: string, metadataType: RcEntityMetadataTypes): any {
    const instance = new type();
    return Reflect.getMetadata(metadataType, instance, propertyKey);
  }
}
