import {IRcRoute} from "../components/RcRoute/IRcRoute";
// @ts-ignore
import {RcApplicationRoute} from "../common/RcApplicationRoute";
import {IRcApplicationRouteIndex} from "../common/IRcApplicationRouteIndex";
import {IRcSidebarItem} from "../components/RcSidebar/RcSidebarItem";
import {RcApplicationMenu} from "../common/RcApplicationMenu";


export function generateIndexableCommand(sidebarCommand: RcApplicationMenu[], parentPath: string = '', parentTitle: string = ''): IRcApplicationRouteIndex[] {

  const routes : IRcApplicationRouteIndex[] = [];

  sidebarCommand.filter(a => !a.isHeader).forEach((command) => {
    const route: IRcApplicationRouteIndex = {
      id: command.id,
      routerLink: parentPath != '' ? parentPath + command.routerLink : command.routerLink,
      hasIndex: command.hasIndex,
      icon: command.icon,
      title: parentTitle != '' ? parentTitle + ' > ' + command.title : command.title,
    }

    routes.push(route);

    if (command.children)
      routes.push(...generateIndexableCommand(command.children, route.routerLink, route.title));
  })

  return routes;
}

/*export function generateRoutesFromSidebarCommands(sidebarCommand: RcApplicationRoute[], parentPath: string = '', parentTitle: string = ''): IRcRoute[] {

    const routes : IRcRoute[] = [];

    sidebarCommand.forEach((command) => {
        const route : IRcRoute = {
            key: command.id,
            breadcrumb: parentTitle != '' ? parentTitle + '>' + command.title : command.title,
            path: parentPath != '' ? parentPath + command.routerLink : command.routerLink,
            component: command.component,
            layout: command.layout,
            isPublic: command.isPublic
        }

        routes.push(route);

        if(command.children)
            routes.push(...generateRoutesFromSidebarCommands(command.children, route.path, route.breadcrumb));
    })

    return routes;
}*/

export function generateRoutesFromSidebarCommands(sidebarCommand: RcApplicationMenu[], parentPath: string = '', parentTitle: string = ''): IRcRoute[] {

  const routes : IRcRoute[] = [];

  sidebarCommand.forEach((command) => {

    const route : IRcRoute = {
      key: command.id,
      breadcrumb: parentTitle != '' ? parentTitle + '>' + command.title : command.title,
      path: parentPath != '' ? parentPath + command.routerLink : command.routerLink,
      component: command.component,
      layout: command.layout,
      isPublic: command.isPublic,
      enableRoute: command.enableRoute,
      visible: command.visible,
      isHeader: command.isHeader,
    };

    routes.push(route);

    if(command.children)
      routes.push(...generateRoutesFromSidebarCommands(command.children, route.path, route.breadcrumb));
  })

  return routes;
}

export function generateSidebarItemsFromSidebarCommands(sidebarCommand: RcApplicationMenu[], parentPath: string = '', isChildren: boolean = false): IRcSidebarItem[] {

    const items : IRcSidebarItem[] = [];

    sidebarCommand.forEach((command) => {
        const item : IRcSidebarItem = {
            id: command.id,
            routerLink: parentPath != '' ? parentPath + command.routerLink : command.routerLink,
            icon: command.icon,
            isMinimal: false,
            title: command.title,
            isChildren: isChildren,
            isHeader: command.isHeader ? command.isHeader : false,
            visible: command.visible,
        }

        if(command.children) {
            item.children = []
            item.children.push(...generateSidebarItemsFromSidebarCommands(command.children, item.routerLink, true));
        }

        items.push(item);
    })

    return items;
}
