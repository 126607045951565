import {
    CommandBarButton,
    getTheme,
    Image,
    ITheme,
    mergeStyles,
    Stack,
    Text
} from "@fluentui/react";
import React, {useContext, useEffect, useState} from "react";
import {IRcSearchBarCustomSearchResult, RcSearchBar} from "./components/RcSearchBar";
import {IRcApplicationRouteIndex} from "../../common/IRcApplicationRouteIndex";
import {RcAuthContext} from "../../providers/RcAuthProvider";
import {RcThemeContext} from "../../providers/RcThemeProvider";
import {RcLayoutContext} from "../../providers/RcLayoutProvider";
import {RcUserInfo} from "../RcUserInfo/RcUserInfo";
import {AuthInterface} from "../../services/auth/auth.interface";

export interface RcHeaderBarProps {
  indexableCommands: IRcApplicationRouteIndex[]
  searchbarCustomSearches?: ((q: string) => Promise<IRcSearchBarCustomSearchResult>)[] | undefined;
}

export const RcHeaderBar = (props: RcHeaderBarProps): JSX.Element => {

    const themeContext = useContext(RcThemeContext);
    const layoutContext = useContext(RcLayoutContext);
    const authContext = useContext<AuthInterface>(RcAuthContext);
    const [user, setUser] = useState<any | null>(null);

    const theme: ITheme = getTheme();

    function adjust(color: string, amount: number) {
      return '#' + color.replace(/^#/, '').replace(/../g, color => ('0'+Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(16)).substr(-2));
    }

    const getStyles : any = {
        root: {
            borderBottomStyle: 'solid',
            borderBottomColor: theme.semanticColors.bodyFrameDivider,
            borderBottomWidth: 1,
            // Rocket
            position: "fixed",
            width: "100%",
            top: 0,
            right: 0,
            height: 48,
            transition: ".3s left",
            zIndex: 999,
            background: themeContext?.rcTheme?.headerBar?.backgroundColor,
            //boxShadow: "0 6.4px 14.4px 0 rgb(0 0 0 / 13%), 0 1.2px 3.6px 0 rgb(0 0 0 / 11%)",
        },
        logo: {
            'img': {
                maxHeight: 32,
                //position: "absolute",
                top: 0,
                bottom: 0,
                margin: "auto",
            },
            height: "100%",
        },
        menuSidebarButton: {
          background: themeContext?.rcTheme?.headerBar?.backgroundColor, // themeContext?.rcTheme?.sideBar?.commandButtonBackgroundColor,
          height: "100%",
          width: "48px",
          //boxShadow: "0 6.4px 14.4px 0 rgb(0 0 0 / 13%), 0 1.2px 3.6px 0 rgb(0 0 0 / 11%)",
          i: {
            fontSize: "24px",
            color: '#ffffff',
          },
          ':hover': {
            backgroundColor: adjust(themeContext?.rcTheme?.headerBar?.backgroundColor!, -20),// themeContext?.rcTheme?.sideBar?.commandButtonHoverColor,
            color: '#ffffff',

            i: {
              color: '#ffffff',
            }
          },
          ':active': {
            backgroundColor: adjust(themeContext?.rcTheme?.headerBar?.backgroundColor!, -20),
            color: '#ffffff',

            i: {
              color: '#ffffff',
            }
          },
        },
        menuButton: {
            background: themeContext?.rcTheme?.headerBar?.backgroundColor,
            height: "100%",
            width: "48px",
            i: {
                fontSize: "24px",
                color: '#ffffff',
            },
            ':hover': {
                backgroundColor: adjust(themeContext?.rcTheme?.headerBar?.backgroundColor!, -20),
                color: '#ffffff',

                i: {
                    color: '#ffffff',
                }
            },
            ':active': {
                backgroundColor: adjust(themeContext?.rcTheme?.headerBar?.backgroundColor!, -20),
                color: '#ffffff',

                i: {
                    color: '#ffffff',
                }
            },
        },
        menuButtonIcon: {
            fontSize: "24px",
            color: '#ffffff',
            ':hover': {
                backgroundColor: themeContext?.rcTheme?.headerBar?.backgroundColor!,
                color: '#ffffff',

                i: {
                    color: '#ffffff',
                }
            },
        },
        menuAccountInfoButton: {
          background: themeContext?.rcTheme?.headerBar?.backgroundColor,
          height: "100%",
          color: '#fff',
        }
    };

    const logoTextStyle = mergeStyles({
        color: "#fff",
        fontWeight: 600,
        fontSize: 16
    });

    const stackItemStyles = mergeStyles({
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
    });

    useEffect(() => {
      if(authContext.isAuthenticated && authContext.isAuthenticated()) {
        authContext.getUser().then((user: any) => {
          setUser(user);
        })
      }
    }, []);

    return (
        <Stack horizontal className={getStyles.root} tokens={{ childrenGap: '1em' }}>
            <Stack.Item className={stackItemStyles}>
                <CommandBarButton
                    onClick={() => layoutContext.toggleNavState()}
                    className={getStyles.menuSidebarButton}
                    iconProps={{ iconName: "GlobalNavButton", style: getStyles.menuButtonIcon }}>
                </CommandBarButton>
            </Stack.Item>
            <Stack.Item className={stackItemStyles}>
              <Stack horizontal tokens={{childrenGap: 8}} verticalAlign={'center'}>
                {themeContext?.rcTheme?.logo?.path &&
                  <Image className={getStyles.logo} alt="logo" src={themeContext?.rcTheme?.logo?.path} />
                }
                {themeContext?.rcTheme?.logo?.text &&
                  <Text className={logoTextStyle}>{themeContext?.rcTheme?.logo?.text}</Text>
                }
              </Stack>
            </Stack.Item>
            <Stack.Item grow>
                <div/>
            </Stack.Item>
            {(themeContext?.rcTheme?.headerBar?.disabled === undefined || themeContext?.rcTheme?.headerBar?.disabled === true) &&
              <Stack.Item grow className={stackItemStyles} styles={{root: {maxWidth: 500}}}>
                <RcSearchBar customSearches={props.searchbarCustomSearches} commands={props.indexableCommands} />
              </Stack.Item>
            }
            <Stack.Item className={stackItemStyles}>
              {
                layoutContext.actionButtons.map((button) => {
                  return (
                    <CommandBarButton
                      key={button.id}
                      onClick={button.action}
                      className={getStyles.menuButton}
                      iconProps={{ iconName: button.icon, style: getStyles.menuButtonIcon }}>
                    </CommandBarButton>
                  )
                })
              }
              {(authContext.isAuthenticated && authContext.isAuthenticated() && authContext.type !== 'notDefined' && authContext.type !== 'none') &&
                <RcUserInfo email={user?.profile?.email ? user?.profile?.email : user?.mail} name={user?.profile?.name ? user?.profile?.name : user?.username} />
              }
            </Stack.Item>
        </Stack>
    )
}
