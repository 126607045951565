import React from 'react';
import {IconFactory, RcIcons} from "../../utilities/iconFactory";

export interface RcIconProps {
  id?: string,
  iconName: RcIcons,
  fill: string,
  height: string | number,
  width: string | number,
}

export const RcIcon = (props: RcIconProps): JSX.Element => {

  const id = props.id ? props.id : 'svg_icon_' + props.iconName;

  const style = {
    span: {
      display: 'inline-block',
      verticalAlign: 'middle',
      speak: 'none',
      height: props.height,
      width: props.width,
      paddingBottom: 3
    },
    svg: {
      height: '100%',
      fill: props.fill,
      verticalAlign: 'top',
    }
  }

  return (
    <span id={props.id || 'icon_' + props.iconName} style={style.span}>
      { IconFactory.get(props.iconName, id, props.fill) }
    </span>
  )
}
