import React, {useContext} from "react";
import {
  Stack,
  mergeStyleSets,
  CommandBarButton,
  PersonaSize,
  Persona,
  Link,
  Callout,
  IPersonaProps, Separator
} from "@fluentui/react";
import {RcThemeContext} from "../../providers/RcThemeProvider";
import { useBoolean } from '@fluentui/react-hooks';
import {RcAuthContext} from "../../providers/RcAuthProvider";
import {AuthInterface} from "../../services/auth/auth.interface";
import {useNavigate} from "react-router-dom";

export interface IRcUserInfo {
  name: string | undefined;
  email: string | undefined;
}

export const RcUserInfo = (props : IRcUserInfo): JSX.Element => {

  const themeContext = useContext(RcThemeContext);
  const navigate = useNavigate();
  const authContext = useContext<AuthInterface>(RcAuthContext);
  const [isCalloutVisible, { toggle: toggleIsCalloutVisible }] = useBoolean(false);

  function adjust(color: string, amount: number) {
    return '#' + color.replace(/^#/, '').replace(/../g, color => ('0'+Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(16)).substr(-2));
  }

  const styles: any = mergeStyleSets({
    root: {
      height: "100%",
      width: "100%",
    },
    userInfoPanelContainer: {
      right: "0px !important",
      minWidth: 350,
      padding: 10,
      background: '#fff'
    },
    userInfoPanelPersona: {
      padding: "40px 0px"
    },
    userInfoButton: {
      background: themeContext?.rcTheme?.headerBar?.backgroundColor,
      height: "100%",
      //boxShadow: "0 6.4px 14.4px 0 rgb(0 0 0 / 13%), 0 1.2px 3.6px 0 rgb(0 0 0 / 11%)",
      i: {
        fontSize: "24px",
        color: '#ffffff',
      },
      ':hover': {
        backgroundColor: adjust(themeContext?.rcTheme?.headerBar?.backgroundColor!, -20),
        color: '#ffffff',

        i: {
          color: '#ffffff',
        }
      },
      ':active': {
        backgroundColor: themeContext?.rcTheme?.headerBar?.backgroundColor,
        color: '#ffffff',

        i: {
          color: '#ffffff',
        }
      },
    },
  });

  function handleOnTertiaryTextRender(props: IPersonaProps): JSX.Element {

    if(authContext.type === 'rocket' && process.env.REACT_APP_AUTH_ROCKET_FORGOT_PASSWORD_ENDPOINT) {
      return <Link onClick={() => navigate('/identity/changepassword')}>{props.tertiaryText}</Link>
    } else if(authContext.type === 'identity' && process.env.REACT_APP_IDENTITY_ENDPOINT) {
      return <Link href={process.env.REACT_APP_IDENTITY_ENDPOINT + '/Identity/Account/Manage/ChangePassword'}>{props.tertiaryText}</Link>
    } else {
      return <span />
    }
  }

  const onLogoutClicked = () => {
    authContext.logout();
  }

  return (
    <Stack className={styles.root} >
      <CommandBarButton id={'rc-account-info-button'} className={styles.userInfoButton} onClick={toggleIsCalloutVisible}>
        <Persona styles={{primaryText: { color: '#fff', ':hover': {color: 'white'}}}} text={props.name!} size={PersonaSize.size24} />
      </CommandBarButton>
      {isCalloutVisible &&
        <Callout className={styles.userInfoPanelContainer} setInitialFocus target={`#rc-account-info-button`} isBeakVisible={false} onDismiss={toggleIsCalloutVisible}>
          <Stack>
            <Stack.Item align="end">
              <Link onClick={onLogoutClicked}>Esci</Link>
            </Stack.Item>
            <Separator />
            <Persona className={styles.userInfoPanelPersona} onRenderTertiaryText={handleOnTertiaryTextRender} tertiaryText={'Cambia password'} secondaryText={props.email} text={props.name} size={PersonaSize.size72} />
          </Stack>
        </Callout>
      }
    </Stack>
  )
}
